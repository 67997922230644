import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import Message from "../components/Message";
import { addToCart, removeFromCart } from "../actions/cartActions";

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  const { cartItems } = cart;

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    history.push("/login?redirect=shipping");
  };

  return (
    <>
      <Row>
        <Col md={12} className="bg_white pl-0 pr-0">
          <h3 className="pl-3">Shopping Cart</h3>

          {cartItems.length === 0 ? (
            <Message>
              Your cart is empty <Link to="/">Go Back</Link>
            </Message>
          ) : (
            <div>
              <Table className="table-responsive  bg-light w-100 border-0">
                <thead>
                  <tr>
                    <th width={150}>Image</th>
                    <th width={700}>Product Name</th>
                    <th width={200}>Price</th>
                    <th width={100}>Quantity</th>
                    <th width={100}>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item) => (
                    <tr key={item.product}>
                      <td className="cart_img">
                        {" "}
                        <Image
                          className="img-fluid"
                          src={item.image}
                          alt={item.name}
                          fluid
                          rounded
                        />
                      </td>
                      <td>
                        {" "}
                        <Link to={`/product/${item.product}`}>{item.name}</Link>
                      </td>
                      <td>
                        {" "}
                        <img
                          width={10}
                          className="img-fluid mr-1 mb-1"
                          src="../images/taka_black.svg"
                        />
                        {item.price}
                      </td>
                      <td>
                        {" "}
                        <Form.Control
                          as="select"
                          className="select_form"
                          value={item.qty}
                          onChange={(e) =>
                            dispatch(
                              addToCart(item.product, Number(e.target.value))
                            )
                          }
                        >
                          {[...Array(item.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </Form.Control>
                      </td>
                      <td>
                        {" "}
                        <Button
                          type="button"
                          variant="light"
                          onClick={() => removeFromCartHandler(item.product)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            // {cartItems.map((item) => (
            //     <ListGroup.Item key={item.product}>
            //         <Row>
            //             <Col md={2}>
            //                 <Image src={item.image} alt={item.name} fluid rounded />
            //             </Col>
            //             <Col md={3}>
            //                 <Link to={`/product/${item.product}`}>{item.name}</Link>
            //             </Col>
            //             <Col md={2}>
            //                 ${item.price}
            //             </Col>
            //             <Col md={2}>
            //                 <Form.Control as='select' value={item.qty} onChange={(e) => dispatch(addToCart(item.product, Number(e.target.value)))}>
            //                     {
            //                         [...Array(item.countInStock).keys()].map(x => (
            //                             <option key={x + 1} value={x + 1}>{x + 1}</option>
            //                         ))
            //                     }
            //                 </Form.Control>
            //             </Col>
            //             <Col md={2}>
            //                 <Button type='button' variant='light' onClick={() => removeFromCartHandler(item.product)}>
            //                     <i className='fas fa-trash'></i>
            //                 </Button>
            //             </Col>
            //         </Row>
            //     </ListGroup.Item>
            // ))}
          )}
        </Col>
      </Row>
      <Row className="bg_white">
        <Col md={8}></Col>
        <Col md={4}>
          <ListGroup variant="flush">
            <ListGroup.Item className="prices_cart">
              <div className="d-flex justify-content-between mb-3 cart_de">
                <h4 className="p_colr"> Total Item :</h4>
                <h4 className="p_colr_gn">
                  {" "}
                  ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) items
                </h4>
              </div>
              <div className="d-flex justify-content-between cart_de">
                <h4 className="p_colr">Total Price: </h4>
                <h4 className="p_colr_gn">
                  <img
                    width={12}
                    className="img-fluid mr-1 mb-1"
                    src="../images/taka_orange.svg"
                  />
                  {cartItems
                    .reduce((acc, item) => acc + item.qty * item.price, 0)
                    .toFixed(2)}{" "}
                </h4>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      {/* <Row>
        <Col md={12}>
          <h3>What would you like to do next?</h3>
          <h6 className="w-75 cart_para">
            Choose if you have a discount code or reward points you want to use
            or would like to estimate your delivery cost.
          </h6>
        </Col>{" "}
      </Row>
      <Row className="bg_cart d-flex align-items-center">
        <Col md={6}>
          <div class="input-group mb-3 mt-3">
            <input
              type="text"
              class="form-control"
              placeholder="Promo / Coupon Code"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <span class="input-group-text color_btn" id="basic-addon2">
              Apply Coupon
            </span>
          </div>
        </Col>
        <Col md={6}>
          <div class="input-group mb-3 mt-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter your gift voucher code here"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <span class="input-group-text color_btn" id="basic-addon2">
              Apply Voucher
            </span>
          </div>
        </Col>
      </Row> */}
      <Row className="align-items-end pt-5 pb-5 mb-5 bg_white">
        <Col md={3}></Col> <Col md={3}></Col> <Col md={3}></Col>
        <Col md={3}>
          <Button
            type="button"
            className="btn-block "
            disabled={cartItems.length === 0}
            onClick={checkoutHandler}
          >
            Proceed To Checkout
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CartScreen;
