import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Image, NavDropdown, Container, Row, Col, InputGroup, Form, Button, Modal } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import FeaturedCategory from '../components/FeaturedCategory'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import ProductCarousel from '../components/ProductCarousel'
import Meta from '../components/Meta'
import { listProducts } from '../actions/productActions'
import NoticeView from '../components/NoticeView'

const HomeScreen = ({ match }) => {
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const productList = useSelector(state => state.productList)
  const { loading, error, products, page, pages } = productList

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])

  return (
    <>
      <Meta />
      <Row className=' margin_top'>
        <Col sm={12} xl={12}>
          {!keyword ? <ProductCarousel /> : <Link to='/' className='btn btn-light'>Go Back</Link>}
        </Col>
        {/* <Col sm={12} xl={3}>

          <div class="mdl-compare">
            <h4>Compare Products</h4>
            <p>Choose Two Products to Compare</p>
            <form method="post" action="" class="form-cmpr">
              <input type="hidden" name="product_id" />
              <div class="cmpr-field">
                <InputGroup className=" ">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"

                    className='nav_serch_form'
                  />
                  <InputGroup.Text id="basic-addon2" className='nav_serch_btn'><i class="fas fa-search"></i></InputGroup.Text>
                </InputGroup>
              </div>
              <div class="cmpr-field">
                <InputGroup className="width_nav_fix ">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"

                    className='nav_serch_form'
                  />
                  <InputGroup.Text id="basic-addon2" className='nav_serch_btn'><i class="fas fa-search"></i></InputGroup.Text>
                </InputGroup>
              </div>
              <Link to='/CompareScreen'>
                <button class="btn st-outline mt-3">View Comparison</button></Link>
            </form>

          </div>

          <Image className='img-fluid' src="../images/small_banner.jpg" />
        </Col> */}
      </Row>
      {/* {!keyword ? <ProductCarousel /> : <Link to='/' className='btn btn-light'>Go Back</Link>} */}
      {!keyword ?
      <div>
        <div className="hwrap mb-3 mt-3">
            <NoticeView/>
        </div>
      </div>:""}
      {/* {keyword ?
      <>
      <h4 className='text-center'> Searched Products</h4>
      <p className='text-center'>Check & Get Your Desired Product!</p>
      {loading ?
        <Loader /> : error ?
          <Message variant='danger'>{error}</Message> :
          <>
            <Row>
              {products.map(product => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
            <Paginate pages={pages} page={page} keyword={keyword ? keyword : ''} />
          </>
      }
      </>:""} */}
      {keyword ? (
  <>
    <h4 className='text-center mt-3'> Searched Products</h4>
    <p className='text-center'>Check & Get Your Desired Product!</p>
    {loading ? (
      <Loader />
    ) : error ? (
      <Message variant='danger'>{error}</Message>
    ) : products.length === 0 ? (
      <h5 className='text-center text-bold noresult_found'>No results found. Please try another search.</h5>
    ) : (
      <>
        <Row>
          {products.map(product => (
            <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
              <Product product={product} />
            </Col>
          ))}
        </Row>
        <Paginate pages={pages} page={page} keyword={keyword} />
      </>
    )}
  </>
) : null}

      <div className="Section_title mt-5">
        <h4 className='text-center'>Featured Category</h4>
        <p  className='text-center'>Get Your Best Product from Interwave Computers</p>

        {/* <Row className="justify-content-lg-between g-3 justify-content-xl-between justify-content-md-around justify-content-sm-around justify-content-around pl-3 pr-3"> */}
        <Row className="g-3 text-center">
          {/* <Col lg="2" sm="6" xs="6">

          <Link to="/category/ac">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
             src="../images/AC.svg"
            />
            <span>AC</span>
          </div></Link>
          </Col> */}
          <Col lg="2" sm="6" xs="6">
          <Link  to="/category/laptop">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Laptop.svg"
            />
            <span>Laptop</span>
          </div></Link>
            
          </Col>
          <Col lg="2" sm="6" xs="6">

          <Link to="/category/monitor">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Monitor.svg"
            />
            <span>Monitor</span>
          </div>
          </Link>
          </Col>
          <Col lg="2" sm="6" xs="6">

          <Link to="/category/motherboard">

<div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
  <img
    className="img-fluid "
    src="https://creatuscomputer.com/image/cache/catalog/website/featured-category/motherboard-120x120.png.webp"
  />
  <span>Motherboard</span>
</div>
</Link>
          </Col>
          <Col lg="2" sm="6" xs="6">
  
          <Link to="/category/processor">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/CPU.svg" /> 
            <span>Processor</span>
          </div>
          </Link>
            
          </Col>
          <Col lg="2" sm="6" xs="6">

          <Link to="/category/speaker">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Speaker.svg" />
            <span>Speaker</span>
          </div>
          </Link>
          </Col>
       
          <Col lg="2" sm="6" xs="6">
          <Link to="/category/case">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="	https://creatuscomputer.com/image/cache/catalog/website/featured-category/case-120x120.png.webp"
            />
            <span>Case</span>
          </div>
          </Link>
            </Col>
            {/* <Col lg="2" sm="6" xs="6">
            <Link to="/category/cpu cooler">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Cpu_Coller.svg" />
            <span>Cpu Cooler</span>
          </div>
          </Link>
              </Col> */}
          
              <Col lg="2" sm="6" xs="6">
              <Link to="/category/gaming chair">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Gaming_Chair.svg" />
            <span>Gaming Chair</span>
          </div>
          </Link>
</Col>
        
<Col lg="2" sm="6" xs="6">
<Link to="/category/graphics card">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Graphics_Card.svg" />
            <span>Graphics Card</span>
          </div>
          </Link>
</Col>   
<Col lg="2" sm="6" xs="6">
<Link to="/category/Headset">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Headphone.svg" />
            <span>Headset</span>
          </div>
          </Link>
</Col>
{/* <Col lg="2" sm="6" xs="6">
<Link to="/category/mousepad">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Mousepad.svg" />
            <span>Mousepad</span>
          </div>
          </Link>
</Col>      */}
<Col lg="2" sm="6" xs="6">
<Link to="/category/power supply">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/PSU.svg" />
            <span>Power Supply</span>
          </div>
          </Link>
</Col>
<Col lg="2" sm="6" xs="6">
<Link to="/category/mouse"> 
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="	https://creatuscomputer.com/image/cache/catalog/website/featured-category/mouse-120x120.png.webp"
            />
            <span>Mouse</span>
          </div>
          </Link>
</Col>
         
{/* <Col lg="2" sm="6" xs="6">
<Link to="/category/component"> 
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Component.svg"/>
            <span>Component</span>
          </div>
          </Link>
</Col> */}
{/* <Col lg="2" sm="6" xs="6">
<Link to="/category/security"> 
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Security.svg"/>
            <span>Security</span>
          </div>
          </Link>
</Col> */}
<Col lg="2" sm="6" xs="6">
<Link to="/category/gaming"> 
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Gaming.svg"/>
            <span>Gaming</span>
          </div>
          </Link>
</Col>
{/* <Col lg="2" sm="6" xs="6">
<Link to="/category/network"> 
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Network.svg"/>
            <span>Network</span>
          </div>
          </Link>
</Col>       */}
        
       
        
       
          
         
          

        </Row>
      </div>
      <FeaturedCategory />
      {!keyword ?
      <>
      <h4 className='text-center'> Featured Products</h4>
      <p className='text-center'>Check & Get Your Desired Product!</p>
      {loading ?
        <Loader /> : error ?
          <Message variant='danger'>{error}</Message> :
          <>
            <Row>
              {products.map(product => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
            <Paginate pages={pages} page={page} keyword={keyword ? keyword : ''} />
          </>
      }
      </>:""}

      {/* <h4 className='text-center'> Best Deals</h4>
      <p className='text-center'>Check & Get Your Desired Product!</p>

      {loading ?
        <Loader /> : error ?
          <Message variant='danger'>{error}</Message> :
          <>
            <Row>
              {products.map(product => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
            <Paginate pages={pages} page={page} keyword={keyword ? keyword : ''} />
          </>
      } */}

      <section className="text-start mt-5 site_description">
        <Row>
          <Col>
            <div className="mb-2">
              <h5>
                <strong>
                Interwave Computers is Largest & Trusted Retail Computer Shop
                  in Bangladesh
                </strong>
              </h5>
              <p>
                Provides customer service, with thousands of customers
                connected with the organization. We think differently. We
                don’t just sell computers, we create them. Our team of experts
                hand-pick each and every component to ensure that your new
                computer is perfect for you. Plus, we backs all our products
                with a one-year warranty so you can be sure you’re getting the
                best possible value for your money. Come see us today and
                experience the Creatus difference!
              </p>
            </div>
            <div className="mb-2">
              <h5>
                <strong>Top Laptop & Notebook Shop in Bangladesh</strong>
              </h5>
              <p>
                It doesn’t matter if you want a gaming laptop, an Intel laptop or
                an AMD Ryzen laptop for your work, gaming, freelancing or
                study. We have covered all range of budgets for laptops. You
                can also buy a genuine official Apple Macbook Air or Macbook
                Pro laptop from our store.
              </p>
            </div>
            <div className="mb-2">
              <h5>
                <strong>
                  THE BEST E-COMMERCE SHOP TO ORDER THE PRODUCT OF YOUR CHOICE
                </strong>
              </h5>
              <p>
                We have always prioritized its customers and has launched
                e-commerce shops in addition to physical stores to ensure
                better customer service. The goal was to meet the needs of
                more customers in the shortest possible time. Since then, we
                have topped the list as the best e-commerce shop in
                Bangladesh. Our website has a comprehensive search option to
                find the desired product. Our website features a unique PC
                maker so you can create a custom PC with your desired
                components.
              </p>
            </div>
            <div className="mb-2">
              <h5>
                <strong>
                  Build PC with Best Price, Hassale free Product & After-Sales
                  Service
                </strong>
              </h5>
              <p>
                We are the best choice for your home or office computing
                needs. We have the lowest prices around, and our quality and
                service are unbeatable. Plus, we offer after-sales support
                that is second to none. We also offers a cash-on-delivery
                option &amp; EMI facility. We have online delivery available
                in all 64 districts in
              </p>
            </div>
          </Col>
        </Row>

      </section>
      <section className="mb-2">

        {/* <Row>
          <Col>
            <Row className="g-3">
              {" "}
              <Col xl={2} md={4} lg={3} sm={6}>

                <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">DESKTOP</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">laptop</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">service</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>{" "}
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">Accessories</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">helpline</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">Rma</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
            </Row>
          </Col>
        </Row> */}

      </section>
    </>
  )
}

export default HomeScreen
