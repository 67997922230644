import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listProductDetails, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'


const ProductEditScreen = ({ match, history }) => {
    const productId = match.params.id

    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [image, setImage] = useState('')
    const [additionalImages, setAdditionalImages] = useState([])
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [subCategory, setSubCategory] = useState('')
    const [countInStock, setCountInStock] = useState(0)
    const [description, setDescription] = useState('')
    const [uploading, setUploading] = useState(false)
    const dispatch = useDispatch()

    const productDetails = useSelector((state) => state.productDetails)
    const { loading, error, product } = productDetails

    const productUpdate = useSelector((state) => state.productUpdate)
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = productUpdate


    const handleAddImageField = () => {
        setAdditionalImages([...additionalImages, '']);
    };
    const handleRemoveImageField = (index) => {
        const newImages = [...additionalImages];
        newImages.splice(index, 1);
        setAdditionalImages(newImages);
    };

    const [specifications, setSpecifications] = useState([]);

    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const handleInputChange = (titleIndex, dependencyIndex, fieldName, value) => {
        const updatedFormData = [...formData];
        if (dependencyIndex === -1) {
          updatedFormData[titleIndex][fieldName] = value;
        } else {
          updatedFormData[titleIndex].dependencies[dependencyIndex][fieldName] = value;
        }
        setFormData(updatedFormData);
      };
      const handleAddButtonClick = () => {
        setShowForm(true);
        setFormData([...formData, { title: '', dependencies: [] }]);
      };

      const handleRemoveButtonClick = (titleIndex) => {
        const updatedFormData = [...formData];
        updatedFormData.splice(titleIndex, 1);
        setFormData(updatedFormData);
      };

      const handleAddButtonClick1 = (index) => {
        const updatedFormData = [...formData];
        updatedFormData[index].dependencies.push({ ProcessorBrand: '', brand: '' });
        setFormData(updatedFormData);
      };

      const handleRemoveButtonClick1 = (titleIndex, dependencyIndex) => {
        const updatedFormData = [...formData];
        updatedFormData[titleIndex].dependencies.splice(dependencyIndex, 1);
        setFormData(updatedFormData);
      };


    const [showRamDetails, setShowRamDetails] = useState([]);

    // useEffect(() => {
    //     if (category === 'AC') {
    //         setShowRamDetails(false);
    //     }
    //      else if (category === 'Laptop') {
    //         setShowRamDetails(true);
    //     }
    //      else {
    //         setShowRamDetails(false);
    //     }
    // }, [category]);

    // useEffect(() => {
    //     if (successUpdate) {
    //         dispatch({ type: PRODUCT_UPDATE_RESET })
    //         history.push('/admin/productlist')
    //     }
    //     else {
    //         if (!product.name || product._id !== productId) {
    //             dispatch(listProductDetails(productId))
    //         }
    //         else {
    //             setName(product.name)
    //             setPrice(product.price)
    //             setImage(product.image)
    //             setAdditionalImages(product.additionalImages)
    //             setBrand(product.brand)
    //             setCategory(product.category)
    //             setSubCategory(product.subCategory)
    //             setCountInStock(product.countInStock)
    //             setDescription(product.description)
    //             setSpecifications(product.specifications)
    //         }
    //     }
    // }, [dispatch, history, productId, product, successUpdate])
    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: PRODUCT_UPDATE_RESET })
            history.push('/admin/productlist')
        } else {
            if (!product.name || product._id !== productId) {
                dispatch(listProductDetails(productId))
            } else {
                setName(product.name)
                setPrice(product.price)
                setImage(product.image)
                setAdditionalImages(product.additionalImages)
                setBrand(product.brand)
                setCategory(product.category)
                setSubCategory(product.subCategory)
                setCountInStock(product.countInStock)
                setDescription(product.description)
                setSpecifications(product.specifications)
    
                // Set formData with existing specifications data
                if (product.specifications) {
                    const formData = product.specifications.map(spec => ({
                        title: spec.name,
                        dependencies: spec.values.map(value => ({
                            ProcessorBrand: value.name,
                            brand: value.value
                        }))
                    }));
                    setFormData(formData);
                }
    
                // Open the specification form
                setShowForm(true);
            }
        }
    }, [dispatch, history, productId, product, successUpdate])
    useEffect(() => {
        console.log('Product Sub-Category:', product.subCategory);
        if (product.subCategory) {
            setSubCategory(product.subCategory);
        }
    }, [product.subCategory]);
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('/category2.json');
                const data = await response.json();
                setCategories(data);
                if (product.category) {
                    setCategory(product.category);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, [product]);

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };
    const handleSubCategoryChange = (e) => {
        setSubCategory(e.target.value);
    };
    const handleBrandChange = (e) => {
        setBrand(e.target.value);
    };

    const uploadfileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/upload', formData, config)

            setImage(data)
            setUploading(false)
        }
        catch (error) {
            console.log(error)
            setUploading(false)
        }
    }
    const uploadfileHandlerAdditional = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/upload', formData, config)


            console.log("before",additionalImages)

            setAdditionalImages(prevImages => {
                const filteredImages = prevImages.filter(image => image.length > 0);
                return [...filteredImages, data];
            });
            console.log("we",additionalImages)
            setUploading(false)
        }
        catch (error) {
            console.log(error)
            setUploading(false)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            updateProduct({
                _id: productId,
                name,
                price,
                image,
                additionalImages,
                brand,
                category,
                subCategory,
                countInStock,
                description,
                specifications: formData.map(({ title, dependencies }) => ({
                    name: title,
                    values: dependencies.map(({ ProcessorBrand, brand }) => ({
                        name: ProcessorBrand,
                        value: brand
                    }))
                }))
            })
        );
    };


    return (
        <>
            <Link to='/admin/productlist' className='btn btn-light mb-3'>
                Go Back
            </Link>
            <div className="jj">
            <FormContainer >
                <h1>Edit Product</h1>
                {loadingUpdate && <Loader />}
                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> :
                    (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='name'>
                                <Form.Label>Name Address</Form.Label>
                                <Form.Control
                                    type='name'
                                    placeholder='Enter name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='price'>
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                    type='number'
                                    placeholder='Enter price'
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group controlId='image'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter image url'
                                    value={image}
                                    onChange={(e) => setImage(e.target.value)}
                                ></Form.Control>
                                <Form.File id='image-file' label='Choose File' custom onChange={uploadfileHandler}></Form.File>
                                {uploading && <Loader />}
                            </Form.Group>
                            <Form.Group controlId='additionalImages'>
                                <Form.Label>Additional Images</Form.Label> <br/>
                                {additionalImages.map((image, index) => (
                                    <div key={index}>
                                        <input type="file" onChange={uploadfileHandlerAdditional} />
                                        <Button variant="danger" onClick={() => handleRemoveImageField(index)} style={{ marginBottom: '8px' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
        <path d="M11.354 4.646a.5.5 0 0 1 0 .708L8.707 8l2.647 2.646a.5.5 0 1 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708 0z"/>
    </svg>
</Button>

                                    </div>
                                ))}
                                <Button variant="primary" onClick={handleAddImageField}>Add Image</Button>
                                {uploading && <Loader />}
                            </Form.Group>

                            {/* <Form.Group controlId='brand'>
                                <Form.Label>Brand</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter brand'
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                ></Form.Control>
                            </Form.Group> */}

                            <Form.Group controlId='countInStock'>
                                <Form.Label>Count In Stock</Form.Label>
                                <Form.Control
                                    type='number'
                                    placeholder='Enter count in stock'
                                    value={countInStock}
                                    onChange={(e) => setCountInStock(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='category'>
    <Form.Label>Category</Form.Label>
    <Form.Control
        as='select'
        value={category}
        onChange={handleCategoryChange}
    >
        <option value=''>Select category</option>
        {Array.from(new Set(categories.map(item => item.category))).map((category, index) => (
            <option key={index} value={category}>
                {category}
            </option>
        ))}
    </Form.Control>
</Form.Group>

<Form.Group controlId='sub_category'>
    <Form.Label>Sub Category</Form.Label>
    <Form.Control
        as='select'
        value={subCategory} // Ensure that value is bound to subCategory state
        onChange={handleSubCategoryChange} // Ensure that onChange function is correctly defined
    >
        <option value=''>Select subCategory</option>
        {categories.map((category, index) => ( // Loop through categories array
            <option key={index} value={category.sub_category}>
                {category.sub_category}
            </option>
        ))}
    </Form.Control>
</Form.Group>
                            <Form.Group controlId='brand'>
                                <Form.Label>Brand</Form.Label>
                                <Form.Control
                                    as='select'
                                    value={brand}
                                    onChange={handleBrandChange}
                                >
                                    <option value=''>Select brand</option>
                                    {Array.from(new Set(categories.map(item => item.brand))).map((brand, index) => (
                                        <option key={index} value={brand}>
                                            {brand}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            {/* <Form.Group controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></Form.Control>
                            </Form.Group> */}

{showRamDetails && (
    <Form.Group controlId='specifications'>

    <div>
      <Form.Label>Specification</Form.Label> <br />
      <Button onClick={handleAddButtonClick}>Add </Button>
      <br /><br />

      {showForm && (
        <div >
          {formData.map((data, titleIndex) => (
            <div key={titleIndex} className='specification_card'>
              <Form.Row>
                <Form.Group className="mr-3 ">
                  <Form.Label className='mt-6'>Title</Form.Label>
                  <Form.Control
                    type='text'
                    name='title'
                    value={formData[titleIndex].title} 
                    onChange={(e) => handleInputChange(titleIndex, -1, 'title', e.target.value)}
                  />
                </Form.Group>
                <Button variant="danger" onClick={() => handleRemoveButtonClick(titleIndex)} className='edit_specification'>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
    <path d="M11.354 4.646a.5.5 0 0 1 0 .708L8.707 8l2.647 2.646a.5.5 0 1 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708 0z" />
  </svg>
</Button>

              </Form.Row>

              <Button onClick={() => handleAddButtonClick1(titleIndex)}>Add </Button> &nbsp;
              {data.dependencies.map((dependency, dependencyIndex) => (
                <Form.Row key={dependencyIndex}>
                  <Form.Group className="mr-3">
                    <Form.Label className="mt-3">Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="ProcessorBrand"
                      value={formData[titleIndex].dependencies[dependencyIndex].ProcessorBrand}
                      onChange={(e) => handleInputChange(titleIndex, dependencyIndex, 'ProcessorBrand', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mt-3">Value</Form.Label>
                    <Form.Control
                      type="text"
                      name="brand"
                      value={formData[titleIndex].dependencies[dependencyIndex].brand}
                      onChange={(e) => handleInputChange(titleIndex, dependencyIndex, 'brand', e.target.value)}
                    />
                  </Form.Group>
                  &nbsp;&nbsp;
                  <Button variant="danger" onClick={() => handleRemoveButtonClick1(titleIndex, dependencyIndex)} className='edit_specification_value'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M11.354 4.646a.5.5 0 0 1 0 .708L8.707 8l2.647 2.646a.5.5 0 1 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                  </Button>
                </Form.Row>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
    </Form.Group>
)}

                            <Button type='submit' variant='primary'>
                                Update
                            </Button>
                        </Form>
                    )}
            </FormContainer></div>
        </>
    )
}

export default ProductEditScreen
