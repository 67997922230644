import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import Message from './Message';
import { offersList } from '../actions/offersAction';
import { OFFER_LIST_REQUEST } from '../constants/offerConstants';

const ProductCarousel = () => {
    const dispatch = useDispatch();

    // Fetch offers and loading status from Redux store
    const { loading, error, offers } = useSelector(state => state.offersList);

    useEffect(() => {
        // Dispatch action to fetch offers only if they are not available
        if (!offers || offers.length === 0) {
            dispatch(offersList());
        }
    }, [dispatch, offers]); // Dependency array includes dispatch and offers

    return (
        <Carousel pause='hover' className='bg-dark'>
            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                // Render carousel items if offers are available
                offers.map(offer => {
                    if (offer.isActive)
                        return (
                            <Carousel.Item key={offer._id}>
                                <Image className='img img-fluid' src={`${offer.imageUrl}`} />
                                <Carousel.Caption className='carousel-caption'>{/* Add caption content here */}</Carousel.Caption>
                            </Carousel.Item>
                        );
                    return null; // Ensure to return null if the offer is not active
                })
            )}
        </Carousel>
    );
};

export default ProductCarousel;
