import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const PcBuilderChooseScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <section className="p-item-page bg-bt-gray p-tb-15">
              <div className="container body">
                <div className="row pc-builder-choose-content " style={{width:"110%"}}>
                  <Col id="column-left" className="col-sm-3">
                    {/* <span className="lc-close">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </span> */}
                    <div className="filters">
                      <div className="price-filter ws-box">
                        <div className="label">
                          <span>Price Range</span>
                        </div>
                        <input
                          type="range"
                          className="form-range"
                          min="0"
                          max="5"
                          id="customRange2"
                        />
                        <div className="d-flex justify-content-between">
                          <label for="customRange2" className="form-label">
                            max
                          </label>{" "}
                          <label for="customRange2" className="form-label">
                            min
                          </label>
                        </div>{" "}
                      </div>
                      <div
                        className="filter-group ws-box show"
                        data-group-type="status"
                      >
                        <div className="label">
                          <span>Availability</span>
                        </div>
                        <div className="items">
                          <label className="filter">
                            <input type="checkbox" name="status" value="7" />
                            <span>In Stock</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="status" value="8" />
                            <span>Pre Order</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="status" value="9" />
                            <span>Up Coming</span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="filter-group ws-box show"
                        data-group-id="291"
                      >
                        <div className="label">
                          <span>Socket</span>
                        </div>
                        <div className="items">
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1944" />
                            <span>Intel LGA2011</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1942" />
                            <span>Intel LGA2066</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1911" />
                            <span>Intel LGA1200</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1912" />
                            <span>Intel LGA1700</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1913" />
                            <span>AMD AM4</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="2112" />
                            <span>AMD AM5</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1914" />
                            <span>AMD TR4</span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="filter-group ws-box show"
                        data-group-id="54"
                      >
                        <div className="label">
                          <span>Number of Core</span>
                        </div>
                        <div className="items">
                          <label className="filter">
                            <input type="checkbox" name="filter" value="289" />
                            <span>2</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="290" />
                            <span>4</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="291" />
                            <span>6</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="292" />
                            <span>8</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1643" />
                            <span>10</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="964" />
                            <span>12</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="2120" />
                            <span>14</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="965" />
                            <span>16</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="2669" />
                            <span>20</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1287" />
                            <span>24</span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="filter-group ws-box show"
                        data-group-id="292"
                      >
                        <div className="label">
                          <span>Number of Thread</span>
                        </div>
                        <div className="items">
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1916" />
                            <span>4</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1917" />
                            <span>6</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1918" />
                            <span>8</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1919" />
                            <span>12</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1920" />
                            <span>16</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1921" />
                            <span>20</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1922" />
                            <span>24</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1923" />
                            <span>28</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="2670" />
                            <span>32</span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="filter-group ws-box show"
                        data-group-id="55"
                      >
                        <div className="label">
                          <span>Clock Speed</span>
                        </div>
                        <div className="items">
                          <label className="filter">
                            <input type="checkbox" name="filter" value="399" />
                            <span>Up to 2.4GHz</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="396" />
                            <span>2.5GHz to 3.4GHz</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="325" />
                            <span>3.5GHz to 3.90GHz</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="311" />
                            <span>4.00GHz to 5.0GHz</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="1322" />
                            <span>Above 5.1GHz</span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="filter-group ws-box show"
                        data-group-id="57"
                      >
                        <div className="label">
                          <span>Cache</span>
                        </div>
                        <div className="items">
                          <label className="filter">
                            <input type="checkbox" name="filter" value="323" />
                            <span>2MB to 6MB</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="322" />
                            <span>8MB to 12MB</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="321" />
                            <span>14MB to 30MB</span>
                          </label>
                          <label className="filter">
                            <input type="checkbox" name="filter" value="432" />
                            <span>32MB &amp; Above</span>
                          </label>
                        </div>
                      </div>
                    </div>{" "}
                  </Col>
                  <div id="content" className="col-xs-12 col-md-9">
                    <div className="top-bar ws-box">
                      <div className="row">
                        <div className="col-sm-4 col-xs-2 actions">
                          <a className="tool-btn" href="#"></a>

                          <div className="search pcb-search m-hide">
                            <input
                              type="text"
                              name="search"
                              value=""
                              id="input-search"
                              placeholder="Search"
                              className="form-control input-lg"
                              autocomplete="off"
                            />
                            <button className="btn btn-primary">Search</button>
                          </div>
                        </div>
                        <div className="col-sm-8 col-xs-10 show-sort">
                          <div className="form-group d-flex justify-content-end">
                            {/* <label className="mt-2">Sort</label> */}
                            <div className="custom-select w-50">
                              <select id="input-sort">
                                <option value="p.sort_order-ASC">
                                  Default
                                </option>
                                <option value="p.price-ASC" selected="selected">
                                  Price (Low &gt; High)
                                </option>
                                <option value="p.price-DESC">
                                  Price (High &gt; Low)
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="main-content">
                      <div className="product-thumb ws-box">
                        <div className="img-holder">
                          <a href="#">
                            <img
                              src="https://www.startech.com.bd/image/cache/catalog/processor/intel/intel-12th-gen-pentium-gold-g7400/intel-pentium-gold-g7400-12th-gen-alder-lake-01-228x228.jpg"
                              alt="Intel Pentium Gold G7400 Alder Lake Processor"
                              width="228"
                              height="228"
                            />
                          </a>
                        </div>
                        <div className="product-info">
                          <div className="product-content-blcok">
                            <h4 className="product-name">
                              <a href="#">
                                Intel Pentium Gold G7400 Alder Lake Processor
                              </a>
                            </h4>
                            <div className="short-description">
                              <ul>
                                <li>Base Clock Speed: 3.70 GHz</li>
                                <li>
                                  Cache: 6 MB Intel Smart Cache, Socket: LGA
                                  1700
                                </li>

                                <li>CPU Cores: 2, CPU Threads: 4</li>
                                <li>Intel UHD Graphics 710</li>
                              </ul>
                            </div>
                          </div>
                          <div className="actions">
                            <div className="price space-between">
                              <span>7,300৳</span>
                              <a className="btn-add btn" href="#">
                                Add
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product-thumb ws-box">
                        <div className="img-holder">
                          <a href="#">
                            <img
                              src="https://www.startech.com.bd/image/cache/catalog/processor/AMD/athlon-3000g/athlon-3000g-1-228x228.jpg"
                              alt="AMD Athlon 3000G Processor with Radeon Graphics"
                              width="228"
                              height="228"
                            />
                          </a>
                        </div>
                        <div className="product-info">
                          <div className="product-content-blcok">
                            <h4 className="product-name">
                              <a href="#">
                                AMD Athlon 3000G Processor with Radeon Graphics
                              </a>
                            </h4>
                            <div className="short-description">
                              <ul>
                                <li>Speed: 3.5GHz</li>
                                <li>Cache: L2: 1MB, L3: 4MB</li>

                                <li>Cores-2 &amp; Threads-4</li>
                                <li>Memory Speed: 2667MHz</li>
                              </ul>
                            </div>
                          </div>
                          <div className="actions">
                            <div className="price space-between">
                              <span>7,500৳</span>
                              <a className="btn-add btn" href="#">
                                Add
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-bar">
                      <div className="row">
                        <div className="col-sm-6 number-of-items">
                          Showing 1 to 20 of 78 (4 Pages)
                        </div>
                        <div className="col-sm-6 text-right">
                          <ul className="pagination justify-content-end">
                            <li>
                              <span className="disabled">PREV</span>
                            </li>
                            <li className="active">
                              <span>1</span>
                            </li>
                            <li>
                              <a href="#">2</a>
                            </li>
                            <li>
                              <a href="#">3</a>
                            </li>
                            <li>
                              <a href="#">4</a>
                            </li>
                            <li>
                              <a href="#">NEXT</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PcBuilderChooseScreen;
