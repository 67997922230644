import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const OnlineDeliveryScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <Row>
          <div class="odc abt topper">
            {" "}
            <h1>প্রশপ অনলাইন ডেলিভারির শর্তাবলি</h1>
            <ul>
              <li>
                <strong>বর্তমানে বাংলাদেশের যেকোনো প্রান্তে</strong> নির্দিষ্ট
                পণ্যে <strong>ক্যাশ অন ডেলিভারি</strong> সুবিধা রয়েছে।
              </li>
              <li>
                অনলাইন ডেলিভারির ক্ষেত্রে ডেলিভারি চার্জ/ কুরিয়ার চার্জ
                প্রযোজ্য।{" "}
              </li>
              <li>
                ঢাকা/গাজীপুর/রংপুর/চট্টগ্রাম/খুলনা শহরে আমাদের নিজস্ব কুরিয়ার
                সার্ভিস এর মাধ্যমে পণ্য ডেলিভারি করে থাকি, এছাড়াও অন্যন্য
                শহরগুলোতে আমরা কুরিয়ার সার্ভিস এর মাধ্যমে পণ্য ডেলিভারি সম্পন্ন
                করে থাকি।
              </li>
              <li>
                কুরিয়ারের মাধ্যমে ডেলিভারকৃত পণ্যের মূল্যের সম্পূর্ণ অথবা আংশিক
                মূল্য বিকাশ, ব্যাংকট্রান্সফার অথবা অনলাইন পেমেন্ট এর মাধ্যমে
                এডভান্স পেমেন্ট করে অর্ডার কনফার্ম করতে হবে।
              </li>
              <li>
                ঢাকা/গাজীপুর/রংপুর/চট্টগ্রাম/খুলনা শহরে পণ্যের মূল্য ২০,০০০
                টাকার উপরে হয়ে থাকলে পণ্যের আংশিক মূল্য পরিশোধ করতে হতে পারে।
              </li>
              <li>
                কুরিয়ারের মাধ্যমে ডেলিভারকৃত পণ্যের সম্পূর্ণ কুরিয়ার চার্জ
                ক্রেতাকে বহন করতে হবে।
              </li>
              <li>
                বর্তমানে অনলাইন অর্ডারে ডেলিভারির ক্ষেত্রে সময়{" "}
                <strong>১ থেকে ৩ দিন অথবা তারও বেশি লাগতে পারে।</strong>
              </li>
              <li>
                পেমেন্ট কনফার্মেশনের এসএমএস পাবার পর{" "}
                <strong>৩ দিনের মধ্যে ক্রেতাকে পেমেন্ট করতে হবে</strong>। এর পর
                পেমেন্ট করলে পণ্য স্টক শেষ হয়ে যেতে পারে অথবা মূল্য পরিবর্তিত
                হতে পারে।
              </li>
              <li>
                অর্ডারক্রীত পণ্য স্টকে না থাকলে ক্রেতার সম্মতিক্রমে পণ্য
                পরিবর্তন অথবা মূল্য রিফান্ড করা হবে।
              </li>
              <li>
                বর্তমানে করোনা সংক্রমণ রোধে বিল্ডিং এর ভেতরে নির্দিষ্ট ফ্ল্যাট এ
                গিয়ে ডেলিভারি সাময়িক বন্ধ রাখা হয়েছে। ক্রেতাকে বিল্ডিং এর মেইন
                গেট থেকে পণ্য রিসিভ করতে হবে।{" "}
              </li>
              <li>
                <strong>
                  চট্টগ্রাম শহরের নির্দিষ্ট এরিয়াতে হোম ডেলিভারি সুবিধা রয়েছে।
                </strong>{" "}
                এরিয়াগুলো হল - আগ্রাবাদ, চৌমুহনী, দেওয়ানহাট, টাইগারপাস, লালখান
                বাজার, ওয়াসা, জিইসি মোড়, ২ নাম্বার গেট, মুরাদপুর, প্রবর্তক মোড়,
                জামালখান, চকবাজার, নাসিরাবাদ হাউজিং, খাতুনগঞ্জ, আসাদ্গঞ্জ,
                কাতাল্গঞ্জ, কোতোয়ালি, আন্দরকিল্লা, গনি বেকারি, কাজির দেউরি,
                মেহেদীবাগ, নিউ মার্কেট, কদমতলী, ফিরিঙ্গিবাজার, মাদারবাড়ী, বারিক
                বিল্ডিং, ডিসি হিল, ঈদগাহ, বউবাজার, পাহাড়তলি, সিডিএ আবাসিক,
                চট্টেশ্বরী, বরপুল, কাস্টমস, এয়ারপোর্ট, পতেঙ্গা,নেভাল, স্টিলমিল,
                ফ্রিপোর্ট, হালিশহর, সাগরিকা, অলংকার, একে খান, সিটি গেইট, সিএনভি,
                রাস্তার মাথা, চাদ্গাও আবাসিক, নতুন ব্রিজ, বাহাদুরঘাট, অক্সিজেন।
              </li>
              <li>
                <strong>
                  গাজীপুর শহরের নির্দিষ্ট এরিয়াতে হোম ডেলিভারি সুবিধা রয়েছে।
                </strong>{" "}
                এরিয়াগুলো হল - টাকশাল, শিমুলতলী, অডিয়েন্স, বিওএফ, ডুয়েট, মিষ্ট ,
                জয়দেবপুর বাসস্ট্যান্ড, উত্তর ছায়াবীথি / দক্ষিণ ছায়াবীথি,
                জোড়পুকুর-মোড় , রাজদিঘীর পাড়, রাজবাড়ী মাঠ, রানীবিলাশ মনি স্কুল,
                ভ্যাট-ট্যাক্স ও ডিসি অফিস, জয়দেবপুর পাসপোর্ট অফিস, রেলওয়ে
                স্টেশন, রথ খোলা, সদর হসপিটাল, সিভিল সার্জন অফিস, সিটি কর্পোরেশন,
                ডাকঘর, ধান গবেষণা, কৃষি গবেষণা, পল্লী বিদ্যুৎ, ওয়্যারলেস গেট,
                স্যাটেলাইট অফিস , গ্রেট ওয়াল সিটি, নলজানী, চেরাগআলী (বেক্সিমকো
                ফার্মা), কলেজ গেট (সুরতরঙ্গ রোড / হাউস বিল্ডিং / শফিউদ্দিন রোড),
                বনমালা রোড (দত্তপাড়া হাউস বিল্ডিং / ব্র্যাক টাউন),<br></br>
                <strong>
                  {" "}
                  এই এরিয়াগুলো মেইনরোড ও তারপাশে ডেলিভারি করা হবে -
                </strong>{" "}
                হোসেন মার্কেট , গাজীপুরা, বড়বাড়ি, তারগাছ , বোর্ড বাজার, ইসলামিক
                বিশ্ববিদ্যালয়, উন্মুক্ত বিশ্ববিদ্যালয়, জাতীয় বিশ্ববিদ্যালয়,
                সাইনবোর্ড ,মালেকের বাড়ি, কলম্বিয়া, ভোগড়া, বাইপাস,ভাওয়াল কলেজ,
                নাওজোড়, কড্ডা ব্রিজ, কোনাবাড়ি মার্কেট, কেন্দীয় মসজিদ কোনাবাড়ি,
                কৃষি বিশ্ববিদ্যালয়, হোতাপাড়া, সালনা বাজার।
              </li>
              <strong>
                {" "}
                <li>
                  ইন্টারন্যাশনাল পেমেন্টের ক্ষেত্রে ক্রেতা যে কার্ডের মাধ্যমে
                  পেমেন্ট করেছেন অবশ্যই সেই কার্ডের স্বচ্ছ ছবি এবং
                  কার্ডহোল্ডারের{" "}
                  <strong>
                    ভোটার আইডি / ন্যাশনাল আইডি / ড্রাইভিং লাইসেন্স / পাসপোর্ট
                  </strong>{" "}
                  এর স্বচ্ছ ছবি আমাদের অফিশিয়াল ইমেইল অথবা ফেসবুক পেজে পাঠাতে
                  হবে। (ছবিতে অবশ্যই কার্ড নাম্বারের প্রথম এবং শেষ ৪ ডিজিট
                  স্পষ্ট বুঝা যেতে হবে){" "}
                </li>
                <li>
                  ইন্টারন্যাশনাল কার্ডে কোন <strong>ইএমআই(EMI)</strong> প্রযোজ্য
                  নয়।
                </li>
                <li>
                  কোন স্পেশাল ক্যাম্পেইন অফারের পণ্যে / কোন পণ্যে ভাউচার বা কুপন
                  ব্যাবহার করে ডিস্কাউন্ট পেলে সে পণ্য থেকে পয়েন্ট অর্জিত হবে
                  না। তদ্রূপ কোন অফারের পণ্যে / স্পেশাল ডিস্কাউন্টযুক্ত পণ্যে আর
                  কোন কুপন ব্যাবহার করা যাবে না।
                </li>
              </strong>
            </ul>
            <strong>
              <br></br>
              <h2>
                প্রশপ অনলাইন শপে অর্ডার করে ক্রেতা তার পণ্যটি যেকোনো শপ থেকে
                স্টোর পিক করতে পারবে। এক্ষেত্রে -
              </h2>
              <ul>
                <li>
                  অবশ্যই এজেন্টের কফার্মেশনের পর প্রদত্ত সময়ে শপ থেকে পণ্য রিসিভ
                  করতে হবে।
                </li>
                <li>
                  নির্দিস্ট শপে পণ্যের স্টক না থাকলে অন্য শপ থেকে পণ্য ওই শপে
                  ট্রান্সফার করে আনার পর ক্রেতা স্টোর পিক করতে পারবে তবে
                  এক্ষেত্রে এডভান্স পেমেন্ট করতে হবে।
                </li>
                <li>
                  স্টোর পিক এর এজেন্ট কনফার্মেশন দেয়া সময় এর ৩ দিনের মধ্যে
                  ক্রেতাকে উক্ত শপ থেকে পণ্য রিসিভ করতে হবে।
                </li>
                <li>
                  এডভান্স পেমেন্ট করে পণ্য বুক না করলে যেকোনো সময় পণ্যের স্টক /
                  মূল্য পরিবর্তিত হতে পারে। এক্ষেত্রে ক্রেতাকে পরিবর্তিত মূল্যে
                  পণ্য ক্রয় করতে হবে।
                </li>
                <li>
                  কনফার্মেশনের ৩ দিন এবং পেমেন্ট করে বুক করা অর্ডার এর পণ্য ১৫
                  দিনের মধ্যে ক্রেতা রিসিভ না করলে অর্ডার স্বয়ংক্রিয়ভাবে কেন্সেল
                  হয়ে যাবে। এক্ষেত্রে পেমেন্ট রিফান্ড হলে ক্রেতাকে রিফান্ড চার্জ
                  প্রদান করতে হবে।
                </li>
              </ul>
              <h2>প্রশপ এক্সপ্রেস ডেলিভারির শর্তাবলি</h2>

              <ul>
                <li>
                  শুধুমাত্র পণ্য নির্দিষ্ট স্টকে থাকলে এক্সপ্রেস ডেলিভারিতে
                  অর্ডার নেয়া হয়ে থাকে।
                </li>
                <li>
                  অর্ডার কনফার্মের সময় থেকে ২৪ কর্মঘণ্টার মধ্যে এক্সপ্রেস
                  ডেলিভারি করে দেয়া হয়, এবং ডেলিভারি করতে ২৪ কর্মঘন্টার বেশি সময়
                  লাগলে ক্রেতাকে কোন ডেলিভারি চার্জ বহন করতে হবে না। (শর্ত
                  প্রযোজ্য)
                </li>
                <li>
                  ডেলিভারি চার্জ পণ্যের আকার এবং ওজনের উপর নির্ভর করবে। অর্ডার
                  কনফার্ম করার পূর্বে আমাদের এজেন্ট কল করে ডেলিভারি সম্পর্কিত
                  বিস্তারিত তথ্য প্রদান করবেন।
                </li>
                <li>
                  এক্সপ্রেস ডেলিভারি উক্ত দিন দুপুর ১২টার আগে কনফার্ম করলে সেই
                  দিনেই ডেলিভারি করে দেয়া হবে।
                </li>
                <li>
                  নির্দিষ্ট দিনে দুপুর ১২ টার পর এক্সপ্রেস ডেলিভারি কনফার্ম করলে
                  তা পরবর্তী দিন ডেলিভারি দেয়া হবে।
                </li>
              </ul>
              <div class="nb">
                {" "}
                <h3>রিফান্ড এবং রিটার্ণ পলিসি সম্পর্কিত তথ্য জানতে</h3>
                <a href="#" target="_blank" class="btn btn-primary">
                  এখানে ক্লিক করুন
                </a>{" "}
                <h3>
                  বিস্তারিত জানতে কল করুন - 16793 অথবা 09678002003 (সকাল ৯টা
                  থেকে রাত ৮টা)
                </h3>
              </div>
            </strong>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OnlineDeliveryScreen;
