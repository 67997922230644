import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import { noticeList, noticeUpdate } from '../../actions/noticeAction'

import {

    NOTICE_LIST_REQUEST,
    NOTICE_UPDATE_REQUEST,
    NOTICE_UPDATE_RESET,

} from '../../constants/noticeConstants'

const NoticeEditScreen = ({ match, history }) => {

    const noticeId = match.params.id

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [isActive, setIsActive] = useState('')

    const dispatch = useDispatch()

    const { loadSuccess, notices } = useSelector(state => state.noticeList)

    const { loading, error, successUpdate: success } = useSelector((state) => state.noticeUpdate)

    useEffect(() => {

        dispatch({type: NOTICE_UPDATE_RESET})


        if (success) {

            history.push('/admin/notice/list')

        }

        if (notices.length == 0) {
            dispatch({ type: NOTICE_LIST_REQUEST })
            dispatch(noticeList())
        }



    }, [dispatch, success])


    useEffect(() => {


        if (loadSuccess) {

            console.log("noticeId", noticeId);

            for (let index = 0; index < notices.length; index++) {

                if (notices[index]._id == noticeId) {
                    setTitle(notices[index].title)
                    setDescription(notices[index].description)
                    setIsActive(notices[index].isActive);
                    break;
                }

            }
        }


    }, [loadSuccess])


    const submitHandler = (e) => {

        e.preventDefault()

        dispatch({ type: NOTICE_UPDATE_REQUEST })

        dispatch(noticeUpdate({
            "_id": noticeId,
            "title": title,
            "description": description,
            "isActive": isActive,
        }))

        
    }

    return (
        <>
            <Link to='/admin/notice/list' className='btn btn-light mb-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Notice edit</h1>
                {loading && <Loader />}
                {error && <Message variant='danger'>{error}</Message>}
                {
                    (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='title'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type='name'
                                    placeholder='Enter here offer title'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Button type='submit' variant='primary'>
                                Update
                            </Button>
                        </Form>
                    )}
            </FormContainer>
        </>
    )
}

export default NoticeEditScreen
