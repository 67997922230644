import {
    OFFER_CREATE_REQUEST,
    OFFER_CREATE_SUCCESS,
    OFFER_CREATE_FAIL,

    OFFER_LIST_REQUEST,
    OFFER_LIST_SUCCESS,
    OFFER_LIST_FAIL,

    OFFER_DELETE_REQUEST,
    OFFER_DELETE_SUCCESS,
    OFFER_DELETE_FAIL,

    OFFER_UPDATE_RESET,
    OFFER_UPDATE_REQUEST,
    OFFER_UPDATE_SUCCESS,
    OFFER_UPDATE_FAIL,
} from '../constants/offerConstants'


export const offerCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case OFFER_CREATE_REQUEST:
            return { loading: false }
        case OFFER_CREATE_SUCCESS:
            return { loading: false, success: true, offers: action.payload }
        case OFFER_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const offerUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case OFFER_UPDATE_RESET:
            return { loadingUpdate: false, successUpdate: false }
        case OFFER_UPDATE_REQUEST:
            return { loadingUpdate: true, successUpdate: false }
        case OFFER_UPDATE_SUCCESS:
            return { loadingUpdate: false, successUpdate: true, offers: action.payload }
        case OFFER_UPDATE_FAIL:
            return { loadingUpdate: false, successUpdate: false, error: action.payload }
        default:
            return state
    }
}

export const offerDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case OFFER_DELETE_REQUEST:
            return { loading: false }
        case OFFER_DELETE_SUCCESS:
            return { loadingDelete: false, successDelete: true, offers: action.payload }
        case OFFER_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}



export const offersListReducer = (state = { offers: [] }, action) => {
    switch (action.type) {
        case OFFER_LIST_REQUEST:
            return { loading: true, loadSuccess: false, offers: [] }
        case OFFER_LIST_SUCCESS:
            return {
                loading: false,
                loadSuccess: true,
                offers: action.payload
            }
        case OFFER_LIST_FAIL:
            return { loading: false, loadSuccess: false, error: action.payload }
        default:
            return state
    }
}