import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import Rating from "../components/Rating";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import Product from "../components/Product";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import { listProducts } from "../actions/productActions";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import { render } from "react-dom";
// import Carousel from "../components/Slide";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FsLightbox from "fslightbox-react";
import { Carousel } from "react-responsive-carousel";
import {

  GlassMagnifier
} from "react-image-magnifiers";


const ProductScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { success: successProductReview, error: errorProductReview } =
    productReviewCreate;

  useEffect(() => {
    if (successProductReview) {
      alert("Review Submitted!");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    dispatch(listProductDetails(match.params.id));
  }, [dispatch, match, successProductReview]);

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    );
  };
  const keyword = match.params.keyword;

  const pageNumber = match.params.pageNumber || 1;

  const productList = useSelector((state) => state.productList);
  const { products, page, pages } = productList;

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  const [toggler, setToggler] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);


  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const magnifierProps = {
    allowOverflow: true,
    square: true,
    magnifierSize: '60%',
    zoomLevel: 4,
    borderSize: 1,
    borderColor: 'rgba(0,0,0,0.5)' // Adjust as needed
  };

  const handleThumbClick = (index) => {
    setSelectedImageIndex(index);
  };
  return (
    <>
      <Link className="btn btn-light mb-3" to="/">
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Meta title={product.name} />
          <Row className="ml-0 mr-0" >
            <Col md={6} className="bg_white pl-0 " >
            
              <FsLightbox
                toggler={toggler}
                sources={[
                  product.image, 
                  ...(product.additionalImages ? product.additionalImages : [])
                ].map((image, index) => (
                  <img key={index} alt="" className="img-fluid" src={image} />
                ))}
              />
              {/* <span onClick={() => setToggler(!toggler)}> */}
              {/* <div className="carousel-container">
                <Carousel width autoPlay  showThumbs={true}
  thumbWidth={100}
  onClickThumb={(index) => console.log(`Clicked thumb ${index}`)} >
                  {[
                    product.image, 
                    ...(product.additionalImages ? product.additionalImages : [])
                  ].map((image, index) => (
                    <div key={index}>
           <GlassMagnifier
  imageSrc={image}
  imageAlt="Example"
  largeImageSrc={image} 
  {...magnifierProps} // Optional
/>
                    </div>
                  ))}
                </Carousel></div> */}
                  <div>                  
                  <Carousel selectedItem={selectedImageIndex}>
                    {[
                      product.image, 
                      ...(product.additionalImages ? product.additionalImages : [])
                    ].map((image, index) => (
                      <div key={index}>
                        <GlassMagnifier
                          imageSrc={image}
                          largeImageSrc={image} // Use the same image for the large view
                          {...magnifierProps}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <div className="thumbs-wrapper axis-vertical d-flex mb-2">
  {[
    product.image,
    ...(product.additionalImages ? product.additionalImages : [])
  ].map((image, index) => (
    // Check if the image exists
    image ? (
      <div key={index} onClick={() => handleThumbClick(index)} className="thumbs-wrapper-box">
        <img
          src={image}
          alt={`Thumbnail ${index}`}
          style={{
            width: '100%',
            height: '100px',
            marginRight: '10px',
            cursor: 'pointer'
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '../images/No-Image.png';
            e.target.alt = 'No Image Available';
          }}
        />
      </div>
    ) : null // Render nothing if the image doesn't exist
  ))}
</div>
                </div>               
                  {/* </span> */}
            
            </Col>
            <Col md={6} className="bg_white pr-0">
            
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2 className="product_namee mb-0">{product.name}</h2>
                </ListGroup.Item>
                <ListGroup.Item className="product_code">
                  Product Code: 87878
                </ListGroup.Item>
                <ListGroup.Item className="product_price">
                  Price:{" "}
                  {product.price ? (
                    <>
                      <img
                        width={12}
                        className="img-fluid mr-1 mb-1"
                        src="../images/taka_green.svg"
                      />
                      {product.price.toLocaleString()}
                    </>
                  ) : (
                    "N/A"
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} reviews`}
                  />
                </ListGroup.Item>

                <ListGroup.Item>
                  <h3 className="product_name">Key Features</h3>
                  {product.description}
                </ListGroup.Item>
                <ListGroup.Item className="product_price mb-3">
                  {product.countInStock > 0 ? "In Stock" : "Out Of Stock"}
                </ListGroup.Item>
              </ListGroup>
              <div className="d-flex">
                {product.countInStock > 0 && (
                  <ListGroup.Item className="mr-3 ml-4 customWidth">
                    <Form.Control
                    className="form_c"
                      as="select"
                      value={qty}
                      onChange={(e) => setQty(e.target.value)}
                    >
                      {[...Array(product.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </ListGroup.Item>
                )}

                <Button
                  onClick={addToCartHandler}
                  className="btn-block color_dark customWidth"
                  type="button"
                  disabled={product.countInStock === 0}
                >
                  Add To Cart
                </Button>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4 display_small ">
                <div class="col-inner ">
                  <div class="icon-box featured-box d-flex align-items-center align-items-center">
                    <div class="icon-inner">
                      <img
                        width="50"
                        height="50"
                        src="https://static.vecteezy.com/system/resources/thumbnails/003/344/958/small/hotline-symbol-with-headphones-and-customer-icon-vector.jpg"
                        class="attachment-medium size-medium"
                        alt=""
                        loading="lazy"
                      />
                    </div>

                    <div class="icon-box-text last-reset ml-2">
                      <p className="mb-0">
                        <strong>Hotline Support:</strong>{" "}
                        <span>(10am - 8pm)</span>
                      </p>
                      <p className="mb-0">
                        <span>
                          <strong>
                            <a href="#">Live Chat</a> {" "}
                          </strong>
                          <a href="tel:(880) 1777-171413">(880) 1777-171413</a>
                          <strong>
                            {" "}
                            {/* | */}
                            {/* <a href="mailto:" target="_blank" rel="noopener">
                              Email
                            </a> */}
                          </strong>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-inner  ml-2">
                  <div class="icon-box featured-box circle icon-box-left text-left is-small d-flex align-items-center ">
                    <div class="icon-box-img">
                      <div class="icon-inner">
                        <img
                          width="50"
                          height="50"
                          src="https://cdn1.vectorstock.com/i/1000x1000/66/40/motorcycle-delivery-food-isolated-icon-vector-10256640.jpg"
                          class="attachment-medium size-medium"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div class="icon-box-text last-reset ml-2">
                      <p className="mb-0">
                        <strong>
                          Delivery Time:
                          <span>(48 Hours / 3 WD)</span>
                        </strong>
                      </p>
                      <p className="mb-0">
                        <span>Depends on Location</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mt-4">
                <div className=" ">
                  <button className="btn btn-dark text-uppercase m-2 pt-1 pb-1 pl-2 pr-2">
                    pRICE mATCH
                  </button>
                  <button className="btn btn-dark text-uppercase m-2 pt-1 pb-1 pl-2 pr-2">
                    SSL COMMERZ
                  </button>
                  <button className="btn btn-dark text-uppercase m-2 pt-1 pb-1 pl-2 pr-2">
                    emi INFORMATION
                  </button>
                  <button className="btn btn-dark text-uppercase m-2 pt-1 pb-1 pl-2 pr-2">
                    DELIVERY
                  </button>
                  <button className="btn btn-dark text-uppercase m-2 pt-1 pb-1 pl-2 pr-2">
                    Warranty
                  </button>
                </div>
              </div> */}
        
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <Tabs
                defaultActiveKey="description"
                id="uncontrolled-tab-example"
                className="mb-3 nav_tabs mt-5"
              >
                <Tab eventKey="description" title="Description">
                  <Table
                    responsive
                    striped
             
                    hover
                    size="sm"
                    className="product_table bg-light"
                  >
                  {product.specifications && product.specifications.map((specification, index)=>(
                    <>
                    <thead>
                      <tr>
                        <th class="heading_row" colspan="3">
                          {specification.name}
                        </th>
                      </tr>
                    </thead>
                                        
 <tbody>
 {specification.values.map((value, index)=>(
 <tr>
   <td className="t_name">{value.name}</td>
   <td>{value.value}</td>
 </tr>

 ))}
</tbody>
                                        
                                      </>
                  ))}
                  </Table>
                </Tab>
                {/* <Tab
                  eventKey="additionalInformation "
                  title="Additional information "
                >
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    size="sm"
                    className="product_table"
                  >
                    <thead>
                      <tr>
                        <th class="heading_row" colspan="3">
                          Basic Information
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="t_name">Base Frequency</td>
                        <td>2.10 GHz</td>
                      </tr>
                      <tr>
                        <td className="t_name">Maximum Turbo Frequency</td>
                        <td>4.10 GHz</td>
                      </tr>
                      <tr>
                        <td className="t_name">Cache</td>
                        <td>2.10 GHz</td>
                      </tr>
                      <tr>
                        <td className="t_name">Cores</td>
                        <td>2.10 GHz</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th class="heading_row" colspan="3">
                          Memory Specifications
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="t_name">Maximum Size</td>
                        <td>2.10 GHz</td>
                      </tr>
                      <tr>
                        <td className="t_name">Max Number of Channels</td>
                        <td>258MB</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th class="heading_row" colspan="3">
                          Graphics Specifications
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="t_name">Processor Graphics</td>
                        <td>Intel UHD Graphics 770</td>
                      </tr>
                      <tr>
                        <td className="t_name">Max Number of Channels</td>
                        <td>258MB</td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="questions" title="Questions">
                  <section class="ask-question q-n-r-section bg-white m-tb-15 p-3">
                    <div class="section-head d-flex justify-content-between align-items-center">
                      <div class="title-n-action">
                        <h2>Questions (0)</h2>
                        <p class="section-blurb">
                          Have question about this product? Get specific details
                          about this product from expert.
                        </p>
                      </div>

                      <div class="q-action">
                        <button
                          type="button"
                          class="btn st-outline"
                          onClick={handleShow}
                        >
                          Ask Question
                        </button>
                      </div>
                    </div>

                    <div id="question">
                      <div class="empty-content">
                        <span class="icon material-icons">
                          <i class="fas fa-comment-alt"></i>
                        </span>
                        <div class="empty-text">
                          There are no questions asked yet. Be the first one to
                          ask a question.
                        </div>
                      </div>
                    </div>
                  </section>
                </Tab> */}
                <Tab eventKey="reviews" title="Reviews">
                  <h2></h2>
                  {product.reviews.length === 0 && (
                    <Message>No Reviews</Message>
                  )}
                  <ListGroup variant="flush">
                    {product.reviews.map((review) => (
                      <ListGroup.Item key={review._id}>
                        <strong>{review.name}</strong>
                        <Rating value={review.rating} />
                        <p>{review.createdAt.substring(0, 10)}</p>
                        <p>{review.comment}</p>
                      </ListGroup.Item>
                    ))}
                    <ListGroup.Item>
                      <h2>Write a Customer Review</h2>
                      {errorProductReview && (
                        <Message variant="danger">{errorProductReview}</Message>
                      )}
                      {userInfo ? (
                        <Form onSubmit={submitHandler}>
                          <Form.Group controlId="rating">
                            <Form.Label>Rating</Form.Label>
                            <Form.Control
                              as="select"
                              value={rating}
                              onChange={(e) => setRating(e.target.value)}
                            >
                              <option value="">Select...</option>
                              <option value="1">1 - Poor</option>
                              <option value="2">2 - Fair</option>
                              <option value="3">3 - Good</option>
                              <option value="4">4 - Very Good</option>
                              <option value="5">5 - Excellent</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="comment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as="textarea"
                              row="3"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Button type="submit" variant="primary">
                            Submit
                          </Button>
                        </Form>
                      ) : (
                        <Message>
                          Please <Link to="/login">sign in</Link> to write a
                          review{" "}
                        </Message>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Tab>
              </Tabs>
            </Col>
            {/* <Col md={4} sm={12}>
              <h3 className="mb-3 mt-5 nav_prods">CHECKOUT LATEST PRODUCTS</h3>
              <div class="d-flex align-items-center mb-4">
                <div class="flex-shrink-0">
                  <img
                    className="img-fluid product_img"
                    src="https://creatuscomputer.com/image/cache/catalog/2022/03/value-top-vt-m200-gaming-case-1-500x500-1-90x90.jpg.webp"
                    alt="..."
                  />
                </div>
                <div class="flex-grow-1 ml-3">
                  Cougar Mars Ergonomic Design Gaming Desk
                  <br></br>
                  <span className="prod_price mt-3">Price : $50.00</span>
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div class="flex-shrink-0">
                  <img
                    className="img-fluid product_img"
                    src="https://creatuscomputer.com/image/cache/catalog/2022/03/value-top-vt-m200-gaming-case-1-500x500-1-90x90.jpg.webp"
                    alt="..."
                  />
                </div>
                <div class="flex-grow-1 ml-3">
                  Dahua IMOU IPC-D22P Dome Lite IP Camera
                  <br></br>
                  <span className="prod_price mt-3">Price : $30.00</span>
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div class="flex-shrink-0">
                  <img
                    className="img-fluid product_img"
                    src="https://creatuscomputer.com/image/cache/catalog/2022/03/value-top-vt-m200-gaming-case-1-500x500-1-90x90.jpg.webp"
                    alt="..."
                  />
                </div>
                <div class="flex-grow-1 ml-3">
                  HP M280 Genius RGB Gaming Mouse
                  <br></br>
                  <span className="prod_price mt-3">Price : $80.00</span>
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div class="flex-shrink-0">
                  <img
                    className="img-fluid product_img"
                    src="https://creatuscomputer.com/image/cache/catalog/2022/03/value-top-vt-m200-gaming-case-1-500x500-1-90x90.jpg.webp"
                    alt="..."
                  />
                </div>
                <div class="flex-grow-1 ml-3">
                  Cougar Mars Ergonomic Design Gaming Desk
                  <br></br>
                  <span className="prod_price mt-3">Price : $50.00</span>
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div class="flex-shrink-0">
                  <img
                    className="img-fluid product_img"
                    src="https://creatuscomputer.com/image/cache/catalog/2022/03/value-top-vt-m200-gaming-case-1-500x500-1-90x90.jpg.webp"
                    alt="..."
                  />
                </div>
                <div class="flex-grow-1 ml-3">
                  WESTERN DIGITAL WD40EZRZ 4TB PURPLE DESKTOP HDD
                  <br></br>
                  <span className="prod_price mt-3">Price : $50.00</span>
                </div>
              </div>
            </Col> */}
          </Row>
        </>
      )}

      {/* <h3>Related Product</h3>
      <Row>
        {products.map((product) => (
          <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
            <Product product={product} />
          </Col>
        ))}
      </Row>
      <Modal show={show} centered onHide={handleClose} className="custom-modal">
        <Modal.Header closeButton className="text-center">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="panel m-auto ws-box p-15">
            <div class="ac-title d-flex align-items-center">
              <i class="fas fa-arrow-left mr-2"></i>
              <h1>Ask Question</h1>
            </div>
            <form
              action="#"
              method="post"
              enctype="multipart/form-data"
              class="form-horizontal"
            >
              <div class="form-group">
                <label>Product</label>
                <input
                  type="text"
                  value="MSI PRO MP241X 23.8 FHD Monitor"
                  placeholder="Your E-Mail"
                  id="input-email"
                  class="form-control"
                />
              </div>
              <div class="form-group required">
                <label for="input-email">Your E-Mail</label>
                <input
                  type="email"
                  name="email"
                  value="kantarahmanvu2017@gmail.com"
                  placeholder="Your E-Mail"
                  id="input-email"
                  class="form-control"
                />
              </div>
              <div class="form-group required">
                <label for="input-text">Your Question</label>
                <textarea
                  name="text"
                  id="input-text"
                  placeholder="Your Question"
                  class="form-input form-control "
                ></textarea>
              </div>
              <button type="submit" class="btn btn-primary mb-2">
                Submit
              </button>
              <a class="btn st-outline mb-2" href="#">
                Back
              </a>
            </form>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default ProductScreen;
