import React, { useState, useEffect } from 'react'; 
import { Form, Button } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer'
const ResetPasswordScreen = () => {
    
    return (
        <div>
                <FormContainer>
                <h2>Reset Password</h2>
                <Form >
                <Form.Group controlId="newPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value=""
                      
                        required
                    />
                </Form.Group>
                <Button type="submit" variant="primary">
                    Reset Password
                </Button>
            </Form>
            </FormContainer>
        </div>
    );
};

export default ResetPasswordScreen;
