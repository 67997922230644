import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const PrivacyPolicyScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <Row>
          <div class="priv-pol abt topper">
            {" "}
            <h1>Privacy Policy of Interwave Computers Ltd.</h1>
            <div class="pp-desc">
              {" "}
              <p>
                This privacy policy has been compiled to better serve those who
                are concerned with how their ‘Personally identifiable
                information’ (PII) is being used online. PII, as used in US
                privacy law and information security, is information that can be
                used on its own or with other information to identify, contact,
                or locate a single person, or to identify an individual in
                context. Please read our privacy policy carefully to get a clear
                understanding of how we collect, use, protect or otherwise
                handle your Personally Identifiable Information in accordance
                with our website.
              </p>
            </div>
            <h2>
              What personal information do we collect from the people that visit
              our website or blog?
            </h2>
            <p>
              When ordering or registering on our site, as appropriate, you may
              be asked to enter your name, email address, mailing address, phone
              number or other details to help you with your experience.
            </p>
            <h2>When do we collect information?</h2>
            <p>
              We collect information from you when you subscribe to a
              newsletter, respond to a survey, fill out a form or enter
              information on our site.
            </p>
            <h2>How do we use your information?</h2>
            <p>
              We may use the information we collect from you when you register,
              make a purchase, sign up for our newsletter, respond to a survey
              or marketing communication, surf the website, or use certain other
              site features in the following ways:
            </p>
            <ul>
              <li>
                To personalize user’s experience and to allow us to deliver the
                type of content and product offerings in which you are most
                interested.
              </li>
              <li>To improve our website in order to better serve you.</li>
              <li>
                To administer a contest, promotion, survey or other site
                feature.
              </li>
            </ul>
            <h2>How do we protect visitor information?</h2>
            <ul>
              <li>
                We do not use vulnerability scanning and/or scanning to PCI
                standards.We use regular Malware Scanning.
              </li>
              <li>We do not use an SSL certificate</li>
              <li>
                We do not need an SSL. Because, We only provide affiliate
                products, articles and information
              </li>
            </ul>
            <h2>Do we use ‘cookies’?</h2>
            <p>
              <strong>
                Yes, but we do not use cookies for tracking purposes.
              </strong>{" "}
              You can choose to have your computer warn you each time a cookie
              is being sent, or you can choose to turn off all cookies. You do
              this through your browser (like Internet Explorer) settings. Each
              browser is a little different, so look at your browser’s help menu
              to learn the correct way to modify your cookies.
            </p>
            <p>
              <strong>If users disable cookies in their browser:</strong> If you
              disable cookies, some features will be disabled. It will turn off
              some of the features that make your site experience more efficient
              and some of our services will not function properly.
            </p>
            <p>However, you can still browse our website.</p>
            <h2>Google</h2>
            <p>
              Google’s advertising requirements can be summed up by Google’s
              Advertising Principles. They are put in place to provide a
              positive experience for users.{" "}
              <a href="#" target="_blank" rel="nofollow">
                Visit Here
              </a>
            </p>
            <p>
              Google, as a third party vendor, uses cookies to serve ads on our
              site. Google’s use of the DART cookie enables it to serve ads to
              our users based on their visit to our site and other sites on the
              Internet. Users may opt out of the use of the DART cookie by
              visiting the Google ad and content network privacy policy.
            </p>
            <p>We have implemented the following:</p>
            <ul>
              <li>Remarketing with Google AdSense</li>
              <li>Google Display Network Impression Reporting</li>
              <li>Demographics and Interests Reporting</li>
              <li>DoubleClick Platform Integration</li>
            </ul>
            <p>
              We along with third-party vendors, such as Google use first-party
              cookies (such as the Google Analytics cookies) and third-party
              cookies (such as the DoubleClick cookie) or other third-party
              identifiers together to compile data regarding user interactions
              with ad impressions, and other ad service functions as they relate
              to our website.
            </p>
            <p>
              <strong>Opting out:</strong> Users can set preferences for how
              Google advertises to you using the Google Ad Settings page.
              Alternatively, you can opt out by visiting the Network Advertising
              initiative opt out page or permanently using the Google Analytics
              Opt Out Browser add on.
            </p>
            <h2>California Online Privacy Protection Act</h2>
            <p>
              CalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law’s
              reach stretches well beyond California to require a person or
              company in the United States (and conceivably the world) that
              operates websites collecting personally identifiable information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals with whom it is being shared, and to comply with
              this policy. – See more at:
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
            </p>
            <p>According to CalOPPA we agree to the following:</p>
            <p>
              <strong>Users can visit our site anonymously:</strong> Once this
              privacy policy is created, we will add a link to it on our home
              page, or as a minimum on the first significant page after entering
              our website. Our Privacy Policy link includes the word ‘Privacy’,
              and can be easily be found on the page specified above.
            </p>
            <p>
              Users will be notified of any privacy policy changes{" "}
              <strong>On our Privacy Policy Page.</strong> Users are able to
              change their personal information by emailing us or by logging in
              to their account
            </p>
            <h2>How does our site handle do not track signals?</h2>
            <p>
              We honor do not track signals and do not track, plant cookies, or
              use advertising when a Do Not Track (DNT) browser mechanism is in
              place.
            </p>
            <h2>Does our site allow third party behavioral tracking?</h2>
            <p>
              It’s also important to note that we allow third party behavioral
              tracking.
            </p>
            <h2>COPPA (Children Online Privacy Protection Act)</h2>
            <p>
              When it comes to the collection of personal information from
              children under 13, the Children’s Online Privacy Protection Act
              (COPPA) puts parents in control. The Federal Trade Commission, the
              nation’s consumer protection agency, enforces the COPPA Rule,
              which spells out what operators of websites and online services
              must do to protect children’s privacy and safety online.{" "}
              <strong>
                We do not specifically market to children under 13.
              </strong>
            </p>
            <h2>Fair Information Practices</h2>
            <p>
              The Fair Information Practices Principles form the backbone of
              privacy law in the United States and the concepts they include
              have played a significant role in the development of data
              protection laws around the globe. Understanding the Fair
              Information Practice Principles and how they should be implemented
              is critical to comply with the various privacy laws that protect
              personal information.
            </p>
            <p>
              In order to be in line with Fair Information Practices we will
              notify the users via email within 7 business days if a data breach
              occour. We also agree to the individual redress principle, which
              requires that individuals have a right to pursue legally
              enforceable rights against data collectors and processors who fail
              to adhere to the law. This principle requires not only that
              individuals have enforceable rights against data users, but also
              that individuals have recourse to courts or a government agency to
              investigate and/or prosecute non-compliance by data processors.
            </p>
            <h2>CAN SPAM Act</h2>
            <p>
              The CAN-SPAM Act is a law that sets the rules for commercial
              email, establishes requirements for commercial messages, gives
              recipients the right to have emails stopped from being sent to
              them, and spells out tough penalties for violations. We collect
              your email address in order to deliver fresh content.
            </p>
            <p>
              To be in accordance with CANSPAM we agree to the following: If at
              any time you would like to unsubscribe from receiving future
              emails, you can email us at<br></br>webmaster@Interwave Computers.com.bd and
              we will promptly remove you from ALL correspondence.
            </p>
            <div class="nb">
              {" "}
              <h3>Contacting Us</h3>
              <p>
                If there are any questions regarding this privacy policy you may
                contact us using the information below.
              </p>
              <p>
                Interwave Computers &amp; Engineering Ltd.<br></br>
              </p>
              <p>6th floor, 28 Kazi Nazrul Islam Ave,</p>
              <p>Navana Zohura Square, Dhaka 1000.</p>
              <p>
                Phone: <a href="tel:16793">16793</a> or{" "}
                <a href="tel:09678002003">09678002003</a>
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPolicyScreen;
