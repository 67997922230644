import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Paginate1 = ({ pages, page, url="/cat/laptop/", isCat = false, keyword = '' }) => {
    if (pages <= 1) {
        return " "; // If only 1 page, don't render pagination
    }
    return pages > 1 && (
        <Pagination>
            {[...Array(pages).keys()].map(x => (
                <LinkContainer key={x + 1} to={!isCat ? keyword ? `/cat/${keyword}/page/${x + 1}` : `/page/${x + 1}` : `${url}${x + 1}`}>
                    <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
                </LinkContainer>
            ))}
        </Pagination>
    )
}

export default Paginate1
