import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const PointPolicyScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <Row>
          <div class="sp abt topper">
            <h1>প্রশপ - পয়েন্ট !</h1>

            <div class="sp-desc">
              <p>
                প্রশপ অনলাইন শপ থেকে পয়েন্টযুক্ত যেকোনো পণ্য অনলাইন অর্ডার করে
                কিনলেই পাবেন নির্দিষ্ট পয়েন্ট!! পণ্য ডেলিভারি পাবার ৩ থেকে ৭
                দিনের মধ্যেই পয়েন্ট যোগ হয়ে যাবে আপনার প্রশপ একাউন্টে। যা প্রশপ
                অনলাইন শপ থেকে ভবিষ্যৎ কেনাকাটায় ব্যবহারযোগ্য!! প্রতিটি পয়েন্ট
                ১টাকার সমতুল্য অর্থাৎ ধরুন আপনি কোন পণ্য অর্ডার করে ১০০টি পয়েন্ট
                অর্জন করেছেন পরবর্তীতে যেকোনো অর্ডারে তা ব্যাবহার করে পাবেন
                ১০০টাকা পর্যন্ত ডিস্কাউন্ট।
              </p>
            </div>

            <h2>প্রশপ ওয়েবসাইট এ পয়েন্ট অর্জন ও ব্যবহারের শর্তসমূহ-</h2>

            <ul>
              <li>
                পয়েন্ট অর্জন, সংরক্ষন অথবা ব্যবহার করতে অবশ্যই ক্রেতাকে প্রশপ এর
                ওয়েবসাইটে একাউন্ট খুলে তা লগইন করে পয়েন্ট ব্যাবহার করতে হবে।
              </li>

              <li>
                পয়েন্ট থাকা পণ্য ডেলিভারির পর ৩ থেকে ৭ দিনের মধ্যে তা ক্রেতার
                প্রশপ একাউন্টে যোগ হয়ে যাবে এবং তা মেইলের মাধ্যমে ক্রেতাকে
                জানিয়ে দেয়া হবে।
              </li>

              <li>
                পয়েন্ট শুধুমাত্র প্রশপ অনলাইন শপে পণ্য ক্রয়ের ক্ষেত্রে
                ব্যবহারযোগ্য।
              </li>

              <li>একটি পয়েন্ট এক টাকা সমতুল্য।</li>

              <li>
                কোন স্পেশাল ক্যাম্পেইন অফারের পণ্যে / কোন পণ্যে ভাউচার বা কুপন
                ব্যাবহার করে ডিস্কাউন্ট পেলে সে পণ্য থেকে পয়েন্ট অর্জিত হবে না।
              </li>

              <li>
                একটি অর্ডারে প্রোমো কোড অথবা ভাউচার কোড ব্যবহার করলে ওই অর্ডারে
                পয়েন্ট ব্যাবহার করা যাবে না।
              </li>

              <li>
                পয়েন্ট থাকা কোন পণ্য EMI (ইএমআই) এ ক্রয় করলে তা থেকে পয়েন্ট
                পাওয়া যাবে না। তবে অনলাইন EMI অর্ডার এ পয়েন্ট ব্যবহার করে পণ্য
                ক্রয় করা যাবে।
              </li>

              <li>
                অর্ডারের মোট মূল্যের সর্বোচ্চ ৫০% (পঞ্চাশ ভাগ) পয়েন্টের মাধ্যমে
                পরিশোধযোগ্য।
              </li>

              <li> পয়েন্ট সরাসরি নগদ অর্থে রূপান্তরযোগ্য নয়।</li>

              <li>
                পেয়েন্ট এর মেয়াদ ৩ মাস অর্থাৎ ৩ মাসের মধ্যে খরচ না হলে তা
                একাউন্ট থেকে স্বয়ংক্রিয়ভাবে মুছে যাবে।
              </li>

              <li>
                পয়েন্ট ব্যাবহার করে ক্রয়ক্রিত পণ্য সংগত কারনে রিফান্ড অথবা
                রিটার্ন করা হলে পয়েন্টও রিফান্ড করা হবে। তবে এক্ষেত্রে প্রশপ এর
                গৃহীত যেকোনো সিদ্ধান্ত চূড়ান্ত বলে বিবেচিত হবে।
              </li>
            </ul>

            <iframe
              width="100%"
              height="552"
              src="https://www.youtube.com/embed/XBh4dCAFz1Q"
              title=" Interwave ComputersStar Point"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
            ></iframe>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PointPolicyScreen;
