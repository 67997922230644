import React, { useState, useEffect  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { noticeList } from '../actions/noticeAction'
import { NOTICE_LIST_REQUEST } from '../constants/noticeConstants'
import Loader from './Loader'
import Message from './Message'

export default function NoticeView() {

    const dispatch = useDispatch()

    const { loading, error, notices } = useSelector(state => state.noticeList)

    const [aTNotice, setATNotice] = useState();

    useEffect(() => {

        dispatch({ type: NOTICE_LIST_REQUEST })

        if (notices?.length == 0) {
            dispatch(noticeList())
        }

    }, [dispatch])

    useEffect(() => {


        if (!loading) {

            for (let index = 0; index < notices?.length; index++) {

                if (notices[index].isActive) {
                    setATNotice(notices[index]);
                    return;
                }

            }
        }


    }, [loading])

    return (
        <div className="hmove">

            <div className="hitem">
                {aTNotice?.description}
            </div>

        </div>
    )
}
