import React, { useState, useEffect } from "react";
import { Route, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import SearchBox from "./SearchBox";
import { logout } from "../actions/userActions";
import CartView from "../components/CartView";
import Message from "./Message";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  ListGroup,
  Col,
  InputGroup,
  Form,
  Image,
  Button,
  Modal,
} from "react-bootstrap";

const ProductFilter = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/category.json')
      .then(response => response.json())
      .then(data => {
        setJsonData(data);
        console.log('Fetched JSON data:', data);
      })
      .catch(error => {
        console.error('Error fetching the JSON data:', error);
      });
  }, []);

  const [showSubmenu, setShowSubmenu] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    function handleScroll() {
      const isScrolled = window.pageYOffset > 50;
      setScrolled(isScrolled);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDropdownClick = (id) => {
    if (selectedDropdown === id) {
      setSelectedDropdown(null); // Close the dropdown if it's already open
    } else {
      setSelectedDropdown(id); // Open the dropdown
    }
  };

  const handleDropdownHover = (id) => {
    setSelectedDropdown(id); // Set the selected dropdown on hover
  };

  const handleDropdownLeave = () => {
    setSelectedDropdown(null); // Reset the selected dropdown on leave
  };
  const hideModalAndRedirect = () => {
    setShow(false);
  };
  const getSubItemUrl = (name) => {
    switch (name) {
      case 'DVD-RW':
        return '/category/dvdrw';
      case 'Console':
        return '/category/console';
        case 'Gaming Chair':
          return '/category/gaming chair';
          case 'CC Camera':
          return '/category/cc camera';
          case 'Router':
          return '/category/router';
          case 'Processor':
            return '/category/processor';
            case 'Cpu Cooler':
              return '/category/cpu cooler';
              case 'Case':
              return '/category/case';
      default:
        return '';
    }
  };
  return (
    <>
      <LinkContainer to="/cart">
        <>
          <Nav.Link onClick={handleShow} className="d-flex justify-content-between align-items-center">
            <LinkContainer to="/" >
              <Navbar.Brand>
                <img className='img-fluid logo-width' src="../images/logo.jpg" alt="Logo" />
              </Navbar.Brand>
            </LinkContainer>
            <i class="fas fa-bars toggle_ico"></i>
          </Nav.Link>
        </>
      </LinkContainer>{" "}
      <Modal
        show={show}
        onHide={handleClose}
        scrollable
        className="custom_modal for_he"
      >
        <Modal.Header closeButton className="text-center">
          <Modal.Title>
            <LinkContainer to="/" >
              <Navbar.Brand>
                <img className='img-fluid logo-width' src="../images/logo.jpg" alt="Logo" />
              </Navbar.Brand>
            </LinkContainer>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto", padding: "unset" }}>
          <Navbar
            variant="dark"
            expand="lg"
            collapseOnSelect
            className="nav_bb"
          >
            <Container>
              <Route render={({ history }) => <SearchBox className="nav_formcontrol" history={history} />} />
              <Nav className=" d-flex  align_for w-100">
                <div className="padding_all marginTop border-0 bd-example">
                  <CartView />
                </div>
                {userInfo ? (
                  <Nav.Link className="mr-3 width_100">
                    <div class="d-flex ">
                      <div class="flex-shrink-0">
                        <i className='fas fa-user  admin_ico mr-2 mt-2'></i>
                      </div>
                      <div class="flex-grow-1 ms-3 line_height">
                        <NavDropdown
                          className="nav_username mr-1"
                          title={userInfo.name}
                          id="username"
                        >
                          <LinkContainer to="/profile">
                            <NavDropdown.Item className="color_black">
                              Profile
                            </NavDropdown.Item>
                          </LinkContainer>
                          <NavDropdown.Item
                            className="color_black"
                            onClick={logoutHandler}
                          >
                            Logout
                          </NavDropdown.Item>
                        </NavDropdown>
                        <br />
                        <span className='font_customize'> Your Account </span>
                      </div>
                    </div>
                  </Nav.Link>
                ) : (
                  <LinkContainer to="/login">
                    <Nav.Link className="mr-3 marginTop">
                      <div class="d-flex ">
                        <div class="flex-shrink-0">
                          <i className='fas fa-user  admin_ico mr-2 mt-2'></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <span>Account</span> <br></br>
                          <span className='font_customize'> Log in/Register </span>
                        </div>
                      </div>
                    </Nav.Link>
                  </LinkContainer>
                )}
                {userInfo && userInfo.isAdmin && (
                  <Nav.Link className=" marginTop">
                    <div class="d-flex ">
                      <div class="flex-shrink-0">
                        <i className='fas fa-user  admin_ico mr-2 mt-2'></i>
                      </div>
                      <div class="flex-grow-1 ms-3 line_height">
                        <NavDropdown title="Admin Panel" id="adminmenu" className="mr-1">
                          <LinkContainer to="/admin/userlist">
                            <NavDropdown.Item className="color_black">
                              Users
                            </NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/admin/productlist">
                            <NavDropdown.Item className="color_black">
                              Products
                            </NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/admin/orderlist">
                            <NavDropdown.Item className="color_black">
                              Orders
                            </NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/admin/offerslist">
                            <NavDropdown.Item className="color_black">
                              Offers
                            </NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/admin/notice/list">
                            <NavDropdown.Item className="color_black">
                              Notice
                            </NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                        <br />
                        <span className='font_customize'> Your Account </span>
                      </div>
                    </div>
                  </Nav.Link>
                )}
              </Nav>
              <Nav className=" mr-0 my-lg-0  nav_text d-lg-none" navbarScroll>
                {jsonData && jsonData.mainItems ? (
                  jsonData.mainItems.map((item) => (
                    <div
                      key={item.id}
                      id="navbarScrollingDropdown"
                      onClick={() => handleDropdownClick(item.id)}
                      onMouseEnter={() => handleDropdownHover(item.id)}
                      onMouseLeave={handleDropdownLeave}
                      className=" mr-0 pt-0 pb-0 nav_link"
                    >
                      <NavDropdown.Item
                        className="mr-0 pt-0 pb-0 nav_link"
                      >
                          <div className="d-flex 
                          ml-3">
            
             
                        {item.name === "Laptop" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/laptop" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "AC" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/ac" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Monitor" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/monitor" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Motherboard" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/motherboard" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Component" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/component" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) 
                        : item.name === "Speaker" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/speaker" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ): item.name === "Gaming" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/gaming" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Graphics Card" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/graphics card" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Headset" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/headset" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Mousepad" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/mousepad" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Power Supply" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/power supply" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Mouse" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/mouse" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Network" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/network" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        ) : item.name === "Security" ? (
                          <Link className="mr-0 pt-0 pb-0 " to="/category/security" onClick={hideModalAndRedirect}>
                            {item.name}
                          </Link>
                        )  : (

                          
                          <span>{item.name}</span>
                        )}
                         {item.subItems && item.subItems.length > 0 && (
                <i className="fas fa-caret-right submenu_icon"></i>
              )}
            </div>
                      </NavDropdown.Item>

                      {selectedDropdown === item.id && item.subItems && (
          <div className="sub_navLink">
            {item.subItems.map((subItem) => (
              <div key={subItem.url} className="dropdown-item-container bg_white">
                <Link
                  key={subItem.name}
                  className="mr-0 pt-0 pb-0 dropdown-item nav_web_link"
                  to={getSubItemUrl(subItem.name)}
                >
                  <div className="d-flex justify-content-between">
                    <span>{subItem.name}</span>
                    {subItem.subItems && subItem.subItems.length > 0 && (
                      <i className="fas fa-caret-right submenu_icon"></i>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
                    </div>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              </Nav>
            </Container>
          </Navbar>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ProductFilter;
