import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const ComplainAdviceScreen = ({ match, location, history }) => {
  return (
    <>
      <Row className="justify-content-center align-items-center ">
        <Col md={9} sm={12}>
          <section class="complain-form">
            <div class="container">
              <form
                class="row form-complain create-edit-form"
                method="post"
                action="/home/saveTask"
              >
                <div class="col-md-12 text-center">
                  <div class="complain-header">
                    <h1>Complain</h1>
                  </div>
                  <div class="complain-blurb">
                    <p>
                      Please fill out the following form with details <br></br>
                      We will review your request and follow up with you as soon
                      as possible.
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-name">
                      Full Name<small>*</small>
                    </label>
                    <input
                      type="text"
                      name="name"
                      class="form-control input-name"
                      maxlength="100"
                      validation="required"
                      placeholder="Type Your Full Name."
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-phone">
                      Phone No.<small>*</small>
                    </label>
                    <input
                      type="text"
                      name="phone"
                      class="form-control input-phone"
                      maxlength="100"
                      validation="required|phone"
                      placeholder="Type Your Mobile No."
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="input-email">Email Address</label>
                    <input
                      type="text"
                      id="input-email"
                      name="email"
                      class="form-control "
                      maxlength="100"
                      validation="email"
                      placeholder="Type Your Email Address."
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="input-title">
                      Subject<small>*</small>
                    </label>
                    <input
                      type="text"
                      id="input-title"
                      name="title"
                      class="form-control"
                      maxlength="150"
                      validation="required"
                      placeholder="Type Your Problem Subject"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="input-description">
                      Details<small>*</small>
                    </label>
                    <textarea
                      name="description"
                      id="input-description"
                      class="form-control"
                      maxlength="500"
                      validation="required"
                      placeholder="Write Your Problem In Details."
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input
                      type="submit"
                      class="btn-complain"
                      value="Submit Your Request"
                    />
                  </div>
                </div>

                <div class="col-md-12 text-center">
                  <div class="complain-copyright">
                    <span>
                      © Power By{" "}
                      <a href="#" rel="noopener" target="_blank">
                        Navigator technologies
                      </a>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default ComplainAdviceScreen;
