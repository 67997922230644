import React, { useState } from 'react'
import { Form, Button,InputGroup } from 'react-bootstrap'

const SearchBox = ({ history }) => {
    const [keyword, setKeyword] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()
        if (keyword.trim()) {
            history.push(`/search/${keyword}`)
        }
        else {
            history.push('/')
        }
    }

    return (
        <>
        <Form onSubmit={submitHandler} inline>
            
            <Form.Control 
           
            type='text' name='q' onChange={(e) => setKeyword(e.target.value)} 
 placeholder='Search Product...' 

  aria-label="Recipient's username"
  aria-describedby="basic-addon2"
  history={history}
  className="nav_serch_form">
            </Form.Control>
            <Button type='submit'  style={{    padding: "0.65rem 1.75rem", backgroundColor:"black"}}  className="nav_serch_btn">
            <i class="fas fa-search"></i>
            </Button>
        </Form>



{/* <InputGroup className="width_nav_fixed ">
<Form.Control
 type='text' name='q' onChange={(e) => setKeyword(e.target.value)} 
 placeholder='Search Product...' 

  aria-label="Recipient's username"
  aria-describedby="basic-addon2"
  history={history}
  className="nav_serch_form"
/>
<InputGroup.Text id="basic-addon2" className="nav_serch_btn">
  <i class="fas fa-search"></i>

</InputGroup.Text>
</InputGroup> */}
</>
    )
}

export default SearchBox
