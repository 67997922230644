
import React from 'react'
import { Link } from 'react-router-dom'
import {
    Container,
    Row,
    Col,
  } from "react-bootstrap";
const FeaturedCategory = ({ }) => {
    return (
<section className="mb-5">
<Container>
  <Row>
  
     
  
  </Row>
</Container>
</section>)}

export default FeaturedCategory