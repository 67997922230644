import React, { useState, useEffect  } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Paginate from '../../components/Paginate'
import {
    noticeList, noticeDelete, noticeUpdate
} from '../../actions/noticeAction'
import {

    NOTICE_LIST_REQUEST, NOTICE_UPDATE_REQUEST, NOTICE_UPDATE_RESET,

} from '../../constants/noticeConstants'


const NoticeListScreen = ({ history, match }) => {

    const dispatch = useDispatch()

    const { loading, loadSuccess, notices, error } = useSelector(state => state.noticeList)

    const { loadingDelete, successDelete: successDelete, errorDelete } = useSelector(state => state.noticeDelete)

    const { loadingUpdate, successUpdate } = useSelector(state => state.noticeUpdate);

    const { userInfo } = useSelector(state => state.userLogin)

    const [getNoticeList, setGetNoticeList] = useState([]);

    useEffect(() => {

        if (!userInfo.isAdmin) {
            history.push('/login')
        }

        dispatch({ type: NOTICE_LIST_REQUEST })

        dispatch(noticeList())


    }, [dispatch])

    useEffect(() => {


        if (successDelete) {
            dispatch({ type: NOTICE_LIST_REQUEST })
            dispatch(noticeList())
        }


    }, [successDelete])

    useEffect(() => {


        if (loadSuccess) {
            setGetNoticeList(notices)
        }


    }, [loadSuccess])

    useEffect(() => {

        if (successUpdate) {
            dispatch({ type: NOTICE_UPDATE_RESET });
        }


    }, [successUpdate])



    const deleteHandler = (id) => {

        dispatch(noticeDelete(id))

    }

    const createNoticetHandler = () => {
        history.push(`/admin/notice/create/`)
    }

    return (
        <>
            <Row className='align-items-center'>
                <Col>
                    <h1>Notice List</h1>
                </Col>
                <Col className='text-right'>
                    <Button className='my-3' onClick={createNoticetHandler}>
                        <i className='fas fa-plus'></i> Create new notice
                    </Button>
                </Col>
            </Row>

            {
                loadingDelete && <Loader />
            }

            {
                loadingUpdate && <Loader />
            }

            {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> :
                (
                    <>
                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>S.L</th>
                                    <th>TITLE</th>
                                    <th>DESCRIPTION</th>
                                    <th>STATUS</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getNoticeList.map((notice, idx) => (
                                    <tr key={notice._id}>
                                        <td>{idx + 1}</td>
                                        <td>{notice.title}</td>
                                        <td>{notice.description}</td>
                                        <td>
                                            <input type='radio' checked={notice.isActive} onClick={() => {

                                                dispatch({ type: NOTICE_UPDATE_REQUEST })

                                                let t_of = [...getNoticeList];
                                                t_of[idx].isActive = !t_of[idx].isActive;

                                                setGetNoticeList(t_of);

                                                dispatch(noticeUpdate({
                                                    "_id": notice._id,
                                                    "description": notice.description,
                                                    "isActive": t_of[idx].isActive,
                                                }))


                                            }} />
                                        </td>
                                        <td>
                                            {<LinkContainer to={`/admin/notice/edit/${notice._id}`}>
                                                <Button variant='light' className='btn-sm'>
                                                    <i className='fas fa-edit'></i>
                                                </Button>
                                            </LinkContainer>}
                                            <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(notice._id)}><i className='fas fa-trash'></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Paginate pages={7} page={1} url='/admin/notice/list/' isAdmin={true} />

                    </>
                )
            }
        </>
    )
}

export default NoticeListScreen
