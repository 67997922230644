import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="footer_body ">
      <Container>
        <Row>
          <Col lg={3} sm={12} xl={3} xxl={3} xs={12}>
            <h4>SUPPORT</h4>
            <a href="tel:16793" className="helpline-btn footer-big-btn mb-4">
              <div className="ic">
                <i className="fas fa-phone-alt"></i>
              </div>
              <p>9AM - 8PM</p>
              <h5>(880) 1777-171413</h5>
            </a>
            {/* <a href="tel:16793" className="helpline-btn footer-big-btn">
              <div className="ic">
              <i class="fas fa-map-marker-alt"></i>
              </div>
              <p>Store Locator</p>
              <h5>Find Our Stores</h5>
            </a> */}
          </Col>
          <Col lg={6} sm={12} xl={6} xxl={6} xs={12}>
            <h4>ABOUT US</h4>
            <ul>
              {/* <li>
                <Link to={`/emi-terms`}> EMI Terms</Link>
              </li> */}

              <li>
                <Link to={`/AboutUsScreen`}>About Us</Link>{" "}
              </li>
              {/* <li>
                <Link to={`/OnlineDeliveryScreen`}>Online Delivery</Link>{" "}
              </li> */}
 <li>
                <Link to={`/TermsandCondScreen`}>Terms & Conditions</Link>{" "}
              </li>
              {/* <li>
                <Link to={`/PrivacyPolicyScreen`}>Privacy Policy</Link>{" "}
              </li> */}
             
              <li>
                <Link to={`/RefundReturnPolicyScreen`}>
                  Refund & Return Policy
                </Link>{" "}
              </li>
              {/* <li>
                <Link to={`/PointPolicyScreen`}>Point Policy</Link>{" "}
              </li> */}
              {/* <li>
                <Link to={`/BlogScreen`}>Blog</Link>{" "}
              </li> */}
              <li>
                <Link to={`/ContactUsScreen`}>Contact Us</Link>{" "}
              </li>
{/* 
              <li>
                <Link to={`/BrandScreen`}>Brands</Link>{" "}
              </li> */}
              {/* <li>
                <Link
                  className="highlight_color"
                  to={`/OnlineServiceSupportScreen`}
                >
                  Online Service Support
                </Link>{" "}
              </li> */}
              {/* <li className="highlight_color">
                <Link className="highlight_color" to={`/ComplainAdviceScreen`}>
                  Complain / Advice
                </Link>{" "}
              </li> */}
            </ul>
          </Col>
          <Col lg={3} sm={12} xl={3} xxl={3} xs={12}>
            <h4>STAY CONNECTED</h4>
            <b>Interwave Computers</b>
            <address>
             Jalil Tower(2nd, 3rd Floor), Lower Jeshore Road, Khulna-9100
            </address>
            <address>Email:</address>{" "}
            <span className="highlight_color">interwavecomp@gmail.com</span>
          </Col>
        </Row>

        <div className="social-footer">
          {/* <div className="app-links">
            <span className="app-link-text">
              Experience Interwave Computers App on your mobile:
            </span>
            <span className="app-link-items">
              <a
                className="app-link"
                href="#"
                target="_blank"
                rel="noopener"
                title="Interwave Computers Android APP"
              >
                <span className="icon-sprite playstore"></span>
                <span className="app-link-text">
                  <span className="download">Download on</span>
                  <span className="app-store">Google Play</span>
                </span>
              </a>
              <a
                className="app-link"
                href="#"
                target="_blank"
                rel="noopener"
                title="Interwave Computers ISO APP"
              >
                <span className="icon-sprite applestore"></span>
                <span className="app-link-text">
                  <span className="download">Download on</span>
                  <span className="app-store">App Store</span>
                </span>
              </a>
            </span>
          </div> */}
          <div className="social-links">
            <a href="https://www.facebook.com/interwavecomputerbd" target="_blank" rel="noopener" title="Facebook">
            <img className='img-fluid f-icon' src="../images/facebook-f.svg" />
            </a>
            {/* <a href="#" target="_blank" rel="noopener" title="Youtube">
            <img className='img-fluid f-icon ' src="../images/whatsapp.svg" />
            </a>
            <a href="#" target="_blank" rel="noopener" title="Instagram">
            <img className='img-fluid f-icon' src="../images/instagram.svg" />
            </a>
            <a href="#" target="_blank" rel="noopener" title="Instagram">
            <img className='img-fluid f-icon' src="../images/twitter.svg" />
            </a> 
            <a href="#" target="_blank" rel="noopener" title="Instagram">
            <img className='img-fluid f-icon' src="../images/youtube.svg" />
            </a> 
            <a href="#" target="_blank" rel="noopener" title="Instagram">
            <img className='img-fluid f-icon' src="../images/linkedin-in.svg" />
            </a> */}
          </div>
        </div>
        <Row className=" sub-footer">
          <Col md={6} className=" copyright-info d-flex align-items-center">
            <p>© 2024 Interwave Computers &amp;  | All rights reserved</p>
          </Col>
          <Col md={6} className=" powered-by">
            {/* <Image className=" img-fluid" src="../images/SSLCommerz-3.png" /> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
