import axios from 'axios'

import {
    NOTICE_CREATE_REQUEST,
    NOTICE_CREATE_SUCCESS,
    NOTICE_CREATE_FAIL,

    NOTICE_LIST_REQUEST,
    NOTICE_LIST_SUCCESS,
    NOTICE_LIST_FAIL,

    NOTICE_DELETE_REQUEST,
    NOTICE_DELETE_SUCCESS,
    NOTICE_DELETE_FAIL,

    NOTICE_UPDATE_REQUEST,
    NOTICE_UPDATE_SUCCESS,
    NOTICE_UPDATE_FAIL,
} from '../constants/noticeConstants'

export const noticeCreate = (notice) => async (dispatch, getState) => {
    try {
        dispatch({
            type: NOTICE_CREATE_REQUEST,
        })

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        }

        const { data } = await axios.post(
            `/api/notices`,
            notice,
            config
        )

        dispatch({
            type: NOTICE_CREATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NOTICE_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const noticeDelete = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: NOTICE_DELETE_REQUEST,
        })

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        }

        await axios.delete(
            `/api/notices/${id}`,
            config
        )

        dispatch({
            type: NOTICE_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: NOTICE_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const noticeUpdate = (notice) => async (dispatch, getState) => {

    try {
        dispatch({
            type: NOTICE_UPDATE_REQUEST,
        })

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        }

        const { data } = await axios.put(
            `/api/notices/${notice._id}`,
            notice,
            config
        )

        dispatch({
            type: NOTICE_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NOTICE_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const noticeList = () => async (dispatch, getState) => {

    try {

        const { data } = await axios.get(
            `/api/notices`,
        )

        console.log("data", data);

        dispatch({
            type: NOTICE_LIST_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        dispatch({
            type: NOTICE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}
