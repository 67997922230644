import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const EmiTermScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <Row className=" emi-terms ">
          <Col>
            <div>
              <h2>Enjoy 0% EMI Facility From The Banks Listed Below</h2>
            </div>
          </Col>
          <Row className="g-3">
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-12 emi">
              <div class="emi-content">
                <div class="status">
                  <div class="status-online"></div>
                  <div class="status-offline"></div>
                </div>
                <img
                  className="img-fluid"
                  src="https://www.startech.com.bd/image/catalog/logo-brand/bank/ab-bank.webp"
                  alt="ab-bank-logo"
                />

                <div class="emi-details">
                  <h2>AB Bank Ltd.</h2>
                  <h4>
                    <span>Applicable For:</span>&nbsp;Online &amp; Offline
                  </h4>
                  <h4>
                    <span>EMI Tenure:</span>
                    3, 6 months(Online) &amp; 6, 9, 12 months(Offline)
                  </h4>
                  <h4>
                    <span>Min. Purchase:</span> BDT. 5,000
                  </h4>{" "}
                  <h4>
                    <span>Condition:</span> N/A
                  </h4>
                </div>
              </div>
            </div>
          </Row>
          <div class="row emi-tnc ">
            {" "}
            <div class="col-lg-12 col-md-12 col-xs-12 text-center">
              {" "}
              <h1>EMI সুবিধা কার্যকরের শর্তাবলি</h1>{" "}
              <ul>
                {" "}
                <li>
                  প্রশপ এর যেকোনো রিটেইল আউটলেট (ঢাকা, চট্রগ্রাম ও রংপুর) থেকে
                  ১২ মাসের 0% EMI সুবিধাটি উপভোগ করা যাবে।
                </li>{" "}
                <li>
                  0% EMI সুবিধাটি সর্বোচ্চ ১২ মাস মেয়াদ এর জন্য কার্যকর হবে।
                  এখানে চাইলে আপনি ৬ ,৯, ১২ মাসের EMI সুবিধাটি উপভোগ করতে
                  পারবেন।
                </li>{" "}
                <li>
                  সর্বনিম্ন ৫০০০ টাকার পণ্য এবং কিছু কিছু ব্যাংক এর ক্ষেত্রে
                  সর্বনিম্ন ১০০০০ টাকার পণ্য ক্রয়ের ক্ষেত্রে এই সুবিধাটি
                  প্রযোজ্য হবে।
                </li>{" "}
                <li>
                  অনলাইন EMI সুবিধাটি শুধুমাত্র ৩ এবং ৬ মাসের জন্য প্রযোজ্য।
                </li>{" "}
                <li>
                  EMI সুবিধার ক্ষেত্রে স্পেশাল প্রাইস / ডিস্কাউন্ট প্রাইস
                  প্রযোজ্য নয়, এক্ষেত্রে রেগুলার প্রাইস প্রযোজ্য হবে।
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>

          <div class="col-lg-12 col-md-12 col-xs-12">
            {" "}
            <img
              className="img-fluid"
              src="https://www.startech.com.bd/image/catalog/logo-brand/bank/saadiq-pf-banner.webp"
              alt="Saadiq Personal Finance"
              width="1300"
              height="400"
            />{" "}
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12 saadiq-tnc">
            {" "}
            <h1>Saadiq Personal Finance সুবিধার শর্তাবলি</h1>{" "}
            <ul>
              {" "}
              <li>
                চাকুরীজীবীর ক্ষেত্রে যেকোনো ব্যাংকে সেলারি একাউন্ট থাকলে অথবা
                ব্যবসায়ী বা ডাক্তার হলে এই সুবিধাটি পেতে পারেন।
              </li>{" "}
              <li>
                স্যালারি Standard Chartered Bank এ সর্বনিম্ন ২২,০০০ টাকা ও
                অন্যান্য ব্যাংক এ সর্বনিম্ন ৩৫,০০০ টাকা স্যালারি হতে হবে।
              </li>{" "}
              <li>
                ব্যবসায়ীদের ক্ষেত্রে সর্বনিম্ন ৫৫,০০০ টাকা ইনকাম থাকতে হবে।
              </li>{" "}
            </ul>{" "}
            <h1>Saadiq Personal Finance -এ পণ্য ক্রয়ের নিয়মাবলি</h1>{" "}
            <ul>
              {" "}
              <li>
                ক্রেতা যে পণ্য অথবা পণ্যসমূহ কিনবেন তার একটি ফর্মাল কোটেশন টেক
                থেকে নিতে হবে।
              </li>{" "}
              <li>
                ক্রেতা Standard Chartered Bank এর{" "}
                <a
                  href="https://www.sc.com/bd/islamic-banking/saadiq-personal-finance/"
                  target="_blank"
                  rel="no-follow"
                >
                  ওয়েবসাইট
                </a>{" "}
                থেকে মোট এমাউন্ট ও পেমেন্ট সময় দিয়ে Apply Now তে ক্লিক করে
                এপ্লাই করতে পারবেন অথবা ক্রেতা চাইলে প্রশপ এর যেকোনো হটলাইন
                এজেন্টকে তার নাম ও ফোন নাম্বার দিলে ব্যাংক থেকে ক্রেতার সাথে
                যোগাযোগ করা হবে।
              </li>{" "}
              <li>
                পরবর্তীতে সকল ডকুমেন্ট ব্যাংকে জমা দিয়ে ব্যাংক কতৃক এসেসমেন্ট এর
                পর ক্রেতা ব্যাংকের পে-অর্ডার নিয়ে প্রশপ থেকে তার পণ্যটি কিনতে
                পারবেন।
              </li>{" "}
            </ul>{" "}
            <h4>
              বিস্তারিত জানতে ভিজিট করুনঃ{" "}
              <a
                href="https://www.sc.com/bd/islamic-banking/saadiq-personal-finance/"
                target="_blank"
                rel="no-follow"
              >
                https://www.sc.com/bd/islamic-banking/saadiq-personal-finance/
              </a>
              , অথবা
            </h4>{" "}
            <h4>
              কল করুন:{" "}
              <a href="tel:16793" target="_blank">
                16793
              </a>
              অথবা{" "}
              <a href="tel:09678002003" target="_blank">
                09678002003
              </a>{" "}
              -এই নম্বরে।
            </h4>{" "}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EmiTermScreen;
