import React, { useState, useEffect  } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Paginate from '../../components/Paginate'
import {
    offersList, offerDelete, offerUpdate
} from '../../actions/offersAction'
import {

    OFFER_LIST_REQUEST, OFFER_UPDATE_REQUEST, OFFER_UPDATE_RESET,

} from '../../constants/offerConstants'


const OffersListScreen = ({ history, match }) => {
    const pageNumber = match.params.pageNumber || 1

    const dispatch = useDispatch()

    const { loading, loadSuccess, error, offers, page, pages } = useSelector(state => state.offersList)

    const { loadingUpdate: loadingUpdate, successUpdate } = useSelector(state => state.offerUpdate)

    const { loadingDelete, successDelete: successDelete, errorDelete } = useSelector(state => state.offerDelete)

    const { userInfo } = useSelector(state => state.userLogin)

    const [getOffersList, setGetOffersList] = useState([]);

    useEffect(() => {

        if (!userInfo.isAdmin) {
            history.push('/login')
        }

        dispatch({ type: OFFER_LIST_REQUEST })
        dispatch(offersList())


    }, [dispatch])

    useEffect(() => {


        if (successDelete) {
            dispatch({ type: OFFER_LIST_REQUEST })
            dispatch(offersList())
        }


    }, [successDelete])

    useEffect(() => {


        if (loadSuccess) {
            setGetOffersList(offers)
        }


    }, [loadSuccess])

    useEffect(() => {


        if (successUpdate) {
            dispatch({ type: OFFER_UPDATE_RESET })
        }


    }, [successUpdate])





    const deleteHandler = (id) => {

        dispatch(offerDelete(id))

    }

    const createOffertHandler = () => {
        history.push(`/admin/offer/create/`)
    }

    return (
        <>
            <Row className='align-items-center'>
                <Col>
                    <h1>Offers List</h1>
                </Col>
                <Col className='text-right'>
                    <Button className='my-3' onClick={createOffertHandler}>
                        <i className='fas fa-plus'></i> Create Offers
                    </Button>
                </Col>
            </Row>

            {
                loadingDelete && <Loader />
            }

            {
                loadingUpdate && <Loader />
            }

            {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> :
                (
                    <>
                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>S.L</th>
                                    <th>TITLE</th>
                                    <th>DESCRIPTION</th>
                                    <th>IMAGE</th>
                                    <th>STATUS</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getOffersList.map((offer, idx) => (
                                    <tr key={offer._id}>
                                        <td>{idx + 1}</td>
                                        <td>{offer.title}</td>
                                        <td>{offer.description}</td>
                                        <td><img width={55} height={55} src={`${offer.imageUrl}`} /></td>
                                        <td>
                                            <input type='radio' checked={offer.isActive} onClick={() => {

                                                dispatch({ type: OFFER_UPDATE_REQUEST })

                                                let t_of = [...getOffersList];
                                                t_of[idx].isActive = !t_of[idx].isActive;

                                                setGetOffersList(t_of);

                                                dispatch(offerUpdate({
                                                    "_id": offer._id,
                                                    "description": offer.description,
                                                    "isActive": t_of[idx].isActive,
                                                }))
                                            }} />
                                        </td>
                                        <td>
                                            <LinkContainer to={`/admin/offer/${offer._id}/edit`}>
                                                <Button variant='light' className='btn-sm'>
                                                    <i className='fas fa-edit'></i>
                                                </Button>
                                            </LinkContainer>
                                            <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(offer._id)}><i className='fas fa-trash'></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Paginate pages={pages} page={page} isAdmin={true} />
                    </>
                )
            }
        </>
    )
}

export default OffersListScreen
