import {
    NOTICE_CREATE_REQUEST,
    NOTICE_CREATE_SUCCESS,
    NOTICE_CREATE_FAIL,

    NOTICE_LIST_REQUEST,
    NOTICE_LIST_SUCCESS,
    NOTICE_LIST_FAIL,

    NOTICE_DELETE_REQUEST,
    NOTICE_DELETE_SUCCESS,
    NOTICE_DELETE_FAIL,

    NOTICE_UPDATE_REQUEST,
    NOTICE_UPDATE_SUCCESS,
    NOTICE_UPDATE_FAIL,
    NOTICE_UPDATE_RESET,
} from '../constants/noticeConstants'

export const noticeCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTICE_CREATE_REQUEST:
            return { loading: false }
        case NOTICE_CREATE_SUCCESS:
            return { loading: false, success: true, notices: action.payload }
        case NOTICE_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const noticeUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTICE_UPDATE_RESET:
            return {loadingUpdate: false, successUpdate: false }
        case NOTICE_UPDATE_REQUEST:
            return { loadingUpdate: true, successUpdate: false }
        case NOTICE_UPDATE_SUCCESS:
            return { loadingUpdate: true, successUpdate: true, notices: action.payload }
        case NOTICE_UPDATE_FAIL:
            return { loadingUpdate: false, successUpdate: false, error: action.payload }
        default:
            return state
    }
}

export const noticeDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTICE_DELETE_REQUEST:
            return { loading: false }
        case NOTICE_DELETE_SUCCESS:
            return { loadingDelete: false, successDelete: true, notices: action.payload }
        case NOTICE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const noticeListReducer = (state = { notices: [] }, action) => {
    switch (action.type) {
        case NOTICE_LIST_REQUEST:
            return { loading: true, loadSuccess: false, notices: [] }
        case NOTICE_LIST_SUCCESS:
            return {
                loading: false,
                loadSuccess: true,
                notices: action.payload
            }
        case NOTICE_LIST_FAIL:
            return { loading: false, loadSuccess: false, error: action.payload }
        default:
            return state
    }
}