import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import {

    OFFER_CREATE_REQUEST,

} from '../../constants/offerConstants'
import { noticeCreate } from '../../actions/noticeAction'

const NoticeCreateScreen = ({ match, history }) => {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const dispatch = useDispatch()


    const { loading, error, success: successCreate } = useSelector((state) => state.noticeCreate)

    useEffect(() => {

        if (successCreate) {
            dispatch({
                type: OFFER_CREATE_REQUEST,
            })
            history.push('/admin/notice/list')
        }

    }, [dispatch, successCreate])


    const submitHandler = (e) => {
        e.preventDefault()


        dispatch({ type: "OFFER_CREATE_REQUEST" })

        dispatch(noticeCreate({
            "title": title,
            "description": description,
        }))
    }

    return (
        <>
            <Link to='/admin/notice/list' className='btn btn-light mb-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Create new notice</h1>
                {loading && <Loader />}
                {error && <Message variant='danger'>{error}</Message>}
                {
                    (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='title'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type='name'
                                    placeholder='Enter here offer title'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Button type='submit' variant='primary'>
                                Save
                            </Button>
                        </Form>
                    )}
            </FormContainer>
        </>
    )
}

export default NoticeCreateScreen
