import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const PcBuilderScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <section className="build-your-pc bg-bt-gray">
              <div className="priv-pol abt topper">
                <div className="container">
                  <div className="pcbuilder_containar">
                    <div className="pcbuilder_header">
                      <div className="site_logo">
                        <img className="logo" src="/images/logo.jpg" alt="Logo" />
                      </div>
                      <div className="pcbaction">
                        <div className="pcbuilder_actions">
                          <a className="action" href="#">
                            <i
                              className="fas fa-shopping-cart admin_ico mb-1"
                              style={{ color: "#f18e18" }}
                            ></i>
                            <span className="pcbuilder_text">Add to Cart</span>
                          </a>
                          <a className="action" href="#">
                            <i
                              className="fas fa-save  admin_ico mb-1"
                              style={{ color: "#f18e18" }}
                            ></i>
                            <span className="pcbuilder_text">Save PC</span>
                          </a>
                          <a className="action m-hide" href="#">
                            <i
                              className="fas fa-print  admin_ico mb-1"
                              style={{ color: "#f18e18" }}
                            ></i>
                            <span className="pcbuilder_text">Print</span>
                          </a>
                          <form
                            action="#"
                            target="_blank"
                            method="post"
                            id="form-base64-image"
                          >
                            <button type="submit" className="action">
                              <i
                                className="fas fa-camera admin_ico mb-1"
                                style={{ color: "#f18e18" }}
                              ></i>
                              <span className="pcbuilder_text">Screenshot</span>
                            </button>
                          </form>
                          <a className="pcb-button btn st-outline" href="#">
                            Get a Quote
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="pcb-inner-content">
                      <div className="pcb-top-content">
                        <div className="left">
                          <h1 className="m-hide">
                            PC Builder - Build Your Own Computer 
                          </h1>
                          <div className="checkbox-inline">
                            <input
                              type="checkbox"
                              name="hide"
                              id="input-hide"
                            />
                            <label for="input-hide">
                              Hide Unconfigured Components
                            </label>
                          </div>
                        </div>
                        <div className="right">
                          <div className="total-amount estimated-watt">
                            <span className="amount">0W</span>

                            <br></br>
                            <span className="items">Estimated Wattage</span>
                            <span className="betaa">BETA</span>
                          </div>
                          <div className="total-amount t-price">
                            <span className="amount">0৳</span>
                            <br></br>
                            <span className="items">0 Items</span>
                          </div>
                        </div>
                      </div>
                      <div className="pcbuilder_cont">
                        <div className="content-label">Core Components</div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico cpu"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>CPU</span>
                              <span className="mark">Required</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                          <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico cpu-cooler"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>CPU Cooler</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico motherboard"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Motherboard</span>
                              <span className="mark">Required</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                          <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico ram"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>RAM</span>
                              <span className="mark">Required</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                          <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico storage"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Storage</span>
                              <span className="mark">Required</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                          <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico graphics-card"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Graphics Card</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                          <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico power-supply"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Power Supply</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico casing"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Casing</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="content-label">
                          Peripherals &amp; Others
                        </div>{" "}
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico monitor"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Monitor</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico casing-cooler"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Casing Cooler</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico keyboard"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Keyboard</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico mouse"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Mouse</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico anti-virus"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Anti Virus</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico headphone"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>Headphone</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                        <div className="pcuilder_items blank">
                          <div className="img">
                            <span className="img-ico ups"></span>
                          </div>
                          <div className="details">
                            <div className="component-name">
                              <span>UPS</span>
                            </div>
                            <div className="pcbuilder_prod_name"></div>
                          </div>
                          {/* <div className="item-price"></div> */}
                          <div className="actions">
                            <a className="btn st-outline" href="/PcBuilderChooseScreen" title="">
                              Choose
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PcBuilderScreen;
