import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import { offersList, offerUpdate } from '../../actions/offersAction'

import {

    OFFER_LIST_REQUEST,
    OFFER_UPDATE_REQUEST,
    OFFER_UPDATE_RESET,

} from '../../constants/offerConstants'

const OfferEditScreen = ({ match, history }) => {

    const offerId = match.params.id

    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [isActive, setIsActive] = useState('')
    const [uploading, setUploading] = useState(false)

    const dispatch = useDispatch()

    const { loadSuccess, offers } = useSelector(state => state.offersList)

    const { loading, error, successUpdate: success } = useSelector((state) => state.offerUpdate)

    useEffect(() => {

        if (success) {
            dispatch({
                type: OFFER_UPDATE_RESET,
            })
            history.push('/admin/offerslist')


        }

        if (offers.length == 0) {
            dispatch({ type: OFFER_LIST_REQUEST })
            dispatch(offersList())
        }



    }, [dispatch, success])


    useEffect(() => {


        if (loadSuccess) {
            console.log("offerId", offerId);

            for (let index = 0; index < offers.length; index++) {

                if (offers[index]._id == offerId) {
                    setTitle(offers[index].title)
                    setDescription(offers[index].description)
                    setImage(offers[index].imageUrl)
                    setIsActive(offers[index].isActive);
                    break;
                }

            }

            // 64b633aea4e84a04db8143bb
        }


    }, [loadSuccess])

    const uploadfileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/upload', formData, config)

            setImage(data)
            setUploading(false)
        }
        catch (error) {
            console.log(error)
            setUploading(false)
        }
    }



    const submitHandler = (e) => {

        e.preventDefault()

        dispatch({ type: OFFER_UPDATE_REQUEST })

        dispatch(offerUpdate({
            "_id": offerId,
            "title": title,
            "description": description,
            "imageUrl": image,
            "isActive": isActive,
        }))
    }

    return (
        <>
            <Link to='/admin/offerslist' className='btn btn-light mb-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Offer edit</h1>
                {loading && <Loader />}
                {error && <Message variant='danger'>{error}</Message>}
                {
                    (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='title'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type='name'
                                    placeholder='Enter here offer title'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='image'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter image url'
                                    value={image}
                                    onChange={(e) => setImage(e.target.value)}
                                ></Form.Control>
                                <Form.File id='image-file' label='Choose File' custom onChange={uploadfileHandler}></Form.File>

                                <br />
                                <br />
                                {
                                    image && <img width={140} height={90} src={`${image}`} />
                                }
                                
                                {uploading && <Loader />}
                            </Form.Group>

                            <Button type='submit' variant='primary'>
                                Update
                            </Button>
                        </Form>
                    )}
            </FormContainer>
        </>
    )
}

export default OfferEditScreen
