import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  span,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const BlogScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <section class="bg-bt-gray p-tb-15 ">
          <div class="container">
            <div class="row">
              <div id="content" class="col-sm-12">
                {" "}
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="article-thumb">
                      <div class="img-holder">
                        <a href="https://www.startech.com.bd/blog/ac-buying-guide">
                          <img
                            src="https://www.startech.com.bd/image/cache/catalog/blog/2023/ac-buying-guide/ac-air-conditioner-buying-guide-bd-370x370-370x370.jpg"
                            alt="Air Conditioner (AC) Buying Guide: Buy The Best AC in 2023"
                            width="370"
                            height="370"
                          />
                        </a>
                        <h3 class="name">
                          Air Conditioner (AC) Buying Guide: Buy The Best AC in
                          2023
                        </h3>
                      </div>
                      <div class="blog-info">
                        <div class="meta">
                          <span class="author">
                            <i class="fas fa-user-circle"></i>
                            <span>Interwave Computers Team</span>
                          </span>
                          <span class="date">
                            <i class="far fa-clock mr-2"></i>
                            <span>06 May 2023</span>
                          </span>
                        </div>
                        <div class="intro-text">
                          Air Conditioner (AC) Buying Guide: Everything To Know
                          Before Purchasing an AC
                        </div>
                        <Link to={"/BlogDetailsScreen"}>
                          <span class="btn color_btn_blog">Read More ...</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="article-thumb">
                      <div class="img-holder">
                        <a href="https://www.startech.com.bd/blog/best-smartphones-under-15000">
                          <img
                            src="https://www.startech.com.bd/image/cache/catalog/blog/2023/five-smartphone-under-15000bdt/five-best-smartphones-under-15000-bdt-in-2023-thumb-370x370.jpg"
                            alt="Five Best Smartphone Under 15000 Tk in Bangladesh"
                            width="370"
                            height="370"
                          />
                        </a>
                        <h3 class="name">
                          Five Best Smartphone Under 15000 Tk in Bangladesh
                        </h3>
                      </div>
                      <div class="blog-info">
                        <div class="meta">
                          <span class="author">
                            <i class="fas fa-user-circle"></i>
                            <span>Interwave Computers Team</span>
                          </span>
                          <span class="date">
                            <i class="far fa-clock mr-2"></i>
                            <span>06 Apr 2023</span>
                          </span>
                        </div>
                        <div class="intro-text">
                          Searching for the best smartphone below 15000 Tk? This
                          article is your guide to buying the best smartphones
                          under 15000 BDT in 2023.
                        </div>
                        <Link to={"/BlogDetailsScreen"}>
                          <span class="btn color_btn_blog">Read More ...</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="article-thumb">
                      <div class="img-holder">
                        <a href="https://www.startech.com.bd/blog/best-gaming-chairs-you-can-buy">
                          <img
                            src="https://www.startech.com.bd/image/cache/catalog/blog/gaming-chair-bd/best-gaming-chairs-in-2023-star-techt-humb-370x370.jpg"
                            alt="Best Gaming Chairs in Bangladesh to Buy in 2023"
                            width="370"
                            height="370"
                          />
                        </a>
                        <h3 class="name">
                          Best Gaming Chairs in Bangladesh to Buy in 2023
                        </h3>
                      </div>
                      <div class="blog-info">
                        <div class="meta">
                          <span class="author">
                            <i class="fas fa-user-circle"></i>
                            <span>Interwave Computers Team</span>
                          </span>
                          <span class="date">
                            <i class="far fa-clock mr-2"></i>
                            <span>03 Mar 2023</span>
                          </span>
                        </div>
                        <div class="intro-text">
                          List &amp; review of the Best Gaming Chairs you can
                          buy in 2023
                        </div>
                        <Link to={"/BlogDetailsScreen"}>
                          <span class="btn color_btn_blog">Read More ...</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="article-thumb">
                      <div class="img-holder">
                        <a href="https://www.startech.com.bd/blog/samsung-s23-ultra-the-perfect-balance-of-style-and-substance">
                          <img
                            src="https://www.startech.com.bd/image/cache/catalog/blog/2023/samsung-s23-ultra/samsung-s23-ultra-perfect-balance-of-style-and-substance-thumb-370x370.jpg"
                            alt="Samsung S23 Ultra: The Perfect Balance of Style and Substance"
                            width="370"
                            height="370"
                          />
                        </a>
                        <h3 class="name">
                          Samsung S23 Ultra: The Perfect Balance of Style and
                          Substance
                        </h3>
                      </div>
                      <div class="blog-info">
                        <div class="meta">
                          <span class="author">
                            <i class="fas fa-user-circle"></i>
                            <span>Interwave Computers Team</span>
                          </span>
                          <span class="date">
                            <i class="far fa-clock mr-2"></i>
                            <span>19 Feb 2023</span>
                          </span>
                        </div>
                        <div class="intro-text">
                          Samsung S23 Ultra: The Perfect Balance of Style and
                          Substance
                        </div>
                        <Link to={"/BlogDetailsScreen"}>
                          <span class="btn color_btn_blog">Read More ...</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="article-thumb">
                      <div class="img-holder">
                        <a href="https://www.startech.com.bd/blog/top-5-best-budget-laptops-for-students">
                          <img
                            src="https://www.startech.com.bd/image/cache/catalog/blog/2023/best-budget-laptop/top-5-best-budget-laptops-for-students-thumb-370x370.jpg"
                            alt="Top 5 Best Budget Laptops for Students in 2023"
                            width="370"
                            height="370"
                          />
                        </a>
                        <h3 class="name">
                          Top 5 Best Budget Laptops for Students in 2023
                        </h3>
                      </div>
                      <div class="blog-info">
                        <div class="meta">
                          <span class="author">
                            <i class="fas fa-user-circle"></i>
                            <span>Interwave Computers Team</span>
                          </span>
                          <span class="date">
                            <i class="far fa-clock mr-2"></i>
                            <span>04 Feb 2023</span>
                          </span>
                        </div>
                        <div class="intro-text">
                          Top 5 Best Budget Laptops for Students in 2023
                        </div>
                        <Link to={"/BlogDetailsScreen"}>
                          <span class="btn color_btn_blog">Read More ...</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="article-thumb">
                      <div class="img-holder">
                        <a href="https://www.startech.com.bd/blog/best-gaming-headphones-you-can-buy">
                          <img
                            src="https://www.startech.com.bd/image/cache/catalog/blog/2023/best-headphone/best-gaming-headphones-you-can-buy-thumb-370x370.jpg"
                            alt="Best Gaming Headphones, You Can Buy in 2023"
                            width="370"
                            height="370"
                          />
                        </a>
                        <h3 class="name">
                          Best Gaming Headphones, You Can Buy in 2023
                        </h3>
                      </div>
                      <div class="blog-info">
                        <div class="meta">
                          <span class="author">
                            <i class="fas fa-user-circle"></i>
                            <span>Interwave Computers Team</span>
                          </span>
                          <span class="date">
                            <i class="far fa-clock mr-2"></i>
                            <span>03 Feb 2023</span>
                          </span>
                        </div>
                        <div class="intro-text">
                          Best Gaming Headphones, You Can Buy in 2023
                        </div>
                        <Link to={"/BlogDetailsScreen"}>
                          <span class="btn color_btn_blog">Read More ...</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottom-bar">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <ul class="pagination">
                        <li>
                          <span class="disabled">PREV</span>
                        </li>
                        <li class="active">
                          <span>1</span>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=2">
                            2
                          </a>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=3">
                            3
                          </a>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=4">
                            4
                          </a>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=5">
                            5
                          </a>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=6">
                            6
                          </a>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=7">
                            7
                          </a>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=8">
                            8
                          </a>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=9">
                            9
                          </a>
                        </li>
                        <li>
                          <a href="https://www.startech.com.bd/blog?page=2">
                            NEXT
                          </a>
                        </li>
                      </ul>{" "}
                    </div>
                    <div class="col-md-6 rs-none text-right">
                      <p>Showing 1 to 6 of 67 (12 Pages)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default BlogScreen;
