import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const TermsandCondScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <Row>
          <div class="tnc abt topper">
            <div class="tnc-desc">
              <p>
                সম্মানিত ক্রেতাবৃন্দ, প্রশপ সব সময় কাস্টমারদের সর্বোচ্চ গুরুত্ব
                দিয়ে থাকে। এতদসত্বেও গ্রাহক সেবার মান উন্নত, সময়োপযোগী এবং
                দ্রুততর করার জন্যে কিছু নিয়ম কানুন মেনে কার্য পরিচালনা করতে
                হয়। সন্মানিত গ্রাহকগনের প্রতি বিশেষভাবে অনুরোধ Interwave Computers &amp;
                Engineering Ltd থেকে কম্পিউটার পণ্য কেনার পূর্বে{" "}
                <strong>
                  নিন্ম উল্লেখিত নিয়মাবলি ভালোভাবে অনুসরণ করবেন। ধন্যবাদ।
                </strong>
              </p>

              <p>
                বিক্রয়ের সময় যে সমস্ত প্রোডাক্টের ওয়ারেন্টি ঘোষণা করা হয় সেগুলো
                মূলত পন্য প্রস্তুতকারক কর্তৃক প্রদান করা ওয়ারেন্টি । অর্থাৎ
                বিক্রিত পণ্যের ওয়ারেন্টি সেবা মূলত নির্দিষ্ট ব্রান্ডের মূল
                কোম্পানী বহন করে থাকে। ওয়ারেন্টি সেবার ভিন্নতার দিক থেকে
                প্রত্যেকটি ব্র্যান্ড সতন্ত্র এবং তাঁদের বিভিন্ন শর্তাবলী নিজস্ব
                অফিশিয়াল ওয়েবসাইটে উল্লেখ করা আছে। এক্ষেত্রে সাহায্যকারী
                প্রতিষ্ঠান Interwave Computers, মূল ব্রান্ডের কোম্পানি গুলোর ওয়ারেন্টি সেবার
                শর্তাবলী কার্যকর করার মাধ্যম হিসেবে কাজ করছে।
              </p>
            </div>

            <h1>
              প্রস্তুতকারী প্রতিষ্ঠান নির্ধারিত ওয়ারেন্টি শর্তাবলী নিম্নরূপঃ
            </h1>

            <ul>
              <li>
                আমরা প্রতিটি প্রোডাক্ট এর{" "}
                <strong>
                  আন্তর্জাতিক, দেশীয় ও বাংলাদেশ কম্পিউটার সমিতি (BCS) কর্তৃক
                  প্রদত্ত ওয়ারেন্টি নীতিমালা অনুসরন করি।
                </strong>
              </li>

              <li>
                Interwave Computers &amp; Engineering Ltd. কর্তৃক আমদানিকৃ্ত অধিকাংশ
                প্রোডাক্ট এর ওয়ারেন্টি স্বল্প সময়ের মধ্যে প্রধান করা হয় এবং
                বেশকিছু প্রোডাক্ট এর অভিযোগ আসা মাত্র তা পরিবর্তন করে দেওয়া হয়।
              </li>

              <li>
                বিক্রিত সকল প্রোডাক্ট এ ওয়ারেন্টি প্রদান করা হয় না। শুধুমাত্র
                যেসকল প্রোডাক্ট গুলোতে মূল কোম্পানি ওয়ারেন্টি মেয়াদ ঘোষণা করে
                থাকে সেগুলোর ক্ষেত্রে ওয়ারেন্টি কার্যকর হয়ে থাকে।
              </li>

              <li>
                ল্যাপটপের{" "}
                <strong>ব্র্যান্ড ও মডেল ভেদে ওয়ারেন্টি ১-৩ বছর হয়।</strong>{" "}
                কিন্তু সকল ল্যাপটপ{" "}
                <strong>ব্যাটারি ও এডাপ্টারের ওয়ারেন্টি শুধমাত্র ১ বছর</strong>।
              </li>

              <li>
                ওয়ারেন্টির আওতাভুক্ত কোন প্রোডাক্ট বিক্রির পর যদি তাতে ত্রুটি
                ধরা পড়ে, তবে মেরামতের মাধ্যমে সেই ত্রুটি দূর করা হয় এবং পন্যের
                প্রকারভেদে তা সাথে সাথে পরিবর্তন করে দেওয়া হয়ে থাকে।
              </li>

              <li>
                নির্দিষ্ট মডেলের প্রোডাক্ট বদলে দেয়ার মতন না থেকে থাকলে Interwave Computers নিজস্ব স্টকে বর্তমান অন্য কোন ব্র্যান্ডের সমমানের পণ্য দিয়ে
                বদল করে দিতে পারে।
              </li>

              <li>
                নির্দিষ্ট মডেলের প্রোডাক্ট মেরামতের অযোগ্য ও বদলে দেয়ার মতন একই
                কিংবা সমমানের পণ্য যদি আমাদের স্টকে বর্তমান না থাকে সেক্ষেত্রে
                উক্ত মডেল থেকে ভাল কোন প্রোডাক্ট অবচয় ও মূল্য সমন্বয় এর মাধ্যমে
                বদলে দেয়া যেতে পারে।
              </li>

              <li>
                নির্দিষ্ট মডেলের প্রোডাক্ট মেরামত বা বদলে দেয়ার অযোগ্য Interwave Computers
                এর কাছে বর্তমান না থাকলে, বিক্রয় অবচয় সমন্বয় এর মাধ্যমে মূল্যের
                অর্থ ফেরত দেওয়া যেতে পারে।
              </li>

              <li>
                প্রোডাক্ট ব্যাবহারের সময় কিংবা Interwave Computers এর সার্ভিসের সময়{" "}
                <strong>
                  যদি কোন সফটওয়্যার বা ডাটা নষ্ট কিংবা হারিয়ে যায় এর দায়ভার
                  Interwave Computers &amp; Engineering Ltd. বহন করবে না
                </strong>
                । উল্লেখ্য যে, এক্ষেত্রে ডাটা পুনরুদ্ধার বা সফটওয়্যার
                পুনস্থাপনের কাজের দায়িত্ত্বও Interwave Computers. এর উপর বর্তাবে না।
              </li>

              <li>
                নির্দিষ্ট মডেলের প্রোডাক্ট ওয়ারেন্টির আওতায় নেয়ার পর সার্ভিসের
                কাজ শেষ করে প্রোডাক্টটি ফেরত দেয়ার সময় নির্দিষ্ট নয়,{" "}
                <strong>
                  এই সময় ৫-৭ দিন থেকে সর্বোচ্চ ৩৫-৪০ দিন কিংবা আরো বেশী হতে পারে
                </strong>
                ; কারণ অধিকাংশ ক্ষেত্রে মেরামতের জন্য প্রয়োজনীয় যন্ত্রাংশ দেশে
                পর্যাপ্ত বাফার স্টক না থাকায় তা বিশেষভাবে আমদানী করে আনতে হয় যা
                অনেক সময় সাপেক্ষ।
              </li>

              <li>
                ক্রেতাসাধারনের অবগতির জানানো যাচ্ছে যে বেশীরভাগ ওয়ারেন্টি
                প্রোডাক্ট রিপেয়ার হয় না, যে পার্টস টি নস্ট হয় সেটা পরিবর্তন করা
                হয় বরং অধিকাংশ ক্ষেত্রে বিদেশ থেকে আমদানি করা হয়।
              </li>

              <li>
                বিক্রয়ের সময় যে কম্পিউটার সেটআপ ও অপারেটিং সিস্টেম কাস্টমাইজ করে
                দেয়া হয় তা ওয়ারেন্টির আওতায় থাকে না।
              </li>

              <li>
                লাইফ টাইম ওয়ারেন্টি মূলত পন্যটি বাজারে যতদিন বর্তমান থাকবে ততদিন
                আপনি ওয়ারেন্টি সুবিধা পাবেন।
              </li>

              <li>
                ওয়ারেন্টির আওতা বহির্ভূত যেকোন সার্ভিসের জন্য Interwave Computers মূল্য
                ধার্য করতে পারবে যা ক্রেতার সম্মতি সাপেক্ষে কার্যকর হবে।
              </li>

              <li>
                সার্ভিস ওয়ারেন্টির ক্ষেত্রে যদি কোন যন্ত্রাংশ পরিবর্তন বা
                সংযোজনের প্রয়োজন হয় তাহলে ক্রেতা সাধারন তা নিজ দায়িত্বে সংগ্রহ
                করবেন অথবা ক্রেতাগনের সম্মতিতে অগ্রিম মূল্য পরিশোধ সাপেক্ষে
                Interwave Computers এর মাধ্যমে সংগ্রহ করতে পারবেন।
              </li>

              <li>
                ওয়ারেন্টির নির্ধারিত মেয়াদ থাকাকালীন বা উত্তীর্ণ হওয়ার পরে
                Interwave Computers কর্তৃক প্রদত্ত ফ্রি সফটওয়্যার বা হার্ডওয়্যার টিউনিংএ যদি
                প্রোডাক্ট এ কোন সমস্যা ধরা পড়ে বা নতুন কোন সমস্যার সৃষ্টি হয় তার
                দায়ভার Interwave Computers এর উপর বর্তাবে না।
              </li>

              <li>
                মনিটরের <strong>ডেড পিক্সেল (Dead Pixel)</strong> জনিত ওয়ারেন্টি
                ক্লেইমের জন্য তাতে ন্যূনতম ৩ বা তার বেশি ডেড পিক্সেল দৃশ্যমান
                হতে হবে।
              </li>

              <li>
                মনিটর ওয়ারেন্টি ক্লেইমের সময় ক্রেতাকে মনিটর বক্স সাথে নিয়ে আসতে
                অনুরোধ করা হচ্ছে।
              </li>
            </ul>

            <h2>
              যেসকল ক্ষেত্রে ওয়ারেন্টি কার্যকর হবে না বা শর্তসাপেক্ষে পেতে পারেন
            </h2>

            <ul>
              <li>
                অসতর্ক ভাবে ব্যবহারজনিত কারনে যেমন, পানিতে ভিজে যাওয়া, ভেঙে
                যাওয়া, পুড়ে যাওয়া, আঘাতপ্রাপ্ত হওয়া, গভীরভাবে আঁচড় এর দাগ
                প্রভৃতি কারণে কোন ত্রুটি দেখা দিলে তা ওয়ারেন্টির আওতায় থাকবে না।
              </li>

              <li>
                কোন পণ্যের <strong>সিরিয়াল বা সিরিয়াল স্টিকার</strong> আংশিক বা
                সম্পূর্ণরূপে মুছে গেলে, উঠে গেলে বা যেকোন কারণে ক্ষতিগ্রস্ত হলে
                তখন পণ্যটি আর ওয়ারেন্টির আওতায় পড়বে না।
              </li>

              <li>
                মাদারবোর্ড, গ্রাফিক্স কার্ড এবং র&zwnj;্যাম এর গায়ে{" "}
                <strong>ফাংগাস বা মরিচা ও গভীর কোন ক্ষত বা আচড়</strong> থাকলে
                উক্ত মাদারবোর্ড গ্রাফিক্স কার্ড বা র&zwnj;্যাম ওয়ারেন্টির আওতায়
                থাকবে না।
              </li>

              <li>
                মাদারবোর্ড এবং প্রসেসর এর ক্ষেত্রে{" "}
                <strong>
                  এক বা একাধিক পিন সম্পূর্ণ বা আংশিক ভাঙ্গা, বাঁকা বা বিকৃত
                </strong>{" "}
                অবস্থায় পাওয়া গেলে পণ্যটি ওয়ারেন্টির আওতায় থাকবে না।
              </li>

              <li>
                যেহেতু Interwave Computers. ল্যাপটপ, ডেস্কটপ কিংবা কোন পণ্য ডেলিভারির সময়
                কোন প্রকারের পাসওয়ার্ড কিংবা সিকিউরিটি কোড প্রয়োগ করে না সেহেতু
                ল্যাপটপ, ডেস্কটপ বা অন্য যেকোন ডিভাইসে BIOS পাসওয়ার্ড এর
                সম্পূর্ণ দায়িত্ব ক্রেতাকে বহন করতে হবে। এটা ওয়ারেন্টির আওতায়
                পড়বে না।
              </li>

              <li>
                Apple Mac Book এর নিজস্ব অপারেটিং সিস্টেম মুছে ফেললে পণ্যটি
                ওয়ারেন্টির আওতায় পড়বে না।
              </li>

              <li>
                প্রিন্টার কার্টিজ, টোনার, হেড, রোলার, ড্রাম, এলিমেন্ট কাভার
                ইত্যাদি যন্ত্রাংশ ওয়ারেন্টির আওতা বহির্ভূত।
              </li>

              <li>
                প্রিন্টারের ওয়ারেন্টির ক্ষেত্রে প্রস্তুতকারী প্রতিষ্ঠান কর্তৃক
                নির্ধারিত কালি, ইংক কার্টিজ বা টোনার কার্টিজ ব্যতীত অন্য কালি,
                ইংক কার্টিজ বা টোনার কার্টিজ ব্যবহার করলে ওয়ারেন্টির আওতায় পড়বে
                না।
              </li>

              <li>
                {" "}
                নির্দিষ্ট মডেলের কম্বো (যুগল) কিবোর্ড-মাউস (যে সব কিবোর্ড-মাউস
                একসাথে বান্ডেল হিসেবে বিক্রি হয়) এর ক্ষেত্রে কিবোর্ড বা মাউস
                যেকোনো একটি ক্ষতিগ্রস্থ হলে ওয়ারেন্টির জন্য{" "}
                <strong>
                  সম্পূর্ণ কম্বো (যুগল) সেটটি (আনুসাঙ্গিক এক্সেসরিজ)
                </strong>{" "}
                উপস্থাপন করতে হবে। শুধুমাত্র কিবোর্ড বা মাউস আলাদাভাবে
                ওয়ারেন্টির জন্য উপস্থাপন গ্রহণযোগ্য হবে না।
              </li>

              <li>
                প্রিন্টার, স্ক্যানার, রাউটার, সুইচ, এক্সেস পয়েন্ট, টিভি কার্ড
                ইত্যাদি এবং একই ধরনের প্রোডাক্ট এর পাওয়ার এডাপ্টার ওয়ারেন্টির
                আওতায় পড়বে না।
              </li>

              <li>
                কোন নির্দিষ্ট প্রোডাক্ট এর ওয়ারেন্টি প্রদানের পর যদি ওয়ারেন্টি
                বা সার্ভিস বিভাগে ২ মাসের অধিক সময় অতিবাহিত হয় তবে উক্ত
                প্রোডাক্ট এর দায়ভার কোম্পানী বহন করবে না।
              </li>

              <li>
                নির্দিষ্ট প্রোডাক্ট এর ওয়ারেন্টি রিসিভ পেপার হারিয়ে গেলে এর
                ক্রয়ের রশিদ ও যথাযোগ্য প্রমান প্রদান সাপেক্ষে প্রোডাক্ট টি গ্রহন
                করতে হবে।
              </li>
            </ul>

            <div class="nb">
              <h3>
                ওয়ারেন্টি সংক্রান্ত যেকোন পরামর্শ বা অভিযোগের জন্য ই-মেইল করার
                ঠিকানা নিম্নরূপ
              </h3>

              <a href="mailto:interwavecomp@gmail.com">interwavecomp@gmail.com</a>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TermsandCondScreen;
