import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col, Button, Card ,  Modal,Nav} from "react-bootstrap";
import { listProducts } from "../actions/productActions";
import Product from "../components/Product";
import Paginate1 from '../components/Paginate1';
import ProductFilter from "../components/ProductFilter";
const CategoryPages = ({ match }) => {
  let keyword = match.params.keyword || '';


  if (keyword === 'laptop') {
      keyword = '';
  } else if (keyword === 'ac') {
      keyword = 'gree';
  }
//const keyword = match.params.keyword || '';

    const pageNumber = match.params.pageNumber || '';

    const dispatch = useDispatch();

    const productList = useSelector(state => state.productList);
    const { loading, error, products, page, pages } = productList;

    useEffect(() => {
        dispatch(listProducts(keyword, pageNumber));
    }, [dispatch, keyword, pageNumber]);

    const [priceRange, setPriceRange] = useState([1000, 200000]);
    const [availability, setAvailability] = useState([]);
    const [brands, setBrands] = useState([]);
    const [processorTypes, setProcessorTypes] = useState([]);
    const handlePriceChange = (event) => {
      setPriceRange([parseInt(event.target.value), priceRange[1]]);
    };

    
    const handleAvailabilityChange = (event) => {
      const value = event.target.value;
      const updatedAvailability = availability.includes(value)
        ? availability.filter(item => item !== value)
        : [...availability, value];
      setAvailability(updatedAvailability);
      console.log("Selected Availability:", updatedAvailability); // Debugging: Print selected availability to console
    };

    const handleBrandChange = (event) => {
      const value = event.target.value;
      const updatedBrands = brands.includes(value)
        ? brands.filter(item => item !== value)
        : [...brands, value];
      setBrands(updatedBrands);
      console.log("Selected Brands:", updatedBrands); // Debugging: Print selected brands to console
    };

    const [ramSizes, setRamSizes] = useState([]);

const handleRamSizeChange = (event) => {
  const value = event.target.value;
  const updatedRamSizes = ramSizes.includes(value)
    ? ramSizes.filter(item => item !== value)
    : [...ramSizes, value];
  setRamSizes(updatedRamSizes);
  console.log("Selected RAM Sizes:", updatedRamSizes);
};
const handleProcessorTypeChange = (event) => {
  const value = event.target.value;
  const isChecked = event.target.checked;

  setProcessorTypes(prevProcessorTypes => {
    if (isChecked) {
      return [...prevProcessorTypes, value];
    } else {
      return prevProcessorTypes.filter(type => type !== value);
    }
  });
};

// Update the filtering logic
const filteredAndSortedProducts = products
  .filter(product => {
    const price = product.price;
    const brandCheck = brands.length === 0 || brands.includes(product.brand);
    const brandCheck1 = availability.length === 0 || product.specifications.some(spec => spec.name === "Processor" && spec.values.some(value => availability.includes(value.value)));
    const ramCheck = ramSizes.length === 0 || ramSizes.some(size => product.description.toLowerCase().includes(size.toLowerCase()));
    const processorTypeCheck = processorTypes.length === 0 || processorTypes.some(type => product.description.toLowerCase().includes(type.toLowerCase()));
    return price >= priceRange[0] && price <= priceRange[1] && brandCheck && brandCheck1 && ramCheck && processorTypeCheck;
  })
  .sort((a, b) => a.price - b.price);




  // Calculate min and max prices dynamically
  const minPrice = products.length > 0 ? Math.min(...products.map(product => product.price)) : 0;
  const maxPrice = products.length > 0 ? Math.max(...products.map(product => product.price)) : 0;
  
  // Set initial price range based on min and max prices
  useEffect(() => {
    setPriceRange([minPrice, maxPrice]);
  }, [minPrice, maxPrice]);
  
        const [show, setShow] = React.useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      


      const productsPerPage = 12; // Number of products per page

      const paginatedFilteredProducts = filteredAndSortedProducts.slice((page - 1) * productsPerPage, page * productsPerPage);
      console.log("paginatedFilteredProducts",paginatedFilteredProducts.length)
      console.log("filteredAndSortedProducts",filteredAndSortedProducts.length)
      console.log("products",products.length)
      return (
        <Container>
          <Row>
            {/* <Col>
              <div className=" d-flex flex-md-row flex-column justify-content-between align-items-center cat_bag">
                <div className="d-flex  justify-content-between ">
                  Home/Categories
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <span className=" mr-3">Showing 1–30 of 3744 results</span>
                  <div class="form-group">
                    <select
                      className="form-control mt-1"
                      id="exampleFormControlSelect1"
                      placeholder="Any Choose "
                    >
                      <option>Any Choose</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
          <div className=" filter_off border-0 bd-example">
          <>
      <LinkContainer to="/cart">
        <>
          <Nav.Link onClick={handleShow}>
            {/* <i className='fas fa-shopping-cart admin_ico'></i> Cart */}
            <div class="d-flex">
             <Button className="w-100">Filter</Button>
            </div>
          </Nav.Link>
        </>
      </LinkContainer>{" "}
      <Modal
        show={show}
        onHide={handleClose}
        scrollable
        className="custom_modal for_he"
      >
        <Modal.Header closeButton className="text-center">
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto" }}>
          <div
            
          >
                    <div>
          <div className="filter_off ">
          <div className="card p-3 cat_card mb-3 mt-3">
          <h3 className="cate_title mb-0">Filter</h3>
            </div>
  <div className="card p-3 cat_card mb-3 mt-3">
    <h3 className="cate_title">PRICE</h3>
    <div>
      <div className="form-group">
        <input
          type="range"
          className="form-control-range colr color_dark"
          id="formControlRange"
          min={minPrice}
          max={maxPrice}
          value={priceRange[0]}
          onChange={handlePriceChange}
        />
        <div className="d-flex justify-content-between pt-3">
          <span style={{fontSize:"14px"}}>
            Price:{" "}
            <img
              width={10}
              className="img-fluid mr-1 mb-1"
              src="../images/taka_black.svg"
            />
            {priceRange[0].toFixed(2)} -{" "}
            <img
              width={10}
              className="img-fluid mr-1 mb-1"
              src="../images/taka_black.svg"
            />
            {priceRange[1].toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>




{products.some(product => product.category.trim().toLowerCase() === 'laptop') && (
  <div className="filter_off ">
    <div className="card p-3 cat_card mb-3 mt-3">
      <h3 className="cate_title">Laptop BRAND</h3>
      <div >
        <div className="form-group">
          {Array.from(new Set(products
            .filter(product => product.category.trim().toLowerCase() === 'laptop')
            .map(product => product.brand)
          )).map(brand => (
            <div className="form-check" key={brand}>
              <input
                type="checkbox"
                className="form-check-input"
                id={`${brand.toLowerCase()}Checkbox`}
                value={brand}
                onChange={handleBrandChange}
              />
              <label className="form-check-label" htmlFor={`${brand.toLowerCase()}Checkbox`}>
                {brand}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)}
  {products.some(product => product.category.trim().toLowerCase() === 'laptop') && (
  <div className="filter_off ">
    <div className="card p-3 cat_card mb-3 mt-3">
      <h3 className="cate_title">PROCESSOR BRAND</h3>
      <div >
        <div className="form-group">
          {Array.from(new Set(products
            .filter(product => product.category.trim().toLowerCase() === 'laptop')
            .flatMap(product => product.specifications
              .flatMap(spec => spec.values)
              .filter(val => val.name === 'Processor Brand')
              .map(val => val.value))
            )).map(uniqueBrand => (
              <div className="form-check" key={uniqueBrand}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`${uniqueBrand.toLowerCase()}Checkbox`}
                  value={uniqueBrand}
                  onChange={handleAvailabilityChange}
                />
                <label className="form-check-label" htmlFor={`${uniqueBrand.toLowerCase()}Checkbox`}>
                  {uniqueBrand}
                </label>
              </div>
            ))}
        </div>
      </div>
    </div>
  </div>
)}


{products.some(product => product.category.trim().toLowerCase() === 'laptop') && (
  <div className="filter_on">
    <div className="card p-3 cat_card mb-3 mt-3">
      <h3 className="cate_title mb-0">Processor Type</h3>
      <div>
        <div className="form-group">
        {
          Array.from(new Set(products
            .filter(product => product.category.trim().toLowerCase() === 'laptop')
            .flatMap(product => product.specifications
              .flatMap(spec => spec.values)
              .filter(spec => spec.name === 'Processor Model')
              .map(val => val.value.substring(0, 7)) // Trim down to the first 7 characters here
            )))
            .sort() // Sort processor model values in ascending order
            .map(processorModel => (
              <div className="form-check" key={processorModel}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`${processorModel.toLowerCase()}Checkbox`}
                  value={processorModel}
                  onChange={handleProcessorTypeChange}
                />
                <label className="form-check-label" htmlFor={`${processorModel.toLowerCase()}Checkbox`}>
                  {processorModel}
                </label>
              </div>
            ))
        }
        </div>
      </div>
    </div>
  </div>
)}

{products.some(product => product.category.trim().toLowerCase() === 'laptop') && (
  <div className="filter_on">
    <div className="card p-3 cat_card mb-3 mt-3">
      <h3 className="cate_title mb-0">RAM SIZE</h3>
      <div >
        <div className="form-group">
        {
          Array.from(new Set(products
            .filter(product => product.category.trim().toLowerCase() === 'laptop')
            .flatMap(product => product.specifications
              .flatMap(spec => spec.values)
              .filter(val => val.name === 'RAM')
              .map(val => val.value.trim()) // Trim the RAM size values
            )))
            .sort((a, b) => parseFloat(a) - parseFloat(b)) // Sort RAM sizes in ascending order
            .map(ramSize => (
              <div className="form-check" key={ramSize}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`ram${ramSize.toLowerCase()}Checkbox`}
                  value={ramSize}
                  onChange={handleRamSizeChange}
                />
                <label className="form-check-label" htmlFor={`ram${ramSize.toLowerCase()}Checkbox`}>
                  {ramSize}
                </label>
              </div>
            ))
        }
        </div>
      </div>
    </div>
  </div>
)}




</div>

          </div>



        </Modal.Body>
      </Modal>
    </>
              </div>
          <Row>


          <div>
          <div className="filter_on">
          <div className="card p-3 cat_card mb-3 mt-3">
          <h3 className="cate_title mb-0">Filter</h3>
            </div>
  <div className="card p-3 cat_card mb-3 mt-3">
    <h3 className="cate_title mb-0">PRICE</h3>
    <div>
      <div className="form-group">
        <input
          type="range"
          className="form-control-range colr color_dark"
          id="formControlRange"
          min={minPrice}
          max={maxPrice}
          value={priceRange[0]}
          onChange={handlePriceChange}
        />
        <div className="d-flex justify-content-between pt-3">
          <span>
            Price:{" "}
            <img
              width={10}
              className="img-fluid mr-1 mb-1"
              src="../images/taka_black.svg"
            />
            {priceRange[0].toFixed(2)} -{" "}
            <img
              width={10}
              className="img-fluid mr-1 mb-1"
              src="../images/taka_black.svg"
            />
            {priceRange[1].toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>




{products.some(product => product.category.trim().toLowerCase() === 'laptop') && (
  <div className="filter_on">
    <div className="card p-3 cat_card mb-3 mt-3">
      <h3 className="cate_title mb-0">Laptop BRAND</h3>
      <div >
        <div className="form-group">
          {Array.from(new Set(products
            .filter(product => product.category.trim().toLowerCase() === 'laptop')
            .map(product => product.brand)
          )).map(brand => (
            <div className="form-check" key={brand}>
              <input
                type="checkbox"
                className="form-check-input"
                id={`${brand.toLowerCase()}Checkbox`}
                value={brand}
                onChange={handleBrandChange}
              />
              <label className="form-check-label" htmlFor={`${brand.toLowerCase()}Checkbox`}>
                {brand}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)}
  {products.some(product => product.category.trim().toLowerCase() === 'laptop') && (
  <div className="filter_on">
    <div className="card p-3 cat_card mb-3 mt-3">
      <h3 className="cate_title mb-0">PROCESSOR BRAND</h3>
      <div >
        <div className="form-group">
          {Array.from(new Set(products
            .filter(product => product.category.trim().toLowerCase() === 'laptop')
            .flatMap(product => product.specifications
              .flatMap(spec => spec.values)
              .filter(val => val.name === 'Processor Brand')
              .map(val => val.value))
            )).map(uniqueBrand => (
              <div className="form-check" key={uniqueBrand}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`${uniqueBrand.toLowerCase()}Checkbox`}
                  value={uniqueBrand}
                  onChange={handleAvailabilityChange}
                />
                <label className="form-check-label" htmlFor={`${uniqueBrand.toLowerCase()}Checkbox`}>
                  {uniqueBrand}
                </label>
              </div>
            ))}
        </div>
      </div>
    </div>
  </div>
)}


{products.some(product => product.category.trim().toLowerCase() === 'laptop') && (
  <div className="filter_on">
    <div className="card p-3 cat_card mb-3 mt-3">
      <h3 className="cate_title mb-0">Processor Type</h3>
      <div>
        <div className="form-group">
        {
          Array.from(new Set(products
            .filter(product => product.category.trim().toLowerCase() === 'laptop')
            .flatMap(product => product.specifications
              .flatMap(spec => spec.values)
              .filter(spec => spec.name === 'Processor Model')
              .map(val => val.value.substring(0, 7)) // Trim down to the first 7 characters here
            )))
            .sort() // Sort processor model values in ascending order
            .map(processorModel => (
              <div className="form-check" key={processorModel}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`${processorModel.toLowerCase()}Checkbox`}
                  value={processorModel}
                  onChange={handleProcessorTypeChange}
                />
                <label className="form-check-label" htmlFor={`${processorModel.toLowerCase()}Checkbox`}>
                  {processorModel}
                </label>
              </div>
            ))
        }
        </div>
      </div>
    </div>
  </div>
)}

{products.some(product => product.category.trim().toLowerCase() === 'laptop') && (
  <div className="filter_on">
    <div className="card p-3 cat_card mb-3 mt-3">
      <h3 className="cate_title mb-0">RAM SIZE</h3>
      <div >
        <div className="form-group">
        {
          Array.from(new Set(products
            .filter(product => product.category.trim().toLowerCase() === 'laptop')
            .flatMap(product => product.specifications
              .flatMap(spec => spec.values)
              .filter(val => val.name === 'RAM')
              .map(val => val.value.trim()) // Trim the RAM size values
            )))
            .sort((a, b) => parseFloat(a) - parseFloat(b)) // Sort RAM sizes in ascending order
            .map(ramSize => (
              <div className="form-check" key={ramSize}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`ram${ramSize.toLowerCase()}Checkbox`}
                  value={ramSize}
                  onChange={handleRamSizeChange}
                />
                <label className="form-check-label" htmlFor={`ram${ramSize.toLowerCase()}Checkbox`}>
                  {ramSize}
                </label>
              </div>
            ))
        }
        </div>
      </div>
    </div>
  </div>
)}





</div>


            <Col lg={9} sm={12}>
              <Row>
              {filteredAndSortedProducts.length > 0 ? (
              filteredAndSortedProducts.map((product) => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={4}>
                  <Product product={product} />
                </Col>
              ))
            ) : (
              products.map((product) => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={4}>
                  <Product product={product} />
                </Col>
              ))
            )}
               
              </Row>

              {filteredAndSortedProducts.length < 12 ? (
  <Paginate1 pages={Math.ceil(filteredAndSortedProducts.length / productsPerPage)} page={page} isCat={true} keyword={keyword} />
) : (
  <Paginate1 pages={pages} page={page} isCat={true} keyword={keyword} />
)}

             
             
            </Col>
          </Row>
        </Container>
      );
    };
    export default CategoryPages;
