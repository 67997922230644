import React from "react";
import { Nav, ProgressBar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  const calculateProgress = () => {
    let activeStep = 1;
    if (step4) {
      activeStep = 4;
    } else if (step3) {
      activeStep = 3;
    } else if (step2) {
      activeStep = 2;
    }
    return ((activeStep - 1) / 3) * 100; // Adjust the denominator based on the number of steps
  };

  const getStepName = (step) => {
    switch (step) {
      case 1:
        return "Sign In";
      case 2:
        return "Shipping";
      case 3:
        return "Payment";
      case 4:
        return "Place Order";
      default:
        return "";
    }
  };

  return (
    <div className="manual_height">
      {/* <Nav className='justify-content-center mb-4 mt-5'>
        <Nav.Item>
          {step1 ? (
            <LinkContainer to='/login'>
              <Nav.Link>Sign In</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Sign In</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step2 ? (
            <LinkContainer to='/shipping'>
              <Nav.Link className='colr-red'>Shipping</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Shipping</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step3 ? (
            <LinkContainer to='/payment'>
              <Nav.Link>Payment</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Payment</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step4 ? (
            <LinkContainer to='/placeorder'>
              <Nav.Link>Place Order</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Place Order</Nav.Link>
          )}
        </Nav.Item>
      </Nav> */}
      <ProgressBar className="progress_bar mb-5 mt-5">
        <ProgressBar
          className="width_change"
          variant={step1 ? "success" : "dark"}
          now={step1 ? calculateProgress() : 0}
          label={getStepName(1)}
          key={1}
        />
        <ProgressBar
          className="width_change"
          variant={step2 ? "success" : "dark"}
          now={step2 ? calculateProgress() : 0}
          label={getStepName(2)}
          key={2}
        />
        <ProgressBar
          className="width_change"
          variant={step3 ? "success" : "dark"}
          now={step3 ? calculateProgress() : 0}
          label={getStepName(3)}
          key={3}
        />
        <ProgressBar
          className="width_change"
          variant={step4 ? "success" : "dark"}
          now={step4 ? calculateProgress() : 0}
          label={getStepName(4)}
          key={4}
        />
      </ProgressBar>
    </div>
  );
};

export default CheckoutSteps;
