import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Rating from "./Rating";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Carousel } from "react-responsive-carousel";
import { listProducts } from "../actions/productActions";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import { render } from "react-dom";
// import Carousel from "../components/Slide";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Product = ({ product, match }) => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };

  const [toggler, setToggler] = useState(false);

  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const checkoutHandler = () => {
    history.push("/login?redirect=shipping");
  };
  const handleNavigate = (productId) => {
    history.push(`/product/${productId}`);
    window.scrollTo(0, 0);
  };
  return (
    <Card className="my-3  rounded product_card ">
      <div className="card-head card_head_custom">
        {/* <span className="offer"> Save: 5%</span> */}
        <span className="quick-view-button-heart">
          <i class="fas fa-heart"></i>
        </span>
        {product.countInStock === 0 ? (
        <Link
        // to={`/product/${product._id}`}
        >
          <Card.Img src={product.image} variant="top" />
        </Link>
        ) : (
          <Link to={`/product/${product._id}`}>
          <Card.Img src={product.image} variant="top" />
        </Link>
        )}
        <span class="quick-view-button w-100" onClick={handleShow}>
          Quick View
        </span>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                {/* <Carousel onClick={() => setToggler(!toggler)} /> */}
                <Carousel width autoPlay>
                  {[
                    product.image,
                    ...(product.additionalImages ? product.additionalImages : [])
                  ].map((image, index) => (
                    <div key={index}>
                      <img alt="" className="img-fluid" src={image} />
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col md={6}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2 className="product_name">{product.name}</h2>
                  </ListGroup.Item>
                  <ListGroup.Item className="product_code">
                  Product Code: 87878
                </ListGroup.Item>
                  <ListGroup.Item className="product_price">
                    Price:{" "}
                    <img
                      width={12}
                      className="img-fluid mr-1 mb-1 "
                      src="../images/taka_green.svg"
                    />
                    {product.price}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Rating
                      value={product.rating}
                      text={`${product.numReviews} reviews`}
                    />
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <h3 className="product_name">Key Features</h3>
                    {product.description}
                  </ListGroup.Item>
                  <ListGroup.Item className="product_price mb-3 ">
                    {product.countInStock > 0 ? "In Stock" : "Out Of Stock"}
                  </ListGroup.Item>
                </ListGroup>
                <div className="d-flex">
                  {product.countInStock > 0 && (
                       <ListGroup.Item className="mr-3 ml-3 w-50 customWidth">
                       <Form.Control
                       className="form_c"
                         as="select"
                         value={qty}
                         onChange={(e) => setQty(e.target.value)}
                       >
                         {[...Array(product.countInStock).keys()].map((x) => (
                           <option key={x + 1} value={x + 1}>
                             {x + 1}
                           </option>
                         ))}
                       </Form.Control>
                     </ListGroup.Item>
                  )}
                  <Link to={`/product/${product._id}`}>
                    <Button className="btn-block color_dark " type="button">
                      {" "}
                      Add To Cart
                    </Button>
                  </Link>
                  {/* <Button
                                            onClick={addToCartHandler}
                                            className='btn-block color_dark w-50'
                                            type='button'
                                            disabled={product.countInStock === 0}
                                        >
                                            Add To Cart
                                    </Button> */}
                </div>
                <div className="mt-4"></div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
      <Card.Body className="pt-0">
        {/* <Link to={`/product/${product._id}`}>
          <Card.Title as="div">
            <strong className="product_name ">{product.name}</strong>
          </Card.Title>
        </Link> */}
        {product.countInStock === 0 ? (
        <div
        // onClick={() => handleNavigate(product._id)}
        >
          <Card.Title as="div" className="mb-0">
            <strong className="product_name mb-0">{product.name}</strong>
          </Card.Title>
        </div>
        ) : (
          <div onClick={() => handleNavigate(product._id)}>
          <Card.Title as="div" className="mb-0">
            <strong className="product_name mb-0">{product.name}</strong>
          </Card.Title>
        </div>
        )}
        <Card.Text as="div">
          <Rating
            value={product.rating}
            text={`${product.numReviews} reviews`}
          />
        </Card.Text>
        {product.countInStock === 0 ? (
        <Card.Text as="h3" className="product_price">
          <img
            width={12}
            className="img-fluid mr-1 mb-1 "
            src="../images/taka_green.svg"
          />
          {/* {product.price.toLocaleString()} */}
          {' OUT OF STOCK '}
        </Card.Text>
        ) : (
        <Card.Text as="h3" className="product_price p-0 pt-1">
          <img
            width={12}
            className="img-fluid mr-1 mb-1 "
            src="../images/taka_green.svg"
          />
          {product.price.toLocaleString()}
        </Card.Text>
        )}
            { product.countInStock === 0 ? (
        <div
        // onClick={() => handleNavigate(product._id)}
        >
          <Button className="btn product_btn w-100">
            <i class="fas fa-shopping-cart mr-2"></i> Up Coming
          </Button>{" "}
        </div>
          ) : (
            <div onClick={() => handleNavigate(product._id)}>
            <Button className="btn product_btn w-100">
              <i class="fas fa-shopping-cart mr-2"></i> Buy Now
            </Button>{" "}
          </div>
          )}
      </Card.Body>
    </Card>
  );
};

export default Product;
