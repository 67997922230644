import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const RefundReturnPolicyScreen = ({ match, location, history }) => {
  return (
    <>
      <Container>
        <Row>
          <div class="rr-policy abt topper">
            {" "}
            <h1>প্রশপ পণ্য রিটার্ন ও রিফান্ড পলিসি</h1>
            <ul>
              <li>
                শপ থেকে পণ্য ক্রয়ের ক্ষেত্রে{" "}
                <strong>অবশ্যই শপে বিক্রয়কর্মীর সামনে চেক করে কিনবেন</strong>।
                পরবর্তীতে সমস্যা হলে যদি পণ্যে ওয়ারেন্টি থাকে তবে তা ওয়ারেন্টির
                আওতাভুক্ত হবে।
              </li>
              <li>
                অনলাইন অর্ডারের ক্ষেত্রে পণ্য ডেলিভারি পাবার পর পণ্যে
                মেনুফেকচারিং ত্রুটি থাকলে আমাদের{" "}
                <strong>হটলাইনে ২৪ ঘন্টার মধ্যে জানাতে হবে</strong>। তবে অবশ্যই
                সে পণ্যের গায়ে কোন স্ক্র্যাচ ফেলা যাবে না এবং পণ্যের বক্স অক্ষত
                রাখতে হবে অন্যথায় তা পরিবর্তনযোগ্য নয়।&nbsp;
              </li>
              <li>
                অনলাইন অর্ডার এর পণ্য ডেলিভারি ম্যান থেকে রিসিভ করার পর যদি বক্স
                দেখে মনে হয় তা আপনার অর্ডারক্রীত পণ্য না তাহলে বক্স খুলে পণ্য
                ব্যবহার করলে এবং বক্স নষ্ট করলে সেই পণ্য পরবর্তীতে পরিবর্তনযোগ্য
                হবে না।&nbsp;
              </li>
              <li>
                ত্রুটিযুক্ত পণ্য আমাদের শপ থেকে পরিবর্তনযোগ্য। এক্ষেত্রে আমাদের
                এক্সপার্টগন পণ্যে ত্রুটি পর্যবেক্ষণ করে তা পরিবর্তন করার পদক্ষেপ
                গ্রহন করবেন।
              </li>
              <li>
                ক্রেতা যদি ডেলিভারি ম্যান এর মাধ্যমে ত্রুটিযুক্ত পণ্য পরিবর্তন
                করতে ইচ্ছুক অথবা পরিবর্তন করতে চান তবে{" "}
                <strong>২০০/- টাকা পরিবর্তন চার্জ</strong> প্রযোজ্য হবে। ঢাকার
                বাইরের ক্ষেত্রে শুধুমাত্র কুরিয়ার চার্জ প্রযোজ্য হবে। পণ্য আনার
                পর যদি পণ্য <strong>ভাঙ্গা অথবা পোড়া/ জ্বলা</strong> অবস্থায়
                পাওয়া যায় তবে সেই পণ্যের সম্পূর্ণ দায়িত্ব ক্রেতাকে বহন করতে হবে।
              </li>
              <li>
                প্রশপ এর ওয়েবসাইটে থাকা বিবরণী দেখে ক্রয়কৃত পণ্য ডেলিভারি কর্মী
                থেকে রিসিভ করার পর তা আপনার নির্দিষ্ট ডিভাইসে সাপোর্ট করছে না
                অথবা তা এখন আর কিনতে ইচ্ছুক নন, এসকল কারনে পণ্য ফেরত অথবা
                পরিবর্তনযোগ্য নয়।
              </li>
              <li>
                কোন ধরণের <strong>সফটওয়্যার/সফটওয়্যার লাইসেন্স</strong> ক্রয়ের
                পর তা রিটার্ন অথবা রিফান্ডযোগ্য নয়।
              </li>
              <li>
                নির্দিষ্ট কারনে পণ্য রিটার্ন দেয়ার পর অথবা গ্রহণযোগ্য কারনে
                মূল্য রিফান্ড করতে <strong>৩ থেকে ১০ কার্যদিবস</strong> এবং
                অনলাইন পেমেন্টের ক্ষেত্রে আরও বেশি সময় লাগতে পারে।
              </li>
              <li>
                সকল প্রকার মোবাইল ফিন্যান্সিয়াল সার্ভিস/ অনলাইন গেটওয়ে / POS
                পেমেন্ট রিফান্ডের ক্ষেত্রে <strong>রিফান্ড চার্জ</strong>{" "}
                প্রযোজ্য ।{" "}
              </li>
              <li>
                কুরিয়ার এর ক্ষেত্রে ক্রেতা অবশ্যই{" "}
                <strong>
                  পণ্য ভাঙ্গা থাকলে অথবা প্যাকেট ছেঁড়া থাকলে কুরিয়ার থেকে পণ্য
                  গ্রহণ করবে না
                </strong>
                । কুরিয়ারে ক্ষতিগ্রস্ত পণ্য ক্রেতা গ্রহণ করলে তা নিজ দায়িত্বে
                করতে হবে এবং এই ব্যাপারে পরে কোন অভিযোগ গ্রহণযোগ্য হবে না।
              </li>
              <li>
                সম্মানিত ক্রেতাগন যদি পেমেন্ট করার সময় কোন প্রকার ক্যাশব্যাক
                পেয়ে থাকেন তাহলে রিফান্ড করার সময় ক্যাশব্যাকের সমপরিমান টাকা
                কেটে রাখা হবে।
              </li>
            </ul>
            <h2>Return &amp; Refund Policy of Interwave Computers</h2>
            <ul>
              <li>
                If a customer is buying the products from our shops, then please
                make sure to{" "}
                <strong>check the products in front of our sellers</strong>.
                Later, if any problems occur then the customer will not be
                entitled to any changes but will be given services based on the
                product provided a description.(Warranty)
              </li>
              <li>
                In the case of ordering online, after receiving the product, if
                any manufacturing issues or problems are noticed or discovered
                than the customer has to{" "}
                <strong>
                  inform us within 24 hours via our hotline service.
                </strong>
                &nbsp;However, the product must not be scratched and the product
                box must be intact otherwise it won't be returnable.
              </li>
              <li></li>
              <li>
                After receiving the online order&nbsp;product from the delivery
                man, if the box looks like it is not the product you ordered,
                then please do not open or damage the box if you open the box
                and use the product and destroy the box, the product will not be
                returnable.
              </li>
              <li>
                If a customer received a manufacturing defective product then
                the customer has to visit any of our shops where our specialists
                will review the product first and then take the necessary steps
                to change the product if it is in need of replacement.
              </li>
              <li>
                If a customer wants to change the defective product through our
                delivery service, then{" "}
                <strong>
                  a charge Of TK. 200/- has to be paid as a replacement charge
                </strong>{" "}
                inside Dhaka and if out of Dhaka, only the Courier charge is
                applicable.{" "}
              </li>
              <li>
                If a Customer is buying any product from our Website after
                reading the description and provided information about the
                particular product and is received successfully without any
                faults and later if the product is not compatible with your
                setup or if you do not want it anymore, then you will not be
                allowed to change or return the product.{" "}
              </li>
              <li>
                After purchasing a software/software license, a return or refund
                won't be applicable
              </li>
              <li>
                With specific reasoning, product(s) will be allowed to be
                returned and refunded back to you within{" "}
                <strong>3 to 10 working days</strong> and in the case of online
                purchase, the procedure may take longer to execute.
              </li>
              <li>
                Refund charges are applicable for All kinds of Mobile Financial
                Services/ Online Gateway / POS payment refunds.
              </li>
              <li>
                If customers found broken or packet-damaged products then we are
                requesting not to receive the product from the courier service.
                If the customer receives courier damaged product then he/she has
                to take his/her own liability and any kind of complaint won't be
                acceptable.
              </li>
              <li>
                If the honorable customer gets any cashback at the time of
                payment, the cashback amount will be deducted while making the
                refund.
              </li>
            </ul>
            <div class="nb">
              {" "}
              <h3>বিস্তারিত জানতে কল করুন নিচের নম্বরে</h3>
            
              <a href="tel:(880) 1777-171413">(880) 1777-171413</a>{" "}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default RefundReturnPolicyScreen;
