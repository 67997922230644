import React, { useState, useEffect  } from 'react'
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  Table,
  Container,
} from "react-bootstrap";

const BrandScreen = ({ match, location, history }) => {
  const [activeTab, setActiveTab] = useState(null);
  const tabData = {
    A: `
      <div>
        <h2 id="A">A</h2>
        <div class="row">
          <div class="col-sm-3">
            <a href="#/a4-tech">A4Tech</a>
          </div>
          <div class="col-sm-3">
            <a href="#/acefast">ACEFAST</a>
          </div>
          <div class="col-sm-3">
            <a href="#/acer">Acer</a>
          </div>
          <div class="col-sm-3">
            <a href="#/adata">ADATA</a>
          </div>
        </div>
      </div>
    `,
    // Add more tabs and corresponding data as needed
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <Container>
        {/* <div className="mt-5">
          <div>
           
            <p class="brand-index">
              <strong>Brand Index:</strong>
              {Object.keys(tabData).map((tab) => (
                <a
                  key={tab}
                  href={`product/manufacturer#${tab}`}
                  onClick={() => handleTabClick(tab)}
                  className={activeTab === tab ? "active" : ""}
                >
                  {tab}
                </a>
              ))}
            </p>
          </div>
          <div>
        
            {activeTab ? (
              <div dangerouslySetInnerHTML={{ __html: tabData[activeTab] }} />
            ) : (
              <div>

                {Object.keys(tabData).map((tab) => (
                  <div
                    key={tab}
                    dangerouslySetInnerHTML={{ __html: tabData[tab] }}
                  />
                ))}
              </div>
            )}
          </div>
        </div> */}

        <div class="container">
          <div class="row brand-row ">
            {" "}
            <div id="content" class="col-sm-12">
              {" "}
              <h1>Find Your Favorite Brand</h1>
              <p class="brand-index">
                <strong>Brand Index:</strong>
                <a href="product/manufacturer#0 - 9">0 - 9</a>
                <a href="product/manufacturer#A">A</a>
                <a href="product/manufacturer#B">B</a>
                <a href="product/manufacturer#C">C</a>
                <a href="product/manufacturer#D">D</a>
                <a href="product/manufacturer#E">E</a>
                <a href="product/manufacturer#F">F</a>
                <a href="product/manufacturer#G">G</a>
                <a href="product/manufacturer#H">H</a>
                <a href="product/manufacturer#I">I</a>
                <a href="product/manufacturer#J">J</a>
                <a href="product/manufacturer#K">K</a>
                <a href="product/manufacturer#L">L</a>
                <a href="product/manufacturer#M">M</a>
                <a href="product/manufacturer#N">N</a>
                <a href="product/manufacturer#O">O</a>
                <a href="product/manufacturer#P">P</a>
                <a href="product/manufacturer#Q">Q</a>
                <a href="product/manufacturer#R">R</a>
                <a href="product/manufacturer#S">S</a>
                <a href="product/manufacturer#T">T</a>
                <a href="product/manufacturer#U">U</a>
                <a href="product/manufacturer#V">V</a>
                <a href="product/manufacturer#W">W</a>
                <a href="product/manufacturer#X">X</a>
                <a href="product/manufacturer#Y">Y</a>
                <a href="product/manufacturer#Z">Z</a>
              </p>
              <h2 id="0 - 9">0 - 9</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/1more">1MORE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/1stplayer">1STPLAYER</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/70mai">70mai</a>
                </div>
              </div>
              <h2 id="A">A</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/a4-tech">A4Tech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/acefast">ACEFAST</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/acer">Acer</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/adata">ADATA</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/addlink">Addlink</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/brand-adobe">Adobe</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/afox">AFOX</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/aigo">Aigo</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/aitc">AITC</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/akash">AKASH</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/akaso">AKASO</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/akg">AKG</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/alesis">Alesis</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/altec-lansing">Altec Lansing</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/amazon">Amazon</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/amd">AMD</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/amp">AMP</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/anacomda">ANACOMDA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/anker">Anker</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/antec">Antec</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/anycast ">AnyCast</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/aoc">AOC</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/aoson">AOSON</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/apacer">Apacer</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/aplus">Aplus</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/apogee">Apogee</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/apollo">APOLLO</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/apple">Apple</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/apposter">Apposter</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/aptech">Aptech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/arktek">ARKTEK</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/armor">ARMOR</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/asrock">ASRock</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/astro">ASTRO</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/astrum">Astrum</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/asus">Asus</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/asustor">Asustor</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/audio-technica">Audio Technica</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/aula">AULA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/aun">AUN</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/aurora">Aurora</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/autodesk-software">Autodesk</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/avaya">Avaya</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/aver">AVer</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/avermedia">AVerMedia</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/avision">Avision</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/avita">AVITA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/awei">Awei</a>
                </div>
              </div>
              <h2 id="B">B</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/bajeal">BAJEAL</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/baseus">Baseus</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/bdcom">BDCOM</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/beats">Beats</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/belkin">Belkin</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/benq">BenQ</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/beyerdynamic ">Beyerdynamic</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/binge">Binge</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/biostar">BIOSTAR</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/bitfenix">Bitfenix</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/biwintech">Biwintech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/bixolon">Bixolon</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/black-cat">Black Cat</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/blackmagic-design">Blackmagic Design</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/blueparrot ">BlueParrot</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/boat">boAt</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/bose">Bose</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/boston">Boston</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/boxlight ">BoxLight </a>
                </div>
                <div class="col-sm-3">
                  <a href="#/boya">Boya</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/brother">Brother</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/bxb">BXB</a>
                </div>
              </div>
              <h2 id="C">C</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/c-data">C-Data</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/cambium">Cambium</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/campro">Campro</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/canon">Canon</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/capturer">Capturer</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/carrier">Carrier</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/case-logic">Case Logic</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/casio">CASIO</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/champion">Champion</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/cheerlux">Cheerlux</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/chuwi">Chuwi</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/cisco">Cisco</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/cmx">CMX</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/colmi">COLMI</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/colorful">Colorful</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/cooler-master">Cooler Master</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/corsair">Corsair</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/cougar">Cougar</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/creative">Creative</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/crelander">Crelander</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/crucial">Crucial</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/cryorig">CRYORIG</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/cudy">Cudy</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/czur">CZUR</a>
                </div>
              </div>
              <h2 id="D">D</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/d-link">D-Link</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/dahua">Dahua</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/dareu">Dareu</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/deepcool">Deepcool</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/defender">Defender</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/deli">Deli</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/dell">Dell</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/delux">Delux</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/digipod">Digipod</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/digital-view">Digital View</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/digital-x">Digital X</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/dizo">DIZO</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/dji">Dji</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/doel">DOEL</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/dtech">DTECH</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/durgod">Durgod</a>
                </div>
              </div>
              <h2 id="E">E</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/ecoflow">EcoFlow</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/edifier">Edifier</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/edimax">Edimax</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/eken">EKEN</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/eksa">EKSA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/ekwb">EKWB</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/elite">Elite</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/elite-power">Elite Power</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/enchen">ENCHEN</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/energizer">Energizer</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/ensmart">EnSmart</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/enter">Enter</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/epson">EPSON</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/eset">Eset</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/evga">EVGA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/evolis">EVOLIS</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/evolur">EVOLUR</a>
                </div>
              </div>
              <h2 id="F">F</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/f-and-d">F&amp;D</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/fantech">Fantech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/fanvil">Fanvil</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/fastrack">Fastrack</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/feiyutech">FeiyuTech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/fiberfox">FiberFox</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/fifine">FIFINE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/fireboltt">Fire Boltt</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/fjgear">FJGEAR</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/focusrite">Focusrite</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/foretell">Foretell</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/fujifilm">Fujifilm</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/fujitsu">Fujitsu</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/furgle">FURGLE</a>
                </div>
              </div>
              <h2 id="G">G</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/g-technology">G-Technology</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/g-tide">G-TiDE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/g-skill">G.SKILL</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/gadmei">Gadmei</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/galax">Galax</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/gamdias">GAMDIAS</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/gamemax ">GameMax</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/games">Games</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/gamesir">GameSir</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/garmin">Garmin</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/geil">GeIL</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/general">General</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/genius">Genius</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/gigabyte">GIGABYTE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/glorious">Glorious</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/godox">Godox</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/golden-field">Golden Field</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/google">Google</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/gopro">GoPro</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/grandstream">Grandstream</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/gree">Gree</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/gudsen">Gudsen</a>
                </div>
              </div>
              <h2 id="H">H</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/haier">Haier</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/harman-kardon">Harman Kardon</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/havit">Havit</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hewlett-packard">Hewlett Packard</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/hgst">HGST</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hifuture">HiFuture</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hikvision">Hikvision</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hitachi">Hitachi</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/hoco">Hoco</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/honeywell">Honeywell</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/horizon">Horizon</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hoya">Hoya</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/hp">HP</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hpe">HPE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hsdpa">HSDPA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/htc">HTC</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/htdj">HTDZ</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/huawei">HUAWEI</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/huion">Huion</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hunter">Hunter</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/huntkey ">Huntkey</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hyper">Hyper</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/hyperx">HyperX</a>
                </div>
              </div>
              <h2 id="I">I</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/imice">iMICE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/imperatorworks">Imperatorworks</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/infinix">Infinix</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/infocus">InFocus</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/innovtech">Innovtech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/insta360">Insta360</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/intech">INTECH</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/intel">INTEL</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/product/manufacturer/info?manufacturer_id=170">
                    Intel PC
                  </a>
                </div>
                <div class="col-sm-3">
                  <a href="#/ip-com">IP-COM</a>
                </div>
              </div>
              <h2 id="J">J</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/jabra">Jabra</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/jack">JACK</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/japan">Japan</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/jbl">JBL</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/jedel">Jedel</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/jovision">Jovision</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/joyroom">JOYROOM</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/jvco">JVCO</a>
                </div>
              </div>
              <h2 id="K">K</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/kentfaith">K&amp;F Concept</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kaspersky">kaspersky</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kenko">Kenko</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kenson">KENSON</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/kesu">Kesu</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/keychron">Keychron</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kieslect">Kieslect</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kingfast">KingFast</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/kingjoy">KINGJOY</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kingo">kingo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kingspec">KingSpec</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kingston">Kingston</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/kington">Kington</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kospet">KOSPET</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kstar">KSTAR</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kts">KTS</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/kwg">KWG</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/kz">KZ</a>
                </div>
              </div>
              <h2 id="L">L</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/lacie">LaCie</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/laxin">Laxin</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/ldnio">LDNIO</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/leadtek">Leadtek</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/lenovo">Lenovo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/leoch">Leoch</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/leofoto">Leofoto</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/levelone">LevelOne</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/lewitt">Lewitt</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/lexar">Lexar</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/lg">LG </a>
                </div>
                <div class="col-sm-3">
                  <a href="#/lian-li">Lian Li </a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/libec">Libec</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/light-breeze">Light Breeze</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/linksys">Linksys</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/lite-on">LITE-ON</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/logitech">Logitech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/long">LONG</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/loupedeck">Loupedeck</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/Lowepro">Lowepro</a>
                </div>
              </div>
              <h2 id="M">M</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/m-audio">M-Audio</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/maken">MAKEN</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/manfrotto">Manfrotto</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/mango">MANGO</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/maono">Maono</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/marshall">Marshall</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/marsriva">MARSRIVA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/marvo">Marvo</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/matias">Matias</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/maxell">Maxell</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/maxgreen">MaxGreen</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/meetion">MeeTion</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/mercusys">Mercusys</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/meta">Meta</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/mibro">Mibro</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/microlab">Microlab</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/micronet">Micronet</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/micropack">Micropack</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/microsoft">Microsoft</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/midea">Midea</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/mikrotik">Mikrotik</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/mirfak">MIRFAK</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/mitel">Mitel</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/mobvoi">Mobvoi</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/montech">Montech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/motorola">Motorola</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/motospeed">MotoSpeed</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/msi">MSI</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/mumre">Mumre</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/mustek">Mustek</a>
                </div>
              </div>
              <h2 id="N">N</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/nacon">Nacon</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/nakamichi">Nakamichi</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/nano">Nano</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/nec">NEC</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/neon">Neon</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/netac">Netac</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/netgear">NETGEAR</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/netis">Netis</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/neumann">Neumann</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/newmen">Newmen</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/nexstgo">Nexstgo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/nikon">Nikon</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/nintendo">Nintendo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/noctua">Noctua</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/nokia">Nokia</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/non-brand">Non-Brand</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/nothing">Nothing</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/novation">Novation</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/nuance">Nuance</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/nvidia">NVIDIA</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/nzxt">NZXT</a>
                </div>
              </div>
              <h2 id="O">O</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/ofitech">Ofitech</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/oneplus">OnePlus</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/onspot">Onspot</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/oppo">OPPO</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/optane">Optane</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/optoma">Optoma</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/orico">ORICO</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/orvibo">Orvibo</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/others">Others</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/non-brand-products">OTHERS</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/ovevo">Ovevo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/ovleng ">Ovleng </a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/ovo">OVO</a>
                </div>
              </div>
              <h2 id="P">P</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/palit ">Palit</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/panasonic">Panasonic</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/pantum">Pantum</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/patriot">PATRIOT</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/pc-power">PC Power</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/pccooler">PCcooler</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/perfect">Perfect</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/phanteks">Phanteks</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/philips">PHILIPS</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/pico">PICO</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/pioneer">Pioneer</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/planet">PLANET</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/plextone">Plextone</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/plextor">PLEXTOR</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/plustek">Plustek</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/pny">PNY</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/poly">Poly</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/posiflex">Posiflex</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/power-guard">Power Guard</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/power-pac">Power Pac</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/power-print">Power Print</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/presonus">PreSonus</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/print-rite">Print-Rite</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/prolink">PROLINK</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/protec">Protec</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/pulsar">Pulsar</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/pure">Pure Acoustics</a>
                </div>
              </div>
              <h2 id="Q">Q</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/qcy">QCY</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/qgeem">QGeeM</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/qnap">QNAP</a>
                </div>
              </div>
              <h2 id="R">R</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/r-and-m">R&amp;M</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/rapoo">Rapoo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/razer">RAZER</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/realfit">Realfit</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/realme">Realme</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/recci">Recci</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/red-hat-software">Red Hat</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/redner">Redner</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/redragon">Redragon</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/remax">Remax</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/revenger">Revenger</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/richerlink">RicherLink</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/ricoh">RICOH</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/rigal">Rigal</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/riotouch">Riotouch</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/rk-royal-kludge">RK ROYAL KLUDGE</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/robot">ROBOT</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/rode">RODE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/rogerele">Rogerele</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/rongta">RONGTA</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/rosenberger">Rosenberger</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/rowa">ROWA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/product/manufacturer/info?manufacturer_id=171">
                    RYZEN PC
                  </a>
                </div>
              </div>
              <h2 id="S">S</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/safenet">Safenet</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/safeway">SafeWay</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/samsung">Samsung</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/sandisk">Sandisk</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/santak">SANTAK</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/sapphire">Sapphire</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/saramonic">Saramonic</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/scalable">Scalable</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/screenbeam">ScreenBeam</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/seagate">Seagate</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/seemo">Seemo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/sennheiser">Sennheiser </a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/sewoo">Sewoo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/sharp">SHARP</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/shure">Shure</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/silicon-power">Silicon Power</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/silverstone">SilverStone</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/singer">SINGER</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/sjcam">SJCAM</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/ske">SKE</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/skullcandy">Skullcandy</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/skyview">Sky View</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/skyloong">Skyloong</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/smart">Smart</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/sodo">Sodo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/solid-state-logic">Solid State Logic</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/sony">Sony</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/speed">Speed</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/sprt">SPRT</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/srihome">SriHome</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/star">Star</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/star-gaming-pc">STAR GAMING PC</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/product/manufacturer/info?manufacturer_id=21">
                    STAR PC
                  </a>
                </div>
                <div class="col-sm-3">
                  <a href="#/starex">Starex</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/starink">StarInk</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/steelseries">SteelSeries</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/steinberg">Steinberg</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/sunlux">Sunlux</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/sunmi">Sunmi</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/suntech">SUNTECH</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/super-general">Super General</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/symphony">Symphony</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/synco">SYNCO</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/synology">Synology</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/szfp">SZFP</a>
                </div>
              </div>
              <h2 id="T">T</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/takstar">Takstar</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/targus">Targus</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/team">Team</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/teamsix">Team Six</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/tecnoware">Tecnoware</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/tecware">Tecware</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/telesin">TELESIN</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/temeisheng">Temeisheng</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/tenda">Tenda</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/terra">Terra</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/teutons">Teutons</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/tev">TEV</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/thermaltake">Thermaltake</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/toshiba">Toshiba</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/toten">Toten</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/toto-link">TOTOLINK</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/tp-link">TP-Link</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/transcend">Transcend</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/trm">TRM</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/trn">TRN</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/trooper">Trooper</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/tsc">TSC</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/tucano">Tucano</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/tumtec">Tumtec</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/turtle-beach">Turtle Beach</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/tvt">TVT</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/twinmos">Twinmos</a>
                </div>
              </div>
              <h2 id="U">U</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/ubiquiti">Ubiquiti</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/ugreen">UGREEN</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/uiisii">UiiSii</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/universal-audio">Universal Audio</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/Uniview">Uniview</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/univision">Univision</a>
                </div>
              </div>
              <h2 id="V">V</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/value-top">Value-Top</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/veikk">VEIKK</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/vention">Vention</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/vertux">Vertux</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/pro-video-camera">Video Camera</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/viewsonic">Viewsonic</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/viltrox">Viltrox</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/vimtag ">Vimtag </a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/vivitek">VIVItek</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/vivo">Vivo</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/VMware-software">Vmware</a>
                </div>
              </div>
              <h2 id="W">W</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/w-and-o">W&amp;O</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/wacom">Wacom</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/walton">Walton</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/wavefun">Wavefun</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/wavlink">Wavlink</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/wekome">WEKOME</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/western-digital">WESTERN DIGITAL</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/wgp">WGP</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/winson">Winson </a>
                </div>
                <div class="col-sm-3">
                  <a href="#/wiwu">WiWU</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/wnk">WNK</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/wonderful">Wonderful</a>
                </div>
              </div>
              <h2 id="X">X</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/x-leo">X-LEO</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/x-mini">X-mini</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/xerox">Xerox</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/xfx">XFX</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/xiaomi">XIAOMI</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/xigmatek">Xigmatek</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/xinji">XINJI</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/xp-pen">XP-PEN </a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/xprinter">Xprinter </a>
                </div>
                <div class="col-sm-3">
                  <a href="#/xtra">XTRA</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/xtreme">Xtreme</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/xtrfy">Xtrfy</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/xtrike-me">Xtrike Me</a>
                </div>
              </div>
              <h2 id="Y">Y</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/yamaha">Yamaha</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/yanmai">Yanmai</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/yealink">Yealink</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/yison">Yison</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/yumite">Yumite</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/yunteng">Yunteng</a>
                </div>
              </div>
              <h2 id="Z">Z</h2>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/zadak">ZADAK</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zebex">Zebex</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zeblaze">Zeblaze</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zebra">Zebra</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/zenius">Zenius</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zepp">Zepp</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zhiyun ">Zhiyun</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zigor">ZIGOR</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/zkteco">ZKTeco</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zoje">ZOJE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zoom">Zoom</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zoook">ZOOOK</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a href="#/zotac">ZOTAC</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zte">ZTE</a>
                </div>
                <div class="col-sm-3">
                  <a href="#/zyxel">Zyxel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default BrandScreen;
