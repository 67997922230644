import React, { useEffect } from "react";
import { InputGroup, Form, Button, Modal } from "react-bootstrap";
import {} from "react-bootstrap";

const CompareScreen = ({}) => {
  return (
    <table class=" table-responsive table table-bordered cmpr-table count-4">
      <tbody>
        <tr class="cmpr-header">
          <td class="compare-blurb name">
            <h4 class="page-heading">Product Comparison</h4>
            <p>
              Find and select products to see the differences and similarities
              between them
            </p>
          </td>
          <td class="value">
            <div class="compare-item-wrapper">
              <div class="cmpr-field">
                <InputGroup className=" ">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className="nav_serch_form"
                  />
                  <InputGroup.Text id="basic-addon2" className="nav_serch_btn">
                    <i class="fas fa-search"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div class="p-item-img">
                <img
                  src="https://www.startech.com.bd/image/cache/catalog/mouse-pad/havit/hv-mp838/hv-mp838-228x228.jpg"
                  alt="Havit HV-MP838 Gaming Mouse Pad"
                  title="Havit HV-MP838 Gaming Mouse Pad"
                  class="img-thumbnail"
                  width="228"
                  height="228"
                />
              </div>
              <a class="p-item-name" href="#">
                <strong>Havit HV-MP838 Gaming Mouse Pad</strong>
              </a>
              <div class="p-item-price">
                <span>100৳</span>
              </div>
              <a href="#" class="remove">
                Remove
              </a>
            </div>
          </td>
          <td class="value">
            <div class="compare-item-wrapper">
              <div class="cmpr-field">
                <InputGroup className=" ">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className="nav_serch_form"
                  />
                  <InputGroup.Text id="basic-addon2" className="nav_serch_btn">
                    <i class="fas fa-search"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div class="p-item-img">
                <img
                  src="https://www.startech.com.bd/image/cache/catalog/mouse-pad/havit/hv-mp837/hv-mp837-228x228.jpg"
                  alt="Havit HV-MP837 Gaming Mouse Pad"
                  title="Havit HV-MP837 Gaming Mouse Pad"
                  class="img-thumbnail"
                  width="228"
                  height="228"
                />
              </div>
              <a class="p-item-name" href="#">
                <strong>Havit HV-MP837 Gaming Mouse Pad</strong>
              </a>
              <div class="p-item-price">
                <span>100৳</span>
              </div>
              <a href="#" class="remove">
                Remove
              </a>
            </div>
          </td>
          <td class="value blank">
            <div class="cmpr-field">
              <InputGroup className=" ">
                <Form.Control
                  placeholder="Search"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="nav_serch_form"
                />
                <InputGroup.Text id="basic-addon2" className="nav_serch_btn">
                  <i class="fas fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
            <p>Find and select product to compare</p>
          </td>
        </tr>
        <tr>
          <td class="name">Model</td>
          <td class="value">Havit HV-MP838 </td>
          <td class="value">Havit HV-MP837 </td>
          <td class="value"></td>{" "}
        </tr>
        <tr>
          <td class="name">Brand</td>
          <td class="value"></td>
          <td class="value"></td>
          <td class="value"></td>{" "}
        </tr>
        <tr>
          <td class="name">Availability</td>
          <td class="value">In Stock</td>
          <td class="value">In Stock</td>
          <td class="value"></td>{" "}
        </tr>
        <tr>
          <td class="name">Rating</td>
          <td class="value rating">
            <div class="rating-star">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="rating-text">Based on 0 reviews.</div>
          </td>
          <td class="value rating">
            <div class="rating-star">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="rating-text">Based on 0 reviews.</div>
          </td>
          <td class="value"></td>{" "}
        </tr>
        <tr>
          <td class="name">Summary</td>
          <td class="value short-desc">
            <ul className="pl-0 list_style">
              <li>Size: 25x21x0.2cm</li>
              <li>Dimensions: 250 x 210 x 2mm</li>
              <li>Ergonomic Design</li>
            </ul>
          </td>
          <td class="value short-desc">
            <ul className="pl-0 list_style">
              <li>Size: 25 x 21 x 0.2cm</li>
              <li>Ergonomic Design</li>
              <li>Prevent injuries and wrist problems</li>
            </ul>
          </td>
          <td class="value"></td>
        </tr>
      </tbody>

      <tbody>
        <tr>
          <td class="name"></td>
          <td class="value">
            <input
              type="button"
              value="Buy Now"
              class="btn btn-primary btn-block"
              onclick="cart.add('9314', '1');"
            />
          </td>
          <td class="value">
            <input
              type="button"
              value="Buy Now"
              class="btn btn-primary btn-block"
              onclick="cart.add('9315', '1');"
            />
          </td>
          <td class="value"></td>{" "}
        </tr>
      </tbody>
    </table>
  );
};

export default CompareScreen;
