import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Container,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";

const ContactUsScreen = ({}) => {
  return (
    <>
      <Container>
        <Row>
          <div class="contact-us-content mt-5">
            <div class="row m-b-30">
              <div class="col-md-6">
                <div class="c-card ws-box">
                  <div class="ic">
                    <a href="tel:16793">
                      <i class="fas fa-phone-alt text-light"></i>
                    </a>
                  </div>
                  <div class="each-contact-text">
                    <h3>Contact Us (10 AM - 7 PM)</h3>
                    <p class="c-phone">+880 1777-171413</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="c-card ws-box">
                  <div class="ic">
                    <a href="mailto:interwavecomp@gmail.com">
                      <i class="fas fa-envelope text-light"></i>
                    </a>
                  </div>
                  <div class="each-contact-text">
                    <h3>For Deals &amp; Complain</h3>
                    <p>interwavecomp@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="top-bar ws-box">
              <div class="row">
                <div class="col-md-8 col-sm-5 actions">
                  <h2 class="page-heading mt-2">Sales Outlets</h2>
                </div>
                <div class="col-md-4 col-sm-7 show-sort">
                  <InputGroup className="width_nav_fix bordered">
                    <Form.Control
                      placeholder="Search"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className="nav_serch_form"
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="nav_serch_btn"
                    >
                      <i class="fas fa-search"></i>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
            </div>
            <div class="showroom">
              <div class="address">
                <div class="address-inner ws-box">
                  <div class="address-details">
                    <h4>
                      <span> Khulna Branch</span>
                      {/* <span class="closed-day">Friday Off</span> */}
                    </h4>
                    <div class="address-line">
                    Jalil Tower(2nd, 3rd Floor), Lower Jeshore Road, Khulna-9100
                    </div>
                  </div>
                  <div class="phone-numbers">
                    <div class="phone">
                      <span>All</span>01777171413
                    </div>
                   
                   
                  </div>
                  <div class="map-link">
                    <a
                      href="https://maps.app.goo.gl/gYok4JnKdS8M47kY6"
                      target="_blank"
                      rel="noopener"
                    >
                      Map<i class="fas fa-external-link-alt ml-2"></i>
                    </a>
                  </div>
                  <meta itemprop="tags" content="" />
                </div>
              </div>
          
            
            </div>
            {/* <div class="category-description ws-box p-3">
              <h1>Contact with Interwave ComputersLtd.</h1>
              <p>
                You can always contact Interwave ComputersLtd. to buy your preferred model
                of the latest <a href="#">laptop</a>, desktop, PC component,{" "}
                <a href="#">Gaming PC</a>, Gaming Accessories,
                <a href="#"> Gadgets</a>, Cameras or
                <a href="#">TV </a> at the best price in Bangladesh.
                <a href="#">Star Tech</a> has multiple branches in major cities
                like Dhaka, Chittagong, Rangpur, Gazipur, and Khulna. Our
                Branches are well-decorated with all the latest Tech products
                from the best Brands in Bangladesh. We are also planning on
                opening more branches in the future to serve more customers. You
                can visit any nearest <a href="#"> Interwave ComputersLtd.</a> branch with
                your query, after-sale service, or any type of technical
                assistance. Our team of highly trained technicians and Qualified
                customer representatives will assist your issue with the utmost
                importance.
              </p>
              <h2>How to Contact Interwave ComputersLtd. to get a faster response?</h2>
              <p>
                In case you have any inquiry and you want to get a faster
                response, You can contact our Hotline number from 9 AM to 8 PM.
                Our Hotline numbers are <a href="tel:16793">16793</a> and{" "}
                <a href="tel:09678002003">09678002003</a>. You can also reach us
                through our Email address{" "}
                <a href="mailto: interwavecomputers@gmail.com">
                  <b>interwavecomputers@gmail.com</b>
                </a>
              </p>
              <h2>How to contact Interwave Computersfor any Complain issue?</h2>
              <p>
                If you have any complaints you can reach us via our Hotline
                number between 9 AM to 8 PM, our hotline numbers are{" "}
                <a href="tel:16793">16793</a> and{" "}
                <a href="tel:09678002003">09678002003</a>. You can also send us
                an Email with your issue at{" "}
                <a href="mailto:interwavecomputers@gmail.com">
                  <b>interwavecomputers@gmail.com</b>
                </a>{" "}
                or{" "}
                <a href="mailto:rezwanur@Interwave Computers.com">rezwanur@Interwave Computers.com</a>
              </p>
              <p></p>
            </div> */}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ContactUsScreen;
