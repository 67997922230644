import React, { useState, useEffect } from "react";
import { Route, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";

import Message from "./Message";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  ListGroup,
  Col,
  InputGroup,
  Form,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import { addToCart, removeFromCart } from "../actions/cartActions";
const Cartview = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const cart = useSelector((state) => state.cart);
  const [show, setShow] = React.useState(false);

  const checkoutHandler = () => {
    history.push("/login?redirect=shipping");
    setShow(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <LinkContainer to="/cart">
        <>
          <Nav.Link onClick={handleShow}>
            {/* <i className='fas fa-shopping-cart admin_ico'></i> Cart */}
            <div class="d-flex">
              <div class="flex-shrink-0">
                <i className="fas fa-shopping-cart admin_ico mr-2 mt-2"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <span>Cart</span> <br></br>
                <span className="font_customize">
                  {cart.cartItems.reduce((acc, item) => acc + item.qty, 0)}{" "}
                  item(s) -{" "}
                  <img
                    width={10}
                    className="img-fluid mr-1 mb-1 "
                    src="../images/taka_black.svg"
                  />
                  {cart.cartItems
                    .reduce((acc, item) => acc + item.qty * item.price, 0)
                    .toFixed(2)}
                </span>
              </div>
            </div>
          </Nav.Link>
        </>
      </LinkContainer>{" "}
      <Modal
        show={show}
        onHide={handleClose}
        scrollable
        className="custom_modal"
      >
        <Modal.Header closeButton className="text-center">
          <Modal.Title>CART VIEW</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "unset" }}>
          <div
            className="text-center"
            style={{ height: "280px", overflowY: "auto" }}
          >
            {cart.cartItems.length === 0 ? (
              <Message>Your cart is empty</Message>
            ) : (
              <ListGroup variant="flush">
                {cart.cartItems.map((item, index) => (
                  <>
                    <div class="d-flex align-items-center mb-4 ">
                      <div class="flex-shrink-0" key={index}>
                        <Image
                          className="img-fluid product_img"
                          src={item.image}
                          alt={item.name}
                          fluid
                          rounded
                        />
                      </div>

                      <div class="flex-grow-1 ml-3  text-left">
                        <Link to={`/product/${item.product}`}>{item.name}</Link>
                        <br></br>
                        <span className="prod_price_view mt-3 d-flex justify-content-between align-items-center">
                          <span>{item.price}</span>
                          <Form.Control
                            style={{ marginRight: "1rem" }}
                            as="select"
                            className="select_form w-50 "
                            value={item.qty}
                            onChange={(e) =>
                              dispatch(
                                addToCart(item.product, Number(e.target.value))
                              )
                            }
                          >
                            {[...Array(item.countInStock).keys()].map((x) => (
                              <option key={x + 1} value={x + 1}>
                                {x + 1}
                              </option>
                            ))}
                          </Form.Control>
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </ListGroup>
            )}
          </div>

          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <span className="prod_price_cart  text-center ">Subtotal: </span>
            </div>
            <div class="flex-grow-1  text-right">
              <span className="prod_price_cart  ">
                ({cart.cartItems.reduce((acc, item) => acc + item.qty, 0)})
                items <br></br>
                <img
                  width={12}
                  className="img-fluid mr-1 mb-1"
                  src="../images/taka_orange.svg"
                />
                {cart.cartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(2)}{" "}
              </span>
            </div>
          </div>
          <LinkContainer to="/cart" onClick={handleClose}>
            <Button className="btn btn-primary w-100 mt-4"> VIEW CART </Button>
          </LinkContainer>
          <br></br>
          {/* <div  type="button"
        
            // disabled={cart.cartItems.length === 0}
            onClick={checkoutHandler}>
            <Button className="btn btn-danger mt-2 w-100"> CHECKOUT </Button>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Cartview;
