import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import { offerCreate } from '../../actions/offersAction'
import {

    OFFER_CREATE_REQUEST,

} from '../../constants/offerConstants'

const OfferCreateScreen = ({ match, history }) => {

    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [uploading, setUploading] = useState(false)

    const dispatch = useDispatch()


    const { loading, error, success: successCreate } = useSelector((state) => state.offerCreate)

    useEffect(() => {

        if (successCreate) {
            dispatch({
                type: OFFER_CREATE_REQUEST,
            })
            history.push('/admin/offerslist')
        }
        else {
            // if (!product.name || product._id !== productId) {
            //     dispatch(listProductDetails(productId))
            // }
            // else {
            //     setName(product.name)
            //     setPrice(product.price)
            //     setImage(product.image)
            //     setBrand(product.brand)
            //     setCategory(product.category)
            //     setCountInStock(product.countInStock)
            //     setDescription(product.description)
            // }
        }
    }, [dispatch, successCreate])

    const uploadfileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/upload', formData, config)

            setImage(data)
            setUploading(false)
        }
        catch (error) {
            console.log(error)
            setUploading(false)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()


        dispatch({ type: "OFFER_CREATE_REQUEST" })

        dispatch(offerCreate({
            "title": title,
            "description": description,
            "imageUrl": image
        }))
    }

    return (
        <>
            <Link to='/admin/offerslist' className='btn btn-light mb-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Create Offer</h1>
                {loading && <Loader />}
                {error && <Message variant='danger'>{error}</Message>}
                {
                    (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='title'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type='name'
                                    placeholder='Enter here offer title'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='image'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter image url'
                                    value={image}
                                    onChange={(e) => setImage(e.target.value)}
                                ></Form.Control>
                                <Form.File id='image-file' label='Choose File' custom onChange={uploadfileHandler}></Form.File>

                                <br />
                                <br />
                                {
                                    image && <img width={140} height={90} src={`${image}`} />
                                }

                                {uploading && <Loader />}
                            </Form.Group>

                            <Button type='submit' variant='primary'>
                                Save
                            </Button>
                        </Form>
                    )}
            </FormContainer>
        </>
    )
}

export default OfferCreateScreen
