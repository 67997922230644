import axios from 'axios'

import {
    OFFER_CREATE_REQUEST,
    OFFER_CREATE_SUCCESS,
    OFFER_CREATE_FAIL,

    OFFER_LIST_REQUEST,
    OFFER_LIST_SUCCESS,
    OFFER_LIST_FAIL,

    OFFER_DELETE_REQUEST,
    OFFER_DELETE_SUCCESS,
    OFFER_DELETE_FAIL,

    OFFER_UPDATE_REQUEST,
    OFFER_UPDATE_SUCCESS,
    OFFER_UPDATE_FAIL,
} from '../constants/offerConstants'

export const offerCreate = (offer) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OFFER_CREATE_REQUEST,
        })

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        }

        const { data } = await axios.post(
            `/api/offers`,
            offer,
            config
        )

        dispatch({
            type: OFFER_CREATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: OFFER_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const offerDelete = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OFFER_DELETE_REQUEST,
        })

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        }

        await axios.delete(
            `/api/offers/${id}`,
            config
        )

        dispatch({
            type: OFFER_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: OFFER_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const offerUpdate = (offer) => async (dispatch, getState) => {
    
    try {
        dispatch({
            type: OFFER_UPDATE_REQUEST,
        })

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        }

        const { data } = await axios.put(
            `/api/offers/${offer._id}`,
            offer,
            config
        )

        dispatch({
            type: OFFER_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: OFFER_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const offersList = () => async (dispatch, getState) => {

    try {

        const { data } = await axios.get(
            `/api/offers`,
        )

        dispatch({
            type: OFFER_LIST_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        dispatch({
            type: OFFER_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}
